<template>
 <div>  
<div id="ChallengeItem"> 
         
 <a  :href = "href" 
 :class="'link routerlink0 '"   id="routerlink0" ref="routerlink0" :target = "'_blank'" 
  v-bind:style="{}">
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0" :elevation = "2" 
  v-bind:style="{}">
 <v-img  :class="'networkimage imagecard01 '"   id="imagecard01" ref="imagecard01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "backImage" 
>  </v-img>
 <v-card 
 :class="'card cardcard03 '"   id="cardcard03" ref="cardcard03" :elevation = "2" 
  v-bind:style="{}">
 <div  :class="'icon iconcardcard031 '"   id="iconcardcard031" ref="iconcardcard031"  v-bind:style="{color : 'gray',fontSize : getUnit(25,'px'),}"><i  :class="'ion-md-flash'"></i></div>  </v-card>
 <div  :class="'text textcard05 '"   id="textcard05" ref="textcard05"  v-bind:style="{color : '#585858',}">
<div class="text-content">{{ title }}</div>

   </div>
 <div  :class="'rectangle rectangleimagecard0114 '"   id="rectangleimagecard0114" ref="rectangleimagecard0114"  v-bind:style="{}">
 <div  :class="'icon iconrectangleimagecard0111 '"   id="iconrectangleimagecard0111" ref="iconrectangleimagecard0111"  v-bind:style="{color : 'gray',fontSize : getUnit(15,'px'),}"><i  :class="subicon"></i></div>
 <div  :class="'text textrectangleimagecard0113 '"   id="textrectangleimagecard0113" ref="textrectangleimagecard0113"  v-bind:style="{color : 'gray',}">
<div class="text-content">{{ subtitle }}</div>

   </div>  </div>  </v-card>  </a>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ChallengeItem',

    components: {},
	computed: {
	
		subtitle: function() { 		
	if(this.video){

		return "Video"

	} else {

		return "Etape"

	}

}
,
		subicon: function() { 		
	if(this.video){

		return "ion-md-film"

	} else {

		return "ion-ios-trending-up"

	}

}
,
	},
	props: {
	
		title: {					required: false,			default: () =>  "loop &#129409;",		},
		backImage: {					required: false,			default: () =>  "https://picsum.photos/500",		},
		href: {					required: false,			default: () =>  "",		},
		video: {			type: Boolean,			required: false,			default: () =>  true,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ChallengeItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

#card0{
	overflow: hidden;
	&:hover{
    transform: scale(1.01);
	}
}



.link.routerlink0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
target:_blank !important; 

display: block;

.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
background-color:#ffffff ; 
border-radius:18px ; max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 45px ; 
	
	display: inherit;

	flex: unset;
	border-radius:18px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}

	.card.cardcard03{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:18px ; 
	
	top: 18px ; 
	width:40px!important ; 
	height:40px!important ; 
	background-color:#ffffff ; 
	border-radius:18px ; max-width: initial;min-width: initial;

		.icon.iconcardcard031{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		color:gray ; 
		font-size:25px ; 
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 20px ; 
	
	right: 20px ; 
	bottom: 5px ; 
	height:36px!important ; 
	
	color:#585858 ; 
	font-size:17px ; 
	align-items:center;
	display: flex;
	text-overflow: ellipsis ; 
	.text-content{
	width: 100%;
	white-space: nowrap;
	overflow: hidden ;
	text-overflow: ellipsis ; 
	font-weight: bold;
	}

	
}

	.rectangle.rectangleimagecard0114{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:30.25px ; 
	
	bottom: 50px ; 
	width:80px!important ; 
	height:30px!important ; 
	
	background-color:#ffffff ; 
	border-radius:15px ; 

		.icon.iconrectangleimagecard0111{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:4px ; 
		
		top: calc(50% + 0px) ; 
		width:15px!important ; 
		height:15px!important ; 
		
		color:gray ; 
		font-size:15px ; 
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrectangleimagecard0113{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 18px ; 
		
		top: 5px ; 
		right: 10px ; 
		bottom: 5px ; 
		
		color:gray ; 
		text-align:center;
		align-items:center;
		display: flex;
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		font-weight: bold;
		}

		
}

	
}


}


}


@media (max-width: 600px) {


.link.routerlink0{


position:absolute !important; 





display: block;

.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.card.cardcard03{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.icon.iconcardcard031{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.rectangle.rectangleimagecard0114{

	
	position:absolute !important; 
	
	
	
	
	
	

		.icon.iconrectangleimagecard0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrectangleimagecard0113{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.link.routerlink0{


position:absolute !important; 





display: block;

.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.card.cardcard03{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.icon.iconcardcard031{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.rectangle.rectangleimagecard0114{

	
	position:absolute !important; 
	
	
	
	
	
	

		.icon.iconrectangleimagecard0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrectangleimagecard0113{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.link.routerlink0{


position:absolute !important; 





display: block;

.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.card.cardcard03{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.icon.iconcardcard031{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.rectangle.rectangleimagecard0114{

	
	position:absolute !important; 
	
	
	
	
	
	

		.icon.iconrectangleimagecard0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrectangleimagecard0113{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.link.routerlink0{


position:absolute !important; 





display: block;

.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.card.cardcard03{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.icon.iconcardcard031{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.rectangle.rectangleimagecard0114{

	
	position:absolute !important; 
	
	
	
	
	
	

		.icon.iconrectangleimagecard0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrectangleimagecard0113{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.link.routerlink0{


position:absolute !important; 





display: block;

.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.card.cardcard03{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.icon.iconcardcard031{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

	
}

	.text.textcard05{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.rectangle.rectangleimagecard0114{

	
	position:absolute !important; 
	
	
	
	
	
	

		.icon.iconrectangleimagecard0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.text.textrectangleimagecard0113{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}
}
</style>
