<template>
 <div>  
<div id="Ch_ModeSwitch"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'row rowcolumncolumnscrollarea021031 '"   id="rowcolumncolumnscrollarea021031" ref="rowcolumncolumnscrollarea021031"  v-bind:style="{}">
 <ButtonImage :class="'object buttonimagerowcolumncolumnscrollarea021031 '"   id="buttonimagerowcolumncolumnscrollarea021031" ref="buttonimagerowcolumncolumnscrollarea021031"  v-bind:style="{}" :active = "true" 
>
</ButtonImage>
 <ButtonImage :class="'object buttonimagerowcolumncolumnscrollarea0210312 '"   id="buttonimagerowcolumncolumnscrollarea0210312" ref="buttonimagerowcolumncolumnscrollarea0210312"  v-bind:style="{}">
</ButtonImage>
 <ButtonImage :class="'object buttonimagerowcolumncolumnscrollarea0210313 '"   id="buttonimagerowcolumncolumnscrollarea0210313" ref="buttonimagerowcolumncolumnscrollarea0210313"  v-bind:style="{}">
</ButtonImage>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import ButtonImage from '../../../At/Components/ButtonImage'


export default {
	name: 'Ch_ModeSwitch',

    components: {ButtonImage,},
} 
</script>

<style lang = "scss" scoped>


 div#Ch_ModeSwitch{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.row.rowcolumncolumnscrollarea021031{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

top: 0px ; 
width:auto!important ; 
height:50px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:10px ; background-color:transparent ; 

	.object.buttonimagerowcolumncolumnscrollarea021031{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	height:50px!important ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210312{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	height:50px!important ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210313{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	height:50px!important ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.row.rowcolumncolumnscrollarea021031{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.object.buttonimagerowcolumncolumnscrollarea021031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210312{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210313{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.row.rowcolumncolumnscrollarea021031{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.object.buttonimagerowcolumncolumnscrollarea021031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210312{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210313{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.row.rowcolumncolumnscrollarea021031{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.object.buttonimagerowcolumncolumnscrollarea021031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210312{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210313{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.row.rowcolumncolumnscrollarea021031{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.object.buttonimagerowcolumncolumnscrollarea021031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210312{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210313{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.row.rowcolumncolumnscrollarea021031{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.object.buttonimagerowcolumncolumnscrollarea021031{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210312{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.buttonimagerowcolumncolumnscrollarea0210313{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}
}
</style>
