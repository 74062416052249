import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
]

/**
 * Please don' t remove this.
 * Artefact will use it to inject code
 */
//<= inject_routes =>
routes = routes.concat([{"path":"/","component":require('../artefact/At/main').default},{"name":"home","path":"/home","component":require('../artefact/At/Pages/Home').default,"redirect":""},{"name":"profile","path":"/profile","component":require('../artefact/At/Pages/Profile').default,"redirect":""},{"name":"profileTab","path":"/profile/:tab","component":require('../artefact/At/Pages/Profile').default,"redirect":""},{"name":"profileUser","path":"/user/:id","component":require('../artefact/At/Pages/Profile').default,"redirect":""},{"name":"challenges","path":"/challenges","component":require('../artefact/At/Pages/Challenges').default,"redirect":""},{"name":"challenges-details","path":"/challenges/:id","component":require('../artefact/At/Pages/ChallengeDetails').default,"redirect":""},{"name":"tutoriels-details","path":"/tutoriels/:id","component":require('../artefact/At/Pages/TutorielDetails').default,"redirect":""},{"name":"explore","path":"/explore","component":require('../artefact/At/Pages/Explore').default,"redirect":""},{"name":"explore-details","path":"/explore/:id","component":require('../artefact/At/Pages/ExploreDetails').default,"redirect":""}])
//<= end_routes =>

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
