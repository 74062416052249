<template>
 <div>  
<div id="Ep_Comment"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagerow01 '"   id="networkimagerow01" ref="networkimagerow01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "m_userImage" 
>  </v-img>
 <div  :class="'column columnitem03 '"   id="columnitem03" ref="columnitem03"  v-bind:style="{}">
 <div  :class="'row rowcolumnitem031 '"   id="rowcolumnitem031" ref="rowcolumnitem031"  v-bind:style="{}">
 <div  :class="'text textrowcolumnitem0311 '"   id="textrowcolumnitem0311" ref="textrowcolumnitem0311"  v-bind:style="{color : '#ff6900',}">
<div class="text-content">{{ userName }}</div>

   </div>
 <div  :class="'text textrowcolumnitem0313 '"   id="textrowcolumnitem0313" ref="textrowcolumnitem0313"  v-bind:style="{color : '#9fa4a8',}">
<div class="text-content">{{ publishedTime }}</div>

   </div>  </div>
 <div  :class="'rectangle rectanglecolumnitem033 ' + 'commentBox'"   id="rectanglecolumnitem033" ref="rectanglecolumnitem033"  v-bind:style="{backgroundColor : '#f6f7f9',}">
 <div  :class="'text textrectanglecolumnitem0331 '"   id="textrectanglecolumnitem0331" ref="textrectanglecolumnitem0331"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ comment }}</div>

   </div>  </div>
 <div  :class="'row rowcolumnitem036 '"   id="rowcolumnitem036" ref="rowcolumnitem036"  v-bind:style="{}">
 <WorldButton :class="'object worldbuttonrowcolumncolumnscrollarea02105123 '"   id="worldbuttonrowcolumncolumnscrollarea02105123" ref="worldbuttonrowcolumncolumnscrollarea02105123"  v-bind:style="{}" :value = "''" 
 :backgroundColor = "likedColor" 
>
</WorldButton>
 <div  :class="'text textrowcolumnitem0363 '"   id="textrowcolumnitem0363" ref="textrowcolumnitem0363"  v-bind:style="{}">
<div class="text-content">{{ numberLike }}</div>

   </div>  </div>
 <div  :class="'column subcommentColumn '"   id="subcommentColumn" ref="subcommentColumn"  v-bind:style="{}">
 <Ep_Comment :class="'object ep_comment1 '"   id="ep_comment1" ref="ep_comment1" v-for="(item,index) in subcommentList" :key="'ep_comment1' + index"  v-bind:style="{}" :comment = "item.value" 
 :userImage = "item.user.profile_image.url" 
 :userName = "item.user.username" 
 :publishedTime = "item.updated_at" 
 :numberLike = "item.likes" 
 :projectID = "projectID" 
 :commentID = "item.id" 
>
</Ep_Comment>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import WorldButton from '../../../At/Components/WorldButton'
import Ep_Comment from '../../../At/Components/Explore/Ep_Comment'

import * as utils from '../../../Js/utils.js'
export default {
	name: 'Ep_Comment',

    components: {WorldButton,Ep_Comment,},
	methods: {
	
		updateLikedColor: function(){ 		
	if(this.liked){

		this.likedColor = 'red';

	} else {

		this.likedColor = '#fff'

	}

}
,
		getSubComments: function(){		
	let filter = {

		project: this.projectID,

		parent_comment: this.commentID,

		is_subcomment: true

	}

	utils.getComments(filter)

	.then((response)=>{

		this.subcommentList = response.data

		this.subcommentList

		.map((e)=>{

			e.user.profile_image.url = this.$store.state.apiURL + e.user.profile_image.url

		})

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
	},
	mounted(){
			
	this.updateLikedColor()

	this.getSubComments()

	},
	computed: {
	
		m_userImage: function(){		
	if(this.userImage){

		return this.$store.state.apiURL + this.userImage.url

	} else {

		return "https://picsum.photos/200"

	}

}
,
	},
	watch: {
	
		liked: function(val){		
	this.updateLikedColor()

}
,
	},
	data(){
	return {
		
		likedColor:  '#fff'		,
		subcommentList:  []		,
		}
	},
	props: {
	
		userImage: {					required: false,			default: () =>  null,		},
		userName: {					required: false,			default: () =>  "dannick",		},
		publishedTime: {					required: false,			default: () =>  "11.21.2020",		},
		numberLike: {					required: false,			default: () =>  0,		},
		liked: {			type: Boolean,			required: false,			default: () =>  false,		},
		comment: {					required: false,			default: () =>  "Salut toi!",		},
		projectID: {					required: false,			default: () =>  0,		},
		commentID: {					required: false,			default: () =>  0,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Ep_Comment{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.commentBox{
	padding-bottom: 30px !important
;
}



.item.item0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;


.networkimage.networkimagerow01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

top: 0px ; 
width:37px!important ; 
height:37px!important ; 

display: inherit;

flex: unset;
border-radius:18.5px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}


}

.column.columnitem03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 60px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (60px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:15px ; background-color: transparent ; 

	.row.rowcolumnitem031{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.text.textrowcolumnitem0311{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		color:#ff6900 ; 
		font-size:16px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.text.textrowcolumnitem0313{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		width:auto!important ; 
		height: calc(100% - (0px + 0px)) !important;
		
		color:#9fa4a8 ; 
		align-items:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

	
}

	.rectangle.rectanglecolumnitem033{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	background-color:#f6f7f9 ; 
	border-radius:20px ; 

		.text.textrectanglecolumnitem0331{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 15px ; 
		
		margin-top: 15px ; 
		margin-right: 15px ; 
		width:100px!important ; 
		height:auto!important ; 
		width: calc(100% - (15px + 15px)) !important;
		
		color:#414a51 ; 
		font-size:20px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnitem036{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	width:auto!important ; 
	height:30px!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.object.worldbuttonrowcolumncolumnscrollarea02105123{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:50px!important ; 
		height:30px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.text.textrowcolumnitem0363{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:auto!important ; 
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.subcommentColumn{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.object.ep_comment1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:relative !important; 






.networkimage.networkimagerow01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.column.columnitem03{


position:relative !important; 









	.row.rowcolumnitem031{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcolumnitem0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowcolumnitem0313{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumnitem033{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectanglecolumnitem0331{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnitem036{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldbuttonrowcolumncolumnscrollarea02105123{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.text.textrowcolumnitem0363{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.subcommentColumn{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.ep_comment1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:relative !important; 






.networkimage.networkimagerow01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.column.columnitem03{


position:relative !important; 









	.row.rowcolumnitem031{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcolumnitem0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowcolumnitem0313{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumnitem033{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectanglecolumnitem0331{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnitem036{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldbuttonrowcolumncolumnscrollarea02105123{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.text.textrowcolumnitem0363{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.subcommentColumn{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.ep_comment1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:relative !important; 






.networkimage.networkimagerow01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.column.columnitem03{


position:relative !important; 









	.row.rowcolumnitem031{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcolumnitem0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowcolumnitem0313{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumnitem033{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectanglecolumnitem0331{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnitem036{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldbuttonrowcolumncolumnscrollarea02105123{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.text.textrowcolumnitem0363{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.subcommentColumn{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.ep_comment1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:relative !important; 






.networkimage.networkimagerow01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.column.columnitem03{


position:relative !important; 









	.row.rowcolumnitem031{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcolumnitem0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowcolumnitem0313{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumnitem033{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectanglecolumnitem0331{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnitem036{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldbuttonrowcolumncolumnscrollarea02105123{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.text.textrowcolumnitem0363{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.subcommentColumn{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.ep_comment1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:relative !important; 






.networkimage.networkimagerow01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.column.columnitem03{


position:relative !important; 









	.row.rowcolumnitem031{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcolumnitem0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textrowcolumnitem0313{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectanglecolumnitem033{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectanglecolumnitem0331{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.row.rowcolumnitem036{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldbuttonrowcolumncolumnscrollarea02105123{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.text.textrowcolumnitem0363{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.subcommentColumn{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.ep_comment1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
