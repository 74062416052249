<template>
 <div>  
<div id="TemplatePage"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'scrollarea scrollarea0 '"   id="scrollarea0" ref="scrollarea0"  v-bind:style="{backgroundColor : '#f6f7f9',backgroundColor : '#f6f7f9',}">
 <div  :class="'column columnscrollarea02 '"   id="columnscrollarea02" ref="columnscrollarea02"  v-bind:style="{}">
 <TopBar :class="'object topbar '"   id="topbar" ref="topbar"  v-bind:style="{}" :showLunch = "showLunchZone" 
 :showPanel = "showPanel" 
 :showSignup = "showSignUP" 
 :showLogin = "showLogIn" 
 :showNotification = "showNotification" 
 :showUserInfo = "showUserPanel" 
 :curentPage = "currentPage" 
>
</TopBar>
 <div  :class="'slot mainSlot '"   id="mainSlot" ref="mainSlot"  v-bind:style="{}"><slot  :name = "'main'" 
></slot></div>
 <div  :class="'text versionText '"   id="versionText" ref="versionText"  v-bind:style="{color : '#959595',}">
<div class="text-content">{{ 'Version 0.0.5' }}</div>

   </div>
 <Footer :class="'object footercolumnscrollarea023 '"   id="footercolumnscrollarea023" ref="footercolumnscrollarea023"  v-bind:style="{}">
</Footer>  </div>  </div>
 <div  :class="'transition transitionitem04 '"   id="transitionitem04" ref="transitionitem04" v-if="haveVideo"  v-bind:style="{height : getUnit(onboardingStepperH,'px'),}">
 <transition  :enter-active-class = "'animated bounce'" 
>
 <OnboardingStepper :class="'object onboardingstepperscrollarea032 '"   id="onboardingstepperscrollarea032" ref="onboardingstepperscrollarea032" v-show="showOnBoard"  v-bind:style="{}" :minifyOnBoard = "minifyOnBoard" 
 :expendOnBoard = "expendOnBoard" 
 :videoList = "pageData.videos" 
>
</OnboardingStepper>  </transition></div>
 <NavPanel :class="'object navPanel '"   id="navPanel" ref="navPanel"  v-bind:style="{ display: ((mqVal({default:panelVisible,md:false}, $mq))? 'block' : 'none'), }" :closePanel = "hidePanel" 
 :showLunch = "showLunchZone" 
 :showSignup = "showSignUP" 
 :showLogin = "showLogIn" 
>
</NavPanel>
 <LunchZone :class="'object lunchzoneitem05 '"   id="lunchzoneitem05" ref="lunchzoneitem05" v-if="lunchZoneVisible"  v-bind:style="{}" :close = "hideLunchZone" 
>
</LunchZone>
 <SignUp :class="'object signupitem '"   id="signupitem" ref="signupitem" v-if="signupVisible"  v-bind:style="{}" :close = "hideSignUP" 
 :showLogIn = "showLogIn" 
>
</SignUp>
 <Login :class="'object loginitem '"   id="loginitem" ref="loginitem" v-if="loginVisible"  v-bind:style="{}" :close = "hideLogIn" 
 :showSignUp = "showSignUP" 
>
</Login>
 <NotificationZone :class="'object notificationzone '"   id="notificationzone" ref="notificationzone" v-if="notificationVisible"  v-bind:style="{}" :close = "hideNotification" 
>
</NotificationZone>
 <PanelUser :class="'object userPanel '"   id="userPanel" ref="userPanel" v-if="userPanelVisible"  v-bind:style="{}" :close = "hideUserPanel" 
>
</PanelUser>  </div>
 
      </div>
 </div> 
</template>
<script>
import TopBar from '../../At/Components/TopBar'
import Footer from '../../At/Components/Footer'
import OnboardingStepper from '../../At/Components/OnboardingStepper'
import NavPanel from '../../At/Components/NavPanel'
import LunchZone from '../../At/Components/LunchZone'
import SignUp from '../../At/Components/Authentification/SignUp'
import Login from '../../At/Components/Authentification/Login'
import NotificationZone from '../../At/Components/NotificationZone'
import PanelUser from '../../At/Components/PanelUser'


export default {
	name: 'TemplatePage',

    components: {TopBar,Footer,OnboardingStepper,NavPanel,LunchZone,SignUp,Login,NotificationZone,PanelUser,},
	methods: {
	
		showLunchZone: function() { 		
	this.lunchZoneVisible = true

}
,
		hideLunchZone: function() { 		
	this.lunchZoneVisible = false

}
,
		showPanel: function() { 		
	this.panelVisible = true

}
,
		hidePanel: function() { 		
	this.panelVisible = false

}
,
		showSignUP: function() { 		
	this.signupVisible = true

}
,
		hideSignUP: function() { 		
	this.signupVisible = false

}
,
		showLogIn: function() { 		
	this.loginVisible = true

}
,
		hideLogIn: function() { 		
	this.loginVisible = false

}
,
		showNotification: function() { 		
	this.notificationVisible = true

}
,
		hideNotification: function() { 		
	this.notificationVisible = false

}
,
		showUserPanel: function() { 		
	this.userPanelVisible = true

}
,
		hideUserPanel: function() { 		
	this.userPanelVisible = false

}
,
		minifyOnBoard: function(){ 		
	this.onboardingStepperH = 33

}
,
		expendOnBoard: function(){ 		
	this.onboardingStepperH = 318

}
,
	},
	mounted(){
			
	setTimeout(function(){ this.showOnBoard = true }.bind(this), 3000);

	},
	computed: {
	
		haveVideo: function() { 		
	if(this.pageData.videos){

		return this.pageData.videos.length > 0

	}

	return false

}
,
	},
	data(){
	return {
		
		showOnBoard:  false		,
		lunchZoneVisible:  false		,
		panelVisible:  false		,
		signupVisible:  false		,
		loginVisible:  false		,
		notificationVisible:  false		,
		userPanelVisible:  false		,
		onboardingStepperH:  318		,
		}
	},
	props: {
	
		currentPage: {					required: false,			default: () =>  0,		},
		pageData: {					required: false,			default: () =>  ({}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#TemplatePage{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

@import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');

:not(i):not(.icon){
	font-family: 'Merienda', cursive, fantasy !important;
	
	.text.text-content{
		font-family: 'Merienda', cursive, fantasy !important;	
	}
	
	i, .icon{
		font-family: inherit !important;	
	}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.scrollarea.scrollarea0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

overflow-x:hidden ; 
overflow-y:scroll ; 
background-color:#f6f7f9 ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #ff6b00;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

	.column.columnscrollarea02{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:40px ; background-color: transparent ; 

		.object.topbar{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 10px ; 
		
		margin-right: 10px ; 
		height:54px!important ; 
		width: calc(100% - (10px + 10px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.slot.mainSlot{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 10px ; 
		
		margin-right: 10px ; 
		height:auto!important ; 
		width: calc(100% - (10px + 10px)) !important;
		}

		.text.versionText{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:100px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#959595 ; 
		font-size:20px ; 
		text-align:center;
		align-items:flex-end;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

		.object.footercolumnscrollarea023{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:100px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.transition.transitionitem04{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 15px ; 

bottom: 15px ; 
width:340px!important ; 


	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.navPanel{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.lunchzoneitem05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.signupitem{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loginitem{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.notificationzone{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.userPanel{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea02{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.topbar{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.slot.mainSlot{

		
		position:relative !important; 
		
		
		
		
		}

		.text.versionText{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.object.footercolumnscrollarea023{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.transition.transitionitem04{


position:absolute !important; 




width:90%!important ; 


	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.navPanel{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.lunchzoneitem05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.signupitem{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loginitem{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.notificationzone{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.userPanel{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea02{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.topbar{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.slot.mainSlot{

		
		position:relative !important; 
		
		
		
		
		}

		.text.versionText{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.object.footercolumnscrollarea023{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.transition.transitionitem04{


position:absolute !important; 






	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.navPanel{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.lunchzoneitem05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.signupitem{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loginitem{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.notificationzone{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.userPanel{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea02{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.topbar{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.slot.mainSlot{

		
		position:relative !important; 
		
		
		
		margin-left: 10% ; 
		
		margin-right: 10% ; 
		width: calc(100% - (10% + 10%)) !important;
		}

		.text.versionText{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.object.footercolumnscrollarea023{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.transition.transitionitem04{


position:absolute !important; 






	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.navPanel{


position:absolute !important; 




display:none !important; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.lunchzoneitem05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.signupitem{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loginitem{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.notificationzone{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.userPanel{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea02{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.topbar{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.slot.mainSlot{

		
		position:relative !important; 
		
		
		
		margin-left: 18% ; 
		
		margin-right: 18% ; 
		width: calc(100% - (18% + 18%)) !important;
		}

		.text.versionText{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.object.footercolumnscrollarea023{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.transition.transitionitem04{


position:absolute !important; 






	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.navPanel{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.lunchzoneitem05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.signupitem{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loginitem{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.notificationzone{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.userPanel{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea02{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.topbar{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.slot.mainSlot{

		
		position:relative !important; 
		
		
		
		
		}

		.text.versionText{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.object.footercolumnscrollarea023{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.transition.transitionitem04{


position:absolute !important; 






	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.navPanel{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.lunchzoneitem05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.signupitem{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loginitem{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.notificationzone{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.userPanel{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
