<template>
 <div>  
<div id="AppCard"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-card 
 :class="'card card0 ' + 'bannerArea'"   id="card0" ref="card0" v-on:click="bannerAction"   v-bind:style="{backgroundColor : bgColor,}">
 <v-img  :class="'image imagerectangleitem013 '"   id="imagerectangleitem013" ref="imagerectangleitem013"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + bgImg + '')" 
>  </v-img>
 <div  :class="'item itemrectangleitem011 '"   id="itemrectangleitem011" ref="itemrectangleitem011"  v-bind:style="{}">
 <v-img  :class="'image imageitemrectangleitem011143 '"   id="imageitemrectangleitem011143" ref="imageitemrectangleitem011143"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + miniLogo + '')" 
>  </v-img>
 <div  :class="'column columnitemrectangleitem0113 '"   id="columnitemrectangleitem0113" ref="columnitemrectangleitem0113"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text titel ' + 'overflowedText'"   id="titel" ref="titel"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ title }}</div>

   </div>
 <div  :class="'text descriptionText ' + 'overflowedText'"   id="descriptionText" ref="descriptionText"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ text }}</div>

   </div>  </div>  </div>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'AppCard',

    components: {},
	props: {
	
		miniLogo: {					required: false,			default: () =>  "Resources/abcCode_Logo_N_G_transparent.png",		},
		title: {					required: false,			default: () =>  "abcCode",		},
		text: {					required: false,			default: () =>  "...",		},
		bannerAction: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		bgColor: {					required: false,			default: () =>  "#414a51",		},
		bgImg: {					required: false,			default: () =>  "Resources/background/0112419d011ec5139aa01521350ec49a.png",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AppCard{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

#card0{
	overflow: hidden;
}
	
.bannerArea{
	cursor: pointer;
	&:hover{
    transform: scale(1.01);
	}
}
.padded{
	padding: 16px !important;
}
	

.descriptionText{
	.text-content{
    height: 100%;
	}
}
	
.overflowedText{
	overflow: hidden;
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 5px ; 

top: 5px ; 
right: 5px ; 
bottom: 5px ; 
background-color:#ffffff ; 
border-radius:20px ; max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 15px ; 
	
	top: 15px ; 
	right: 15px ; 
	bottom: 15px ; 
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		top: 0px ; 
		width:40px!important ; 
		height:40px!important ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 50px ; 
		right: 0px ; 
		bottom: 0px ; 
		display:none !important; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		justify-content:space-between ; background-color: transparent ; 

			.text.titel{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:48%!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#fff ; 
			font-size:20px ; 
			.text-content{
			width: 100%;
			white-space: normal;
			font-weight: bold;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			opacity: 0.7 ;  filter: alpha(opacity=70) ; 
			height:48%!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#fff ; 
			font-size:16px ; 
			align-items:flex-end;
			display: flex;
			.text-content{
			width: 100%;
			white-space: normal;
			}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.titel{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.titel{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	left: 20px ; 
	
	top: 20px ; 
	right: 20px ; 
	bottom: 20px ; 
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		 display:flex !important; 
		
		
		
		

			.text.titel{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.titel{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.titel{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}
}
</style>
