<template>
 <div>  
<div id="Profile"> 
         
 <TemplatePage :class="'object templatepage0 '"   id="templatepage0" ref="templatepage0"  v-bind:style="{}" :currentPage = "'null'" 
>
 <template  v-slot:main>
 <div  :class="'slottemplate slottemplatetemplatepage01 '"   id="slottemplatetemplatepage01" ref="slottemplatetemplatepage01"  v-bind:style="{}">
 <div  :class="'column mainColumn1 '"   id="mainColumn1" ref="mainColumn1"  v-bind:style="{}">
 <div  :class="'rectangle rectanglemainColumn11 '"   id="rectanglemainColumn11" ref="rectanglemainColumn11"  v-bind:style="{backgroundColor : '#ffbc13',}">
 <v-img  :class="'image imagerectanglemainColumn111 '"   id="imagerectanglemainColumn111" ref="imagerectanglemainColumn111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/background/0112419d011ec5139aa01521350ec49a.png')" 
>  </v-img>
 <div  :class="'flow flow0 '"   id="flow0" ref="flow0"  v-bind:style="{justifyContent : 'space-around',}">
 <v-btn tag="div" 
 :color = "'#fff'" 
 :outlined = "!(currentTab == 0)" 
 @click="goToTab0" :class="'button tab1 ' + 'textButton'"   id="tab1" ref="tab1" :elevation = "0" 
  v-bind:style="{}"><span>{{ 'Créations' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#fff'" 
 :outlined = "!(currentTab == 1)" 
 @click="goToTab1" :class="'button tab12 ' + 'textButton'"   id="tab12" ref="tab12" :elevation = "0" 
  v-bind:style="{}"><span>{{ 'Amis' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#fff'" 
 :outlined = "!(currentTab == 2)" 
 @click="goToTab2" :class="'button tab13 ' + 'textButton'"   id="tab13" ref="tab13" v-if="settingEnable" :elevation = "0" 
  v-bind:style="{}"><span>{{ 'Paramètres' }}</span>
</v-btn>  </div>
 <v-card 
 :class="'card profileCard '"   id="profileCard" ref="profileCard" :elevation = "8" 
  v-bind:style="{}">
 <v-img  :class="'networkimage profileIMG '"   id="profileIMG" ref="profileIMG"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "userImage" 
>  </v-img>  </v-card>
 <div  :class="'column editColumn '"   id="editColumn" ref="editColumn"  v-bind:style="{}">
 <div  :class="'text texteditColumn1 '"   id="texteditColumn1" ref="texteditColumn1"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ userData.username }}</div>

   </div>
 <div  :class="'row roweditColumn3 '"   id="roweditColumn3" ref="roweditColumn3"  v-bind:style="{}">
 <div  :class="'rectangle xp '"   id="xp" ref="xp"  v-bind:style="{backgroundColor : '#64ff11',}">
 <div  :class="'text textxp1 '"   id="textxp1" ref="textxp1"  v-bind:style="{}">
<div class="text-content">{{ 'XP' }}</div>

   </div>  </div>
 <div  :class="'text xpNumber '"   id="xpNumber" ref="xpNumber"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ 0 }}</div>

   </div>  </div>
 <v-btn tag="div" 
 :rounded = "true" 
 :class="'button buttoneditColumn5 '"   id="buttoneditColumn5" ref="buttoneditColumn5"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-md-create' !== undefined"  :left = "true" 
>{{ 'ion-md-create' }}
</v-icon><span>{{ 'Editer' }}</span>
</v-btn>  </div>  </div>
 <ProfileCreation :class="'object profilecreationmain '"   id="profilecreationmain" ref="profilecreationmain" v-show="(currentTab == 0)"  v-bind:style="{}" :targetId = "targetId" 
>
</ProfileCreation>
 <ProfileFriends :class="'object profilefriendsmain '"   id="profilefriendsmain" ref="profilefriendsmain" v-show="(currentTab == 1)"  v-bind:style="{}" :targetId = "targetId" 
>
</ProfileFriends>
 <ProfileSetting :class="'object profilesettingmain '"   id="profilesettingmain" ref="profilesettingmain" v-show="(currentTab == 2)"  v-bind:style="{}">
</ProfileSetting>  </div></div></template>
</TemplatePage>
 
      </div>
 </div> 
</template>
<script>
import TemplatePage from '../../At/Pages/TemplatePage'
import ProfileCreation from '../../At/Components/Profile/ProfileCreation'
import ProfileFriends from '../../At/Components/Profile/ProfileFriends'
import ProfileSetting from '../../At/Components/Profile/ProfileSetting'

import * as utils from '../../Js/utils.js'
export default {
	name: 'Profile',

    components: {TemplatePage,ProfileCreation,ProfileFriends,ProfileSetting,},
	methods: {
	
		goToTab0: function() { 		
	this.currentTab = 0

}
,
		goToTab1: function() { 		
	this.currentTab = 1

}
,
		goToTab2: function() { 		
	this.currentTab = 2

}
,
		getUserData: function(){ 			
	if(this.$store.state.user.id == this.targetId){

		this.userData = this.$store.state.user;

	} else {

		utils.getUserData(this.targetId)

		.then(result => {

			this.userData = result.data

		})

	}

}
,
	},
	created(){
			
	if(this.$store.state.user == null){

		this.$router.push('/')

	}

	if(this.$route.params.tab){

		let tabToGo = this.$route.params.tab;

		

		if(tabToGo == "amis"){

			this.currentTab = 1

		} else if(tabToGo == "parametres") {

			this.currentTab = 2

		}

	}

		

	if(this.$route.params.id){

		let userId = parseInt(this.$route.params.id);

		

		this.targetId = userId

		this.settingEnable = false

	} else {

		this.targetId = this.$store.state.user.id

	}

	

	this.getUserData()

	},
	computed: {
	
		m_userData: function(){ 		
		return this.$store.state.user;

}
,
		userImage: function(){		
	if(this.userData && this.userData.profile_image){

		return this.$store.state.apiURL + this.userData.profile_image.url

	} else {

		return "https://picsum.photos/200"

	}

}
,
	},
	watch: {
	
		m_userData: function(val, old){ 		
	if(val){

	} else {

		if(old == targetId){

			this.$router.push('/')

		}

	}

}
,
	},
	data(){
	return {
		
		currentTab:  0		,
		targetId:  null		,
		settingEnable:  true		,
		userData:  ({})		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Profile{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.textButton{
	padding: 10px !important;
}



.object.templatepage0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
width:100px!important ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn1{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:20px ; background-color: transparent ; 

		.rectangle.rectanglemainColumn11{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:400px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		background-color:#ffbc13 ; 
		border-radius:10px ; 

			.image.imagerectanglemainColumn111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			
}

			.flow.flow0{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 20px ; 
			
			right: 10px ; 
			bottom: -1px ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: wrap;
			flex: initial;
			justify-content:space-around ; background-color:transparent ; 

				.button.tab1{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				margin-top: 0px ; 
				width:auto!important ; 
				height:40px!important ; 
				
				font-size:20px ; 
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				font-size:20px ; 
				}
				}

				.button.tab12{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				margin-top: 0px ; 
				width:auto!important ; 
				height:40px!important ; 
				
				font-size:20px ; 
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				font-size:20px ; 
				}
				}

				.button.tab13{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				margin-top: 0px ; 
				width:auto!important ; 
				height:40px!important ; 
				
				font-size:20px ; 
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				font-size:20px ; 
				}
				}

			
}

			.card.profileCard{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
			
			left: calc(50% + 0px) ; 
			
			top: calc(50% + 0px) ; 
			width:200px!important ; 
			height:200px!important ; 
			background-color:#ffffff ; 
			border-radius:100px ; 
			border-style:solid ; 
			border-width:0px ; 
			border-color:undefined ; max-width: initial;min-width: initial;

				.networkimage.profileIMG{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: inherit;

				flex: unset;
				border-radius:100px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

				
}

			
}

			.column.editColumn{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 20px ; 
			
			top: calc(50% + 0px) ; 
			width:100px!important ; 
			height:100px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:10px ; background-color: transparent ; 

				.text.texteditColumn1{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#fff ; 
				font-family:Century Gothic ; 
				font-size:38px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

				.row.roweditColumn3{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:25px!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;
				gap:8px ; background-color:transparent ; 

					.rectangle.xp{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					margin-top: 0px ; 
					width:24px!important ; 
					height:24px!important ; 
					
					background-color:#64ff11 ; 
					border-radius:4px ; 

						.text.textxp1{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left: 0px ; 
						
						top: 0px ; 
						right: 0px ; 
						bottom: 0px ; 
						
						font-size:12px ; 
						text-align:center;
						align-items:center;
						display: flex;
						.text-content{
						width: 100%;
						font-weight: bold;
						}

						
}

					
}

					.text.xpNumber{

					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
					
					
					top: calc(50% + 0px) ; 
					width:auto!important ; 
					height:auto!important ; 
					
					color:#fff ; 
					font-size:20px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

				
}

				.button.buttoneditColumn5{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:100px!important ; 
				height:30px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.object.profilecreationmain{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilefriendsmain{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width:100px!important ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilesettingmain{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		width:100px!important ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn1{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.rectangle.rectanglemainColumn11{

		
		position:relative !important; 
		
		
		
		
		
		

			.image.imagerectanglemainColumn111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.flow.flow0{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.button.tab1{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.tab12{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.tab13{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.card.profileCard{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.profileIMG{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

			.column.editColumn{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.texteditColumn1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.roweditColumn3{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.rectangle.xp{

					
					position:relative !important; 
					
					
					
					
					
					

						.text.textxp1{

						
						position:absolute !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

					.text.xpNumber{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoneditColumn5{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.object.profilecreationmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilefriendsmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilesettingmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn1{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.rectangle.rectanglemainColumn11{

		
		position:relative !important; 
		
		
		
		
		
		

			.image.imagerectanglemainColumn111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.flow.flow0{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.button.tab1{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.tab12{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.tab13{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.card.profileCard{

			
			position:absolute !important; 
			
			
			
			
			width:250px!important ; 
			height:250px!important ; 
			
			border-radius:225px ; max-width: initial;min-width: initial;

				.networkimage.profileIMG{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
				border-radius:250px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

			.column.editColumn{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.texteditColumn1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.roweditColumn3{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.rectangle.xp{

					
					position:relative !important; 
					
					
					
					
					
					

						.text.textxp1{

						
						position:absolute !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

					.text.xpNumber{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoneditColumn5{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.object.profilecreationmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilefriendsmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilesettingmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn1{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.rectangle.rectanglemainColumn11{

		
		position:relative !important; 
		
		
		
		
		
		

			.image.imagerectanglemainColumn111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.flow.flow0{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.button.tab1{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.tab12{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.tab13{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.card.profileCard{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.profileIMG{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

			.column.editColumn{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.texteditColumn1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.roweditColumn3{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.rectangle.xp{

					
					position:relative !important; 
					
					
					
					
					
					

						.text.textxp1{

						
						position:absolute !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

					.text.xpNumber{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoneditColumn5{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.object.profilecreationmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilefriendsmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilesettingmain{

		
		position:relative !important; 
		
		
		
		margin-left: 50px ; 
		
		margin-right: 50px ; 
		width: calc(100% - (50px + 50px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn1{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.rectangle.rectanglemainColumn11{

		
		position:relative !important; 
		
		
		
		
		
		

			.image.imagerectanglemainColumn111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.flow.flow0{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.button.tab1{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.tab12{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.tab13{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.card.profileCard{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.profileIMG{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

			.column.editColumn{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.texteditColumn1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.roweditColumn3{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.rectangle.xp{

					
					position:relative !important; 
					
					
					
					
					
					

						.text.textxp1{

						
						position:absolute !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

					.text.xpNumber{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoneditColumn5{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.object.profilecreationmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilefriendsmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilesettingmain{

		
		position:relative !important; 
		
		
		
		margin-left: 100px ; 
		
		margin-right: 100px ; 
		width: calc(100% - (100px + 100px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn1{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.rectangle.rectanglemainColumn11{

		
		position:relative !important; 
		
		
		
		
		
		

			.image.imagerectanglemainColumn111{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.flow.flow0{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.button.tab1{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.tab12{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.tab13{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.card.profileCard{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.profileIMG{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

			.column.editColumn{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.texteditColumn1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.roweditColumn3{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.rectangle.xp{

					
					position:relative !important; 
					
					
					
					
					
					

						.text.textxp1{

						
						position:absolute !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

					.text.xpNumber{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoneditColumn5{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.object.profilecreationmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilefriendsmain{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilesettingmain{

		
		position:relative !important; 
		
		
		
		margin-left: 10% ; 
		
		margin-right: 10% ; 
		width: calc(100% - (10% + 10%)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
