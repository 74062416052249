<template>
 <div>  
<div id="ProfileFriends"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'row row '"   id="row" ref="row"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :outlined = "!(tab == 0)" 
 :rounded = "true" 
 :dark = "true" 
 @click="goToTab0" :class="'button buttonrow1 ' + 'textButton'"   id="buttonrow1" ref="buttonrow1"  v-bind:style="{}"><span>{{ 'Suiveurs' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :outlined = "!(tab == 1)" 
 :rounded = "true" 
 :dark = "true" 
 @click="goToTab1" :class="'button buttonrow12 ' + 'textButton'"   id="buttonrow12" ref="buttonrow12"  v-bind:style="{}"><span>{{ 'Suivant' }}</span>
</v-btn>  </div>
 <v-card 
 :class="'card cardcolumn03 '"   id="cardcolumn03" ref="cardcolumn03" v-if="(friendsCount == 0)"  v-bind:style="{}">
 <div  :class="'text textrowcolumn0131 '"   id="textrowcolumn0131" ref="textrowcolumn0131"  v-bind:style="{color : '#5f5f5f',}">
<div class="text-content">{{ noData[tab] }}</div>

   </div>  </v-card>
 <v-row  :class="'grid grid '"   id="grid" ref="grid"  v-bind:style="{}">
 <v-col  :class="'col col '"   id="col" ref="col" v-for="(item,index) in followerList" :key="'col' + index" v-if="(tab == 0)"  v-bind:style="{}" :cols = "6" 
 :md = "3" 
 :sm = "4" 
><div class="col-container">
 <UserTile :class="'object usertilecol1 '"   id="usertilecol1" ref="usertilecol1"  v-bind:style="{}" :userID = "item.follower.id" 
 :userImage = "item.follower.profile_image.url" 
 :userName = "item.follower.username" 
>
</UserTile></div></v-col>
 <v-col  :class="'col col '"   id="col" ref="col" v-for="(item,index) in followedList" :key="'col' + index" v-if="(tab == 1)"  v-bind:style="{}" :cols = "6" 
 :md = "3" 
 :sm = "4" 
><div class="col-container">
 <UserTile :class="'object usertilecol1 '"   id="usertilecol1" ref="usertilecol1"  v-bind:style="{}" :getFollowed = "getFollowed" 
 :userID = "item.followed.id" 
 :userImage = "item.followed.profile_image.url" 
 :followID = "item.id" 
 :userName = "item.followed.username" 
>
</UserTile></div></v-col>  </v-row>
 <v-pagination 
 :color = "'#ff6b00'" 
 :length = "friendsCount" 
 :circle = "true" 
 :class="'pagination pagination '"   id="pagination" ref="pagination" v-model="currentPage"  v-bind:style="{}"></v-pagination>  </div>
 
      </div>
 </div> 
</template>
<script>
import UserTile from '../../../At/Components/Profile/UserTile'

import * as utils from '../../../Js/utils.js'
export default {
	name: 'ProfileFriends',

    components: {UserTile,},
	methods: {
	
		goToTab0: function(){ 		
	this.tab = 0

}
,
		goToTab1: function(){ 		
	this.tab = 1

}
,
		getFollowed: function(){ 		
	let filter = {

		follower: this.targetId

	}

	utils.followers(filter)

	.then((response)=>{

			this.followedList = response.data.filter(e => {

				return (e.followed !== null)

			})

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
		getFollower: function(){ 		
	let filter = {

		followed: this.targetId

	}

	utils.followers(filter)

	.then((response)=>{

			this.followerList = response.data.filter(e => {

				return (e.follower !== null)

			})

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
	},
	mounted(){
			
	this.getFollowed();

	this.getFollower();

	},
	computed: {
	
		friendsCount: function() { 			
	return (this.tab == 0?

		Math.ceil(this.followerList.length/this.maxFriendPerPage):

		Math.ceil(this.followedList.length/this.maxFriendPerPage));

}
,
	},
	data(){
	return {
		
		noData:  [		
	"Personne ne vous suit encore.",

	"Vous n'avez encore suivi personne."

]
,
		tab:  0		,
		maxFriendPerPage:  6		,
		currentPage:  0		,
		followedList:  []		,
		followerList:  []		,
		}
	},
	props: {
	
		targetId: {					required: false,			default: () =>  0,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ProfileFriends{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.textButton{
	padding: 10px !important;
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:20px ; background-color: transparent ; 

.row.row{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

width:auto!important ; 
height:41px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:25px ; background-color:transparent ; 

	.button.buttonrow1{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}

	.button.buttonrow12{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}


}

.card.cardcolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:110px!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color:#ffffff ; 
border-radius:8px ; max-width: initial;min-width: initial;

	.text.textrowcolumn0131{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left: 10px ; 
	
	top: calc(50% + 0px) ; 
	right: 10px ; 
	height:auto!important ; 
	
	color:#5f5f5f ; 
	font-size:20px ; 
	text-align:center;
	display: flex;
	text-overflow: ellipsis ; 
	.text-content{
	width: 100%;
	white-space: nowrap;
	overflow: hidden ;
	text-overflow: ellipsis ; 
	font-weight: bold;
	}

	
}


}

.grid.grid{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.col{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:260px!important ; 
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.col{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	height:260px!important ; 
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.pagination{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:36px!important ; 
width: calc(100% - (0px + 0px)) !important;
}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.row.row{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrow1{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrow12{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.card.cardcolumn03{


position:relative !important; 




max-width: initial;min-width: initial;

	.text.textrowcolumn0131{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.grid.grid{


position:relative !important; 






	.col.col{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.col{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.pagination{


position:relative !important; 




}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.row.row{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrow1{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrow12{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.card.cardcolumn03{


position:relative !important; 




max-width: initial;min-width: initial;

	.text.textrowcolumn0131{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.grid.grid{


position:relative !important; 






	.col.col{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.col{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.pagination{


position:relative !important; 




}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.row.row{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrow1{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrow12{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.card.cardcolumn03{


position:relative !important; 




max-width: initial;min-width: initial;

	.text.textrowcolumn0131{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.grid.grid{


position:relative !important; 






	.col.col{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.col{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.pagination{


position:relative !important; 




}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.row.row{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrow1{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrow12{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.card.cardcolumn03{


position:relative !important; 




max-width: initial;min-width: initial;

	.text.textrowcolumn0131{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.grid.grid{


position:relative !important; 






	.col.col{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.col{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.pagination{


position:relative !important; 




}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.row.row{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrow1{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrow12{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.card.cardcolumn03{


position:relative !important; 




max-width: initial;min-width: initial;

	.text.textrowcolumn0131{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.grid.grid{


position:relative !important; 






	.col.col{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.col{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.usertilecol1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.pagination{


position:relative !important; 




}


}
}
}
</style>
