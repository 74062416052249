<template>
 <div>  
<div id="main"> 
         
 <div  id="appli" :class="'applicationwindow appli '">
 <Home :class="'object home '"   id="home" ref="home"  v-bind:style="{}">
</Home>  </div>
 
      </div>
 </div> 
</template>
<script>
import Home from '../At/Pages/Home'


export default {
	name: 'main',

    components: {Home,},
} 
</script>

<style lang = "scss" scoped>


 div#main{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.applicationwindow.appli{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.home{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.applicationwindow.appli{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.home{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.applicationwindow.appli{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.home{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.applicationwindow.appli{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.home{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.applicationwindow.appli{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.home{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.applicationwindow.appli{

position:absolute ; 
left: 0;
top:0;
right: 0;
bottom: 0;

.object.home{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
