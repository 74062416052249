<template>
 <div>  
<div id="Explore"> 
         
 <TemplatePage :class="'object templatepage0 '"   id="templatepage0" ref="templatepage0"  v-bind:style="{}" :pageData = "pageData" 
 :currentPage = "2" 
>
 <template  v-slot:main>
 <div  :class="'slottemplate slottemplatetemplatepage01 '"   id="slottemplatetemplatepage01" ref="slottemplatetemplatepage01"  v-bind:style="{}">
 <div  :class="'column columncolumnscrollarea02101 '"   id="columncolumnscrollarea02101" ref="columncolumnscrollarea02101"  v-bind:style="{}">
 <Banner :class="'object herderBanner '"   id="herderBanner" ref="herderBanner"  v-bind:style="{}" :bannerAction = "goToFirstExploration" 
 :bannerMessages = "pageData.banner_messages" 
 :backgroundColor = "'#56445d'" 
 :title = "'Explorer'" 
 :buttonText = "'Allons y !'" 
>
</Banner>
 <Ep_Filter :class="'object ep_filtercolumncolumnscrollarea02103 '"   id="ep_filtercolumncolumnscrollarea02103" ref="ep_filtercolumncolumnscrollarea02103"  v-bind:style="{}" :showAll = "togelLiked" 
 :showLiked = "togelLiked" 
 :getProjects = "getProjects" 
>
</Ep_Filter>
 <v-row  :class="'grid gridcolumncolumnscrollarea02105 '"   id="gridcolumncolumnscrollarea02105" ref="gridcolumncolumnscrollarea02105"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumnscrollarea021051 '"   id="colgridcolumncolumnscrollarea021051" ref="colgridcolumncolumnscrollarea021051" v-for="(item,index) in projectList" :key="'colgridcolumncolumnscrollarea021051' + index"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
 :sm = "6" 
><div class="col-container">
 <ShareCard :class="'object sharecardcolgridcolumncolumnscrollarea0210511 '"   id="sharecardcolgridcolumncolumnscrollarea0210511" ref="sharecardcolgridcolumncolumnscrollarea0210511"  v-bind:style="{}" :like = "likeProject" 
 :dislike = "dislikeProject" 
 :href = "('/explore/'+ item.id)" 
 :item = "item" 
 :liked = "checkIfPjWasLiked(item.id)" 
>
</ShareCard></div></v-col>  </v-row>
 <v-pagination 
 :color = "'#ff6b00'" 
 :length = "projectsCount" 
 :circle = "true" 
 :class="'pagination paginationcolumncolumnscrollarea02107 '"   id="paginationcolumncolumnscrollarea02107" ref="paginationcolumncolumnscrollarea02107" v-model="currentPage"  v-bind:style="{}"></v-pagination>  </div></div></template>
</TemplatePage>
 
      </div>
 </div> 
</template>
<script>
import TemplatePage from '../../At/Pages/TemplatePage'
import Banner from '../../At/Components/Banner'
import Ep_Filter from '../../At/Components/Explore/Ep_Filter'
import ShareCard from '../../At/Components/Explore/ShareCard'

import * as utils from '../../Js/utils.js'
export default {
	name: 'Explore',

    components: {TemplatePage,Banner,Ep_Filter,ShareCard,},
	methods: {
	
		getProjects: function(filter = {}) { 		
	let filter2 = {

		_start: (this.currentPage - 1)* this.maxItemPerPage,

		_limit: this.maxItemPerPage,

		_where: filter

	}

	

	utils.getProjects(filter2)

	.then((response)=>{

		this.projectList = response.data.map(e => {

			e.description_image.url = this.$store.state.apiURL + e.description_image.url

			return e

		})

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
		getLikedProject: function() { 		
	if(this.$store.state.user){

		let list = this.likedProjectList.map((p)=>{

			return p.project.id

		})

		

		let filter2 = {

			_start: (this.currentPage - 1)* this.maxItemPerPage,

			_limit: this.maxItemPerPage,

			user: this.$store.state.user.id,

			_where: {

				id: list

			}

		}

		utils.getProjects(filter2)

		.then((response)=>{

			this.projectList = response.data.map(e => {

				e.description_image.url = this.$store.state.apiURL + e.description_image.url

				return e

			})

		})

		.catch((error)=>{

		  console.log(error);

		});

	}

}
,
		getLikedProjectList: function() { 		
	if(this.$store.state.user){	

		let filter2 = {

			user: this.$store.state.user.id

		}

		utils.getLikedProjects(filter2)

		.then((response)=>{

			this.likedProjectList = response.data

		})

		.catch((error)=>{

		  console.log(error);

		});

	}

	return []

}
,
		checkIfPjWasLiked: function(pjId){ 		
	let list = this.likedProjectList.map((p)=>{

			return p.project.id

		})

	return list.includes(pjId)

}
,
		likeProject: function(pjId){ 		
	let filter = {

		user: this.$store.state.user.id,

		project: pjId

	}

	utils.likeProject(filter)

	.catch((error)=>{

	  console.log(error);

	});

}
,
		dislikeProject: function(pjId){ 		
	let list = this.likedProjectList.filter((p)=>{

			return (p.project.id == pjId)

	})

	utils.dislikeProject(list[0].id)

	.catch((error)=>{

	  console.log(error);

	});

}
,
		togelLiked: function() { 		
	if(this.likedMode){

		this.getProjects()

	} else {

		this.getLikedProjectList()

		this.getLikedProject()

	}

	this.likedMode = !this.likedMode

}
,
		goToFirstExploration: function(){ 		
	this.$router.push('/explore/' + this.projectList[0].id)

}
,
		getPageData: function() { 		
	utils.getPageData('explorer-page')

	.then((res) => {

		this.pageData = res.data

	})

}
,
	},
	mounted(){
			
	// Get count of project

	utils.getCount('projects', ({}))

	.then((response) => {

		this.m_projectsCount = Math.ceil(response.data/this.maxItemPerPage)

	})

	.catch(error => console.log(error))

	

	// Get the list of projects

	this.getProjects()

	

	// Get liked projects

	this.getLikedProjectList()

	

	this.getPageData()

	},
	computed: {
	
		projectsCount: function() { 			
	return (this.likedMode?

		Math.ceil(this.projectList.length/this.maxItemPerPage) :

		this.m_projectsCount

	)

}
,
	},
	watch: {
	
		currentPage: function(newVal){ 		
	this.getProjects()

}
,
	},
	data(){
	return {
		
		pageData:  ({})			,
		projectList:  []		,
		m_projectsCount:  0		,
		likedProjectList:  []		,
		currentPage:  1		,
		maxItemPerPage:  6		,
		likedMode:  false		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Explore{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.templatepage0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
width:100px!important ; 
height:100px!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:20px ; background-color: transparent ; 

		.object.herderBanner{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:300px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.ep_filtercolumncolumnscrollarea02103{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02105{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color: transparent ; 

			.col.colgridcolumncolumnscrollarea021051{

			
			position:relative !important; 
			margin: unset;
			transform-origin: top left;
			
			
			
			width:100px!important ; 
			height:350px!important ; 
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.sharecardcolgridcolumncolumnscrollarea0210511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-top: 0px ; 
				margin-right: 0px ; 
				margin-bottom: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				height: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea02107{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:36px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		}

	
}


}


}


@media (max-width: 600px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.ep_filtercolumncolumnscrollarea02103{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02105{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridcolumncolumnscrollarea021051{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.sharecardcolgridcolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea02107{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 601px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.ep_filtercolumncolumnscrollarea02103{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02105{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridcolumncolumnscrollarea021051{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.sharecardcolgridcolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea02107{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 961px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.ep_filtercolumncolumnscrollarea02103{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02105{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridcolumncolumnscrollarea021051{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.sharecardcolgridcolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea02107{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1265px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.ep_filtercolumncolumnscrollarea02103{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02105{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridcolumncolumnscrollarea021051{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.sharecardcolgridcolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea02107{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1905px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.ep_filtercolumncolumnscrollarea02103{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02105{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridcolumncolumnscrollarea021051{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.sharecardcolgridcolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea02107{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}
}
</style>
