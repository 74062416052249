<template>
 <div>  
<div id="Login"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectangleitem0111 '"   id="mousearearectangleitem0111" ref="mousearearectangleitem0111"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'rectangle rectangleitem03 ' + 'noOverflow'"   id="rectangleitem03" ref="rectangleitem03"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerectangleitem031 '"   id="rectanglerectangleitem031" ref="rectanglerectangleitem031"  v-bind:style="{backgroundColor : '#e9ebec',}">
 <v-img  :class="'image imagerectanglerectangleitem0311 '"   id="imagerectanglerectangleitem0311" ref="imagerectanglerectangleitem0311"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../../Resources/background/confetis.png')" 
>  </v-img>
 <div  :class="'row rowrectanglerectangleitem0314 '"   id="rowrectanglerectangleitem0314" ref="rowrectanglerectangleitem0314"  v-bind:style="{}">
 <Logo :class="'object logorectanglerectangleitem0313 '"   id="logorectanglerectangleitem0313" ref="logorectanglerectangleitem0313"  v-bind:style="{}">
</Logo>
 <div  :class="'text textrowrectanglerectangleitem03143 '"   id="textrowrectanglerectangleitem03143" ref="textrowrectanglerectangleitem03143"  v-bind:style="{}">
<div class="text-content">{{ 'Se connecter' }}</div>

   </div>  </div>  </div>
 <div  :class="'item itemrectangleitem033 '"   id="itemrectangleitem033" ref="itemrectangleitem033"  v-bind:style="{}">
 <div  :class="'column step '"   id="step" ref="step"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'column columnstep18 '"   id="columnstep18" ref="columnstep18"  v-bind:style="{}">
 <div  :class="'text footerLeft12 '"   id="footerLeft12" ref="footerLeft12"  v-bind:style="{color : '#6767ec',}">
<div class="text-content">{{ 'Nom d\' utilisateur' }}</div>

   </div>
 <v-text-field  :placeholder = "'Entrer votre nom d\' utilisateur'" 
 solo :class="'textfield textfieldrectanglestep1515 '"   id="textfieldrectanglestep1515" ref="textfieldrectanglestep1515" v-model="name" v-on:keyupenter="logIn"   v-bind:style="{}"></v-text-field>  </div>
 <div  :class="'column columnstep183 '"   id="columnstep183" ref="columnstep183"  v-bind:style="{}">
 <div  :class="'text footerLeft12 '"   id="footerLeft12" ref="footerLeft12"  v-bind:style="{color : '#6767ec',}">
<div class="text-content">{{ 'Mot de passe' }}</div>

   </div>
 <v-text-field  :placeholder = "'Entrer votre mot de passe'" 
 :append-icon = "passIcon" 
 :type = "passType" 
 solo :class="'textfield textfieldrectanglestep1515 '"   id="textfieldrectanglestep1515" ref="textfieldrectanglestep1515" v-model="pass" v-on:keyupenter="logIn"   v-bind:style="{}" @click:append="showPass"></v-text-field>  </div>
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :rounded = "true" 
 :dark = "true" 
 @click="logIn" :class="'button buttonstep17 '"   id="buttonstep17" ref="buttonstep17"  v-bind:style="{}"><span>{{ 'Se connecter' }}</span>
</v-btn>
 <div  :class="'text errorText4 '"   id="errorText4" ref="errorText4" v-if="errorVisible"  v-bind:style="{color : '#ff4608',}">
<div class="text-content">{{ error }}</div>

   </div>
 <div  :class="'row footer5 ' + 'rowWrap'"   id="footer5" ref="footer5"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'column columnfooter4 '"   id="columnfooter4" ref="columnfooter4"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :text = "true" 
 @click="switchSignup" :class="'button connectBtn '"   id="connectBtn" ref="connectBtn"  v-bind:style="{}"><span>{{ 'S\' enregistrer' }}</span>
</v-btn>
 <div  :class="'row footerRight ' + 'rowWrap'"   id="footerRight" ref="footerRight"  v-bind:style="{}">
 <div  :class="'text textcolgriditemrectangleitem0331111 '"   id="textcolgriditemrectangleitem0331111" ref="textcolgriditemrectangleitem0331111"  v-bind:style="{color : '#9fa0a7',}">
<div class="text-content">{{ 'Pas de compte?' }}</div>

   </div>
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :text = "true" 
 @click="switchSignup" :class="'button buttonrowcolgriditemrectangleitem03313123 '"   id="buttonrowcolgriditemrectangleitem03313123" ref="buttonrowcolgriditemrectangleitem03313123"  v-bind:style="{}"><span>{{ 'S\' enregistrer' }}</span>
</v-btn>  </div>  </div>
 <div  :class="'text footerLeft '"   id="footerLeft" ref="footerLeft"  v-bind:style="{color : '#9fa0a7',}">
<div class="text-content">{{ 'Privacy Policy' }}</div>

   </div>  </div>  </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import Logo from '../../../At/Components/Logo'

import axios from 'axios';
export default {
	name: 'Login',

    components: {Logo,},
	methods: {
	
		showPass: function() { 		
	if(this.passVisible){

		this.passType = 'text'

		this.passIcon = 'ion-ios-eye-off'

		this.passVisible = false

	} else {

		this.passType = 'password'

		this.passIcon = 'ion-ios-eye'

		this.passVisible = true

	}

}
,
		showError: function(message) { 		
	this.error = message

	this.errorVisible = true

}
,
		hideError: function() { 		
	this.error = ""

	this.errorVisible = false

}	
,
		logIn: function() { 		
	axios

  .post(this.$store.state.apiURL + '/auth/local', {

    identifier: this.name,

    password: this.pass,

  })

  .then(response => {

    // Handle success.

    this.$store.commit('registerUser', response.data.user);

    this.$store.commit('registerToken', response.data.jwt);

		this.close()

  })

  .catch(error => {

    // Handle error.

    this.showError(error.response.data

												.message[0]

												.messages[0]

												.message);

  });

}
,
		switchSignup: function() { 		
	this.showSignUp()

	this.close()

}
,
	},
	data(){
	return {
		
		name:  null		,
		pass:  null		,
		error:  ""		,
		errorVisible:  false		,
		passVisible:  false		,
		passIcon:  'ion-ios-eye'		,
		passType:  "password"		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showSignUp: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Login{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.noOverflow{
	overflow: hidden;
}

.rowWrap{
   flex-wrap: wrap !important;
}
.button{
	padding: 10px !important;
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.4 ;  filter: alpha(opacity=40) ; 

background-color:#000 ; 

	.mousearea.mousearearectangleitem0111{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
width:341.9999999999999px!important ; 
height:400px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 

	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	height:56px!important ; 
	
	background-color:#e9ebec ; 

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		width:254px!important ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left: 20px ; 
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:40px!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:8px ; background-color:transparent ; 

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:40px!important ; 
			height:40px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:auto!important ; 
			
			font-family:Comic Sans MS ; 
			font-size:20px ; 
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 20px ; 
	
	top: 70px ; 
	right: 20px ; 
	bottom: 20px ; 
	

		.column.step{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		justify-content:space-between ; background-color: transparent ; 

			.column.columnstep18{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.text.footerLeft12{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#6767ec ; 
				font-family:Comic Sans MS ; 
				font-size:13px ; 
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:57.83333333325572px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columnstep183{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.text.footerLeft12{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#6767ec ; 
				font-family:Comic Sans MS ; 
				font-size:13px ; 
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:57.83333333325572px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.button.buttonstep17{

			height: auto;
			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			width:auto!important ; 
			height:40px!important ; 
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

			.text.errorText4{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#ff4608 ; 
			.text-content{
			width: 100%;
			}

			
}

			.row.footer5{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:30px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			justify-content:space-between ; background-color:transparent ; 

				.column.columnfooter4{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;background-color: transparent ; 

					.button.connectBtn{

					height: auto;
					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left:0px ; 
					
					width:auto!important ; 
					height:auto!important ; 
					
					font-size:10px ; 
					font-family:"Comic Sans MS" ; 
					text-transform:unset ; 
					min-width: auto;
					padding-left: 16px;
					padding-right: 16px;

					i.v-icon{
					font-size:10px ; 
					}
					}

					.row.footerRight{

					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:18px!important ; 
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: row;
					gap:5px ; background-color:transparent ; 

						.text.textcolgriditemrectangleitem0331111{

						align-self: flex-start;
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						
						margin-top: 0px ; 
						width:auto!important ; 
						height:auto!important ; 
						
						color:#9fa0a7 ; 
						font-family:Comic Sans MS ; 
						font-size:13px ; 
						.text-content{
						width: 100%;
						}

						
}

						.button.buttonrowcolgriditemrectangleitem03313123{

						height: auto;
						align-self: flex-start;
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
						
						
						top: calc(50% + 0px) ; 
						width:auto!important ; 
						height:auto!important ; 
						
						font-size:10px ; 
						font-family:"Comic Sans MS" ; 
						text-transform:unset ; 
						min-width: auto;
						padding-left: 16px;
						padding-right: 16px;

						i.v-icon{
						font-size:10px ; 
						}
						}

					
}

				
}

				.text.footerLeft{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-bottom: 0px ; 
				width:auto!important ; 
				height:auto!important ; 
				
				color:#9fa0a7 ; 
				font-family:Comic Sans MS ; 
				font-size:15px ; 
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem0111{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 







	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	
	
	
	
	

		.column.step{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.column.columnstep18{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columnstep183{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.button.buttonstep17{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.text.errorText4{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.footer5{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.column.columnfooter4{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.connectBtn{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.row.footerRight{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;

						.text.textcolgriditemrectangleitem0331111{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.button.buttonrowcolgriditemrectangleitem03313123{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

					
}

				
}

				.text.footerLeft{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem0111{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 







	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	
	
	
	
	

		.column.step{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.column.columnstep18{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columnstep183{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.button.buttonstep17{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.text.errorText4{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.footer5{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.column.columnfooter4{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.connectBtn{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.row.footerRight{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;

						.text.textcolgriditemrectangleitem0331111{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.button.buttonrowcolgriditemrectangleitem03313123{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

					
}

				
}

				.text.footerLeft{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem0111{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 







	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	
	
	
	
	

		.column.step{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.column.columnstep18{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columnstep183{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.button.buttonstep17{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.text.errorText4{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.footer5{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.column.columnfooter4{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.connectBtn{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.row.footerRight{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;

						.text.textcolgriditemrectangleitem0331111{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.button.buttonrowcolgriditemrectangleitem03313123{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

					
}

				
}

				.text.footerLeft{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem0111{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 







	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	
	
	
	
	

		.column.step{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.column.columnstep18{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columnstep183{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.button.buttonstep17{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.text.errorText4{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.footer5{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.column.columnfooter4{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.connectBtn{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.row.footerRight{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;

						.text.textcolgriditemrectangleitem0331111{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.button.buttonrowcolgriditemrectangleitem03313123{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

					
}

				
}

				.text.footerLeft{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem0111{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 







	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	
	
	
	
	

		.column.step{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.column.columnstep18{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columnstep183{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldrectanglestep1515{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.button.buttonstep17{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.text.errorText4{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.footer5{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.column.columnfooter4{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.connectBtn{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.row.footerRight{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;

						.text.textcolgriditemrectangleitem0331111{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.button.buttonrowcolgriditemrectangleitem03313123{

						height: auto;
						
						position:relative !important; 
						
						
						
						
						
						text-transform:unset ; 
						min-width: auto;
						i.v-icon{
						}
						}

					
}

				
}

				.text.footerLeft{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
