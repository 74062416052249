<template>
 <div>  
<div id="ButtonB"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{ opacity: opacity ,  filter: 'alpha(opacity=opacity*100)' , }">
 <div  :class="'row row0 '"   id="row0" ref="row0"  v-bind:style="{}">
 <div  :class="'icon iconrow01 '"   id="iconrow01" ref="iconrow01"  v-bind:style="{color : color,fontSize : getUnit(20,'px'),}"><i  :class="icon"></i></div>
 <div  :class="'text textrow03 '"   id="textrow03" ref="textrow03"  v-bind:style="{}">
<div class="text-content">{{ text }}</div>

   </div>  </div>
 <div  :class="'mousearea mouseareaitem03 '"   id="mouseareaitem03" ref="mouseareaitem03"  v-bind:style="{}" @click="click"></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ButtonB',

    components: {},
	computed: {
	
		color: function() { 		
	if(this.active){

		return this.activeIconColor

	} else {

		return this.iconColor

	}

}
,
		opacity: function() { 		
	if(this.active){

		return 1

	} else {

		return 0.7

	}

}
,
	},
	props: {
	
		icon: {					required: false,			default: () =>  "ion-ios-home",		},
		activeIconColor: {					required: false,			default: () =>  "#ff7b0f",		},
		iconColor: {					required: false,			default: () =>  "#656a6e",		},
		text: {					required: false,			default: () =>  "Home",		},
		click: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		active: {					required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ButtonB{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.item0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 
height:100%!important ; 


.row.row0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

margin-top: 0px ; 
width:auto!important ; 
height:100%!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;background-color:transparent ; 

	.icon.iconrow01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:25px!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	font-size:20px ; 
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrow03{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	font-size:17px ; 
	text-align:left;
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.item.item0{


position:relative !important; 






.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconrow01{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrow03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.item.item0{


position:relative !important; 






.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconrow01{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrow03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.item.item0{


position:relative !important; 






.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconrow01{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrow03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.item.item0{


position:relative !important; 






.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconrow01{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrow03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.item.item0{


position:relative !important; 






.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconrow01{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrow03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 





}


}
}
}
</style>
