<template>
 <div>  
<div id="Home"> 
         
 <TemplatePage :class="'object templatepage0 '"   id="templatepage0" ref="templatepage0"  v-bind:style="{}" :pageData = "pageData" 
 :currentPage = "0" 
>
 <template  v-slot:main>
 <div  :class="'slottemplate slottemplatetemplatepage01 '"   id="slottemplatetemplatepage01" ref="slottemplatetemplatepage01"  v-bind:style="{}">
 <div  :class="'column mainColumn '"   id="mainColumn" ref="mainColumn"  v-bind:style="{}">
 <HomeBrand :class="'object homebrand '"   id="homebrand" ref="homebrand"  v-bind:style="{}" :showSignup = "showSignup" 
 :showLogin = "showLogin" 
 :loginSininVisible = "loginSininVisible" 
>
</HomeBrand>
 <HomeSection1 :class="'object homesection1 '"   id="homesection1" ref="homesection1"  v-bind:style="{}" :imageList = "imagesList" 
 :pageData = "pageData" 
>
</HomeSection1>
 <CustomSlider :class="'object customslider '"   id="customslider" ref="customslider"  v-bind:style="{}" :slideNumber = "5" 
 :slideImages = "imagesList.slice(0, 10)" 
>
</CustomSlider>
 <SegmentContent :class="'object segmentcontent '"   id="segmentcontent" ref="segmentcontent"  v-bind:style="{}" :title = "'Une communauté créative'" 
 :info = "'Soyez inspiré, créez, jouez et partagez avec notre vibrante communauté de créateurs abcCode World. Ouvrez n\'importe quelle création, voyez le code et remixez-le pour créer quelque chose de nouveau.'" 
 :titleColor = "'#6767ec'" 
>
</SegmentContent>  </div></div></template>
</TemplatePage>
 
      </div>
 </div> 
</template>
<script>
import TemplatePage from '../../At/Pages/TemplatePage'
import HomeBrand from '../../At/Components/Home/HomeBrand'
import HomeSection1 from '../../At/Components/Home/HomeSection1'
import CustomSlider from '../../At/Components/CustomSlider'
import SegmentContent from '../../At/Components/SegmentContent'

import axios from 'axios'
import * as utils from '../../Js/utils.js'
export default {
	name: 'Home',

    components: {TemplatePage,HomeBrand,HomeSection1,CustomSlider,SegmentContent,},
	methods: {
	
		showSignup: function(){		
	this.$refs.templatepage0.showSignUP()

}
,
		showLogin: function(){		
	this.$refs.templatepage0.showLogIn()

}
,
		getPageData: function() { 		
	utils.getPageData('home-page')

	.then((res) => {

		this.pageData = res.data

	})

}
,
	},
	mounted(){
			
	utils.getImages()

	.then((response)=>{

	  this.imagesList = response.data.filter(e => {

			return (e.mime.match("image/") != null)

		});

		this.imagesList = this.imagesList.map(e => {

			e.url = this.$store.state.apiURL + e.url

			return e

		})

	})

	.catch((error)=>{

	  console.log(error);

	});

	

	this.getPageData()

	},
	computed: {
	
		loginSininVisible: function(){		
	return this.userData == null

}
,
		userData: function(){ 		
	return this.$store.state.user;

}
,
	},
	data(){
	return {
		
		imagesList:  []		,
		pageData:  ({})		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Home{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.object.templatepage0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

margin-top: 0px ; 
width:100px!important ; 
height:100px!important ; 

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:20px ; background-color: transparent ; 

		.object.homebrand{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.homesection1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.customslider{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:300px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.segmentcontent{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:100px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.homebrand{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.homesection1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.customslider{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.segmentcontent{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.homebrand{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.homesection1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.customslider{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.segmentcontent{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.homebrand{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.homesection1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.customslider{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.segmentcontent{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.homebrand{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.homesection1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.customslider{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.segmentcontent{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.mainColumn{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.homebrand{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.homesection1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.customslider{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.segmentcontent{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
