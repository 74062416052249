<template>
 <div>  
<div id="CustomSlider"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'item itemitem01 '"   id="itemitem01" ref="itemitem01"  v-bind:style="{}">
 <div  :class="'row rowitemitem011 '"   id="rowitemitem011" ref="rowitemitem011"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerowitemitem0111 '"   id="rectanglerowitemitem0111" ref="rectanglerowitemitem0111" v-for="(item,index) in numberImages" :key="'rectanglerowitemitem0111' + index"  v-bind:style="{backgroundColor : '#b1b1b1',}">
 <div  :class="'mousearea mousearearectanglerowitemitem01111 '"   id="mousearearectanglerowitemitem01111" ref="mousearearectanglerowitemitem01111"  v-bind:style="{}"></div>  </div>  </div>  </div>
 <div  :class="'item itemitem03 '"   id="itemitem03" ref="itemitem03"  v-bind:style="{}">
 <div  :class="'icon iconitemitem031 '"   id="iconitemitem031" ref="iconitemitem031"  v-bind:style="{fontSize : getUnit(20,'px'),}"><i  :class="'ion-ios-arrow-back'"></i></div>
 <div  :class="'mousearea mouseareaitemitem033 '"   id="mouseareaitemitem033" ref="mouseareaitemitem033"  v-bind:style="{}"></div>  </div>
 <div  :class="'item itemitem033 '"   id="itemitem033" ref="itemitem033"  v-bind:style="{}">
 <div  :class="'icon iconitemitem031 '"   id="iconitemitem031" ref="iconitemitem031"  v-bind:style="{fontSize : getUnit(20,'px'),}"><i  :class="'ion-ios-arrow-forward'"></i></div>
 <div  :class="'mousearea mouseareaitemitem033 '"   id="mouseareaitemitem033" ref="mouseareaitemitem033"  v-bind:style="{}"></div>  </div>
 <div  :class="'item itemitem07 '"   id="itemitem07" ref="itemitem07"  v-bind:style="{}">  </div>
 <div  :class="'scrollarea itemitem010 '"   id="itemitem010" ref="itemitem010"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'row rowitem09 '"   id="rowitem09" ref="rowitem09"  v-bind:style="{}">
 <div  :class="'item itemrowitem091 '"   id="itemrowitem091" ref="itemrowitem091" v-for="(item,index) in numberItem" :key="'itemrowitem091' + index"  v-bind:style="{marginTop : getUnit((slideItemPadding(index)),'px'),marginBottom : getUnit((slideItemPadding(index)),'px'),width : (slideItemWidth) + '%',}">
 <v-card 
 :class="'card carditemitem01051 '"   id="carditemitem01051" ref="carditemitem01051"  v-bind:style="{width : getUnit(slideWidth,'px'),}">
 <v-img  :class="'networkimage imagecarditemitem01051 '"   id="imagecarditemitem01051" ref="imagecarditemitem01051"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "slideImages[index].url" 
>  </v-img>  </v-card>  </div>  </div>
 <div  :class="'row rowitem092 '"   id="rowitem092" ref="rowitem092"  v-bind:style="{}">
 <div  :class="'item itemrowitem0911 '"   id="itemrowitem0911" ref="itemrowitem0911" v-for="(item,index) in numberItem" :key="'itemrowitem0911' + index"  v-bind:style="{marginTop : getUnit((slideItemPadding(index)),'px'),marginBottom : getUnit((slideItemPadding(index)),'px'),width : (slideItemWidth) + '%',}">
 <v-card 
 :class="'card carditemitem01051 '"   id="carditemitem01051" ref="carditemitem01051"  v-bind:style="{width : getUnit(slideWidth,'px'),}">
 <v-img  :class="'networkimage imagecarditemitem01051 '"   id="imagecarditemitem01051" ref="imagecarditemitem01051"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "slideImages[numberItem + index + 1].url" 
>  </v-img>  </v-card>  </div>  </div>
 <v-card 
 :class="'card carditemitem0105 '"   id="carditemitem0105" ref="carditemitem0105"  v-bind:style="{width : getUnit(slideWidth,'px'),}">
 <v-img  :class="'networkimage imagecarditemitem01051 '"   id="imagecarditemitem01051" ref="imagecarditemitem01051"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "slideImages[numberItem + 1].url" 
>  </v-img>  </v-card>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'CustomSlider',

    components: {},
	methods: {
	
		slideItemPadding: function(index) { 		
	let val = parseInt((this.slideNumber/2) - index) * 10

	return val

}
,
		plusSlides: function(n){		
  clearInterval();

  if (n < 0){

    this.showSlides(this.slideIndex -= 1);

  } else {

   	this.showSlides(this.slideIndex += 1); 

  }

  if (n === -1){

    this.myTimer = setInterval(function(){this.plusSlides(n + 2)}, 4000);

  } else {

    this.myTimer = setInterval(function(){this.plusSlides(n + 1)}, 4000);

  }

}
,
		showSlides: function(n){		
  if (n > this.slideNumber) {this.slideIndex = 1}

  if (n < 1) {this.slideIndex = this.slideNumber}

}
,
	},
	mounted(){
			
	this.showSlides(this.slideIndex);

	},
	computed: {
	
		slideItemWidth: function() { 		
	return (100/(this.slideNumber/2))

}
,
		numberImages: function() { 		
	return this.slideImages.length

}
,
		numberItem: function() { 		
	let number = Math.min(this.slideNumber,  this.numberImages) - 1

	return (number%2 == 0? number : number-1) / 2

}
,
	},
	data(){
	return {
		
		myTimer:  []		,
		slideIndex:  0		,
		resume:  (() =>{		
  this.clearInterval(this.myTimer)
  this.myTimer = setInterval(function(){this.plusSlides(this.slideIndex)}, 4000)
})
,
		pause:  (() => {		
  clearInterval(this.myTimer)
})
,
		}
	},
	props: {
	
		slideNumber: {					required: false,			default: () =>  5,		},
		slideImages: {					required: false,			default: () =>  [],		},
		slideWidth: {					required: false,			default: () =>  250,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#CustomSlider{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.item.itemitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
bottom: 0px ; 
height:20px!important ; 


	.row.rowitemitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	top: 0px ; 
	bottom: 0px ; 
	width:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:5px ; background-color:transparent ; 

		.rectangle.rectanglerowitemitem0111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:14px!important ; 
		height:14px!important ; 
		
		background-color:#b1b1b1 ; 
		border-radius:7px ; 

			.mousearea.mousearearectanglerowitemitem01111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			}

		
}

	
}


}

.item.itemitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

top: 0px ; 
bottom: 0px ; 
width:17px!important ; 


	.icon.iconitemitem031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	font-size:20px ; 
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.item.itemitem033{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: 0px ; 
right: 0px ; 
bottom: 0px ; 
width:17px!important ; 


	.icon.iconitemitem031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	font-size:20px ; 
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.item.itemitem07{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

top: 0px ; 
width:100px!important ; 
height:100px!important ; 



}

.scrollarea.itemitem010{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 17px ; 

top: 0px ; 
right: 17px ; 
bottom: 25px ; 

overflow:hidden ; 
overflow-x:hidden ; 
overflow-y:hidden ; 
background-color:transparent ; 

	.row.rowitem09{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:17px ; 
	
	top: 0px ; 
	bottom: 0px ; 
	width:50%!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;background-color:transparent ; 

		.item.itemrowitem091{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			bottom: 0px ; 
			background-color:#ffffff ; 
			border-radius:8px ; max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: inherit;

				flex: unset;
				border-radius:8px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

				
}

			
}

		
}

	
}

	.row.rowitem092{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	width:50%!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	flex-direction: row-reverse ; background-color:transparent ; 

		.item.itemrowitem0911{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			bottom: 0px ; 
			background-color:#ffffff ; 
			border-radius:8px ; max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: inherit;

				flex: unset;
				border-radius:8px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

				
}

			
}

		
}

	
}

	.card.carditemitem0105{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	top: 0px ; 
	bottom: 0px ; 
	background-color:#ffffff ; 
	border-radius:8px ; max-width: initial;min-width: initial;

		.networkimage.imagecarditemitem01051{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: inherit;

		flex: unset;
		border-radius:8px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.item.itemitem01{


position:absolute !important; 






	.row.rowitemitem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowitemitem0111{

		
		position:relative !important; 
		
		
		
		
		
		

			.mousearea.mousearearectanglerowitemitem01111{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.item.itemitem03{


position:absolute !important; 






	.icon.iconitemitem031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.item.itemitem033{


position:absolute !important; 






	.icon.iconitemitem031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.item.itemitem07{


position:absolute !important; 







}

.scrollarea.itemitem010{


position:absolute !important; 






	.row.rowitem09{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemrowitem091{

		
		position:relative !important; 
		
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.row.rowitem092{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemrowitem0911{

		
		position:relative !important; 
		
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.card.carditemitem0105{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.imagecarditemitem01051{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.item.itemitem01{


position:absolute !important; 






	.row.rowitemitem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowitemitem0111{

		
		position:relative !important; 
		
		
		
		
		
		

			.mousearea.mousearearectanglerowitemitem01111{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.item.itemitem03{


position:absolute !important; 






	.icon.iconitemitem031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.item.itemitem033{


position:absolute !important; 






	.icon.iconitemitem031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.item.itemitem07{


position:absolute !important; 







}

.scrollarea.itemitem010{


position:absolute !important; 






	.row.rowitem09{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemrowitem091{

		
		position:relative !important; 
		
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.row.rowitem092{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemrowitem0911{

		
		position:relative !important; 
		
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.card.carditemitem0105{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.imagecarditemitem01051{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.item.itemitem01{


position:absolute !important; 






	.row.rowitemitem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowitemitem0111{

		
		position:relative !important; 
		
		
		
		
		
		

			.mousearea.mousearearectanglerowitemitem01111{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.item.itemitem03{


position:absolute !important; 






	.icon.iconitemitem031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.item.itemitem033{


position:absolute !important; 






	.icon.iconitemitem031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.item.itemitem07{


position:absolute !important; 







}

.scrollarea.itemitem010{


position:absolute !important; 






	.row.rowitem09{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemrowitem091{

		
		position:relative !important; 
		
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.row.rowitem092{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemrowitem0911{

		
		position:relative !important; 
		
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.card.carditemitem0105{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.imagecarditemitem01051{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.item.itemitem01{


position:absolute !important; 






	.row.rowitemitem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowitemitem0111{

		
		position:relative !important; 
		
		
		
		
		
		

			.mousearea.mousearearectanglerowitemitem01111{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.item.itemitem03{


position:absolute !important; 






	.icon.iconitemitem031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.item.itemitem033{


position:absolute !important; 






	.icon.iconitemitem031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.item.itemitem07{


position:absolute !important; 







}

.scrollarea.itemitem010{


position:absolute !important; 






	.row.rowitem09{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemrowitem091{

		
		position:relative !important; 
		
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.row.rowitem092{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemrowitem0911{

		
		position:relative !important; 
		
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.card.carditemitem0105{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.imagecarditemitem01051{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.item.itemitem01{


position:absolute !important; 






	.row.rowitemitem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.rectangle.rectanglerowitemitem0111{

		
		position:relative !important; 
		
		
		
		
		
		

			.mousearea.mousearearectanglerowitemitem01111{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.item.itemitem03{


position:absolute !important; 






	.icon.iconitemitem031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.item.itemitem033{


position:absolute !important; 






	.icon.iconitemitem031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.mousearea.mouseareaitemitem033{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.item.itemitem07{


position:absolute !important; 







}

.scrollarea.itemitem010{


position:absolute !important; 






	.row.rowitem09{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemrowitem091{

		
		position:relative !important; 
		
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.row.rowitem092{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemrowitem0911{

		
		position:relative !important; 
		
		
		
		
		

			.card.carditemitem01051{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecarditemitem01051{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}

	.card.carditemitem0105{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.imagecarditemitem01051{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}


}


}
}
}
</style>
