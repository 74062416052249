<template>
 <div>  
<div id="HomeBrand"> 
         
 <div  :class="'column columncolumnscrollarea0272 '"   id="columncolumnscrollarea0272" ref="columncolumnscrollarea0272"  v-bind:style="{}">
 <v-img  :class="'image imagecolumncolumnscrollarea0271 '"   id="imagecolumncolumnscrollarea0271" ref="imagecolumncolumnscrollarea0271"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../../Resources/abcCode_Logo_N_G_transparent.png')" 
>  </v-img>
 <div  :class="'row rowcolumncolumnscrollarea0273 '"   id="rowcolumncolumnscrollarea0273" ref="rowcolumncolumnscrollarea0273"  v-bind:style="{}">
 <div  :class="'text textrowcolumncolumnscrollarea02731 '"   id="textrowcolumncolumnscrollarea02731" ref="textrowcolumncolumnscrollarea02731"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'La comunaté' }}</div>

   </div>
 <div  :class="'item itemrowcolumncolumnscrollarea02733 '"   id="itemrowcolumncolumnscrollarea02733" ref="itemrowcolumncolumnscrollarea02733"  v-bind:style="{}">  </div>
 <div  :class="'rectangle rectangle013 '"   id="rectangle013" ref="rectangle013"  v-bind:style="{backgroundColor : '#46505d',}">
 <div  :class="'text textrectangle01 '"   id="textrectangle01" ref="textrectangle01"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ 'abcCode' }}</div>

   </div>
 <div  :class="'rectangle rectanglerectangle03 '"   id="rectanglerectangle03" ref="rectanglerectangle03"  v-bind:style="{}">  </div>
 <div  :class="'rectangle rectanglerectangle033 '"   id="rectanglerectangle033" ref="rectanglerectangle033"  v-bind:style="{}">  </div>
 <div  :class="'rectangle rectanglerectangle034 '"   id="rectanglerectangle034" ref="rectanglerectangle034"  v-bind:style="{}">  </div>
 <div  :class="'rectangle rectanglerectangle0335 '"   id="rectanglerectangle0335" ref="rectanglerectangle0335"  v-bind:style="{}">  </div>  </div>  </div>
 <div  :class="'flow flowcolumncolumnscrollarea0275 ' + 'tagsFlow'"   id="flowcolumncolumnscrollarea0275" ref="flowcolumncolumnscrollarea0275"  v-bind:style="{}">
 <div  :class="'text textflowcolumncolumnscrollarea02751 '"   id="textflowcolumncolumnscrollarea02751" ref="textflowcolumncolumnscrollarea02751"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'Réaliser des ' }}</div>

   </div>
 <div  :class="'column columnflowcolumncolumnscrollarea02753 ' + 'home-tag'"   id="columnflowcolumncolumnscrollarea02753" ref="columnflowcolumncolumnscrollarea02753"  v-bind:style="{}">
 <div  :class="'text textflowcolumncolumnscrollarea027511 '"   id="textflowcolumncolumnscrollarea027511" ref="textflowcolumncolumnscrollarea027511"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'jeux,' }}</div>

   </div>
 <div  :class="'rectangle rectanglecolumnflowcolumncolumnscrollarea027533 '"   id="rectanglecolumnflowcolumncolumnscrollarea027533" ref="rectanglecolumnflowcolumncolumnscrollarea027533"  v-bind:style="{backgroundColor : '#25baff',}">  </div>  </div>
 <div  :class="'column columnflowcolumncolumnscrollarea027533 ' + 'home-tag'"   id="columnflowcolumncolumnscrollarea027533" ref="columnflowcolumncolumnscrollarea027533"  v-bind:style="{}">
 <div  :class="'text textflowcolumncolumnscrollarea027511 '"   id="textflowcolumncolumnscrollarea027511" ref="textflowcolumncolumnscrollarea027511"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'animations,' }}</div>

   </div>
 <div  :class="'rectangle rectanglecolumnflowcolumncolumnscrollarea027533 '"   id="rectanglecolumnflowcolumncolumnscrollarea027533" ref="rectanglecolumnflowcolumncolumnscrollarea027533"  v-bind:style="{backgroundColor : '#9d26ff',}">  </div>  </div>
 <div  :class="'column columnflowcolumncolumnscrollarea027534 ' + 'home-tag'"   id="columnflowcolumncolumnscrollarea027534" ref="columnflowcolumncolumnscrollarea027534"  v-bind:style="{}">
 <div  :class="'text textflowcolumncolumnscrollarea027511 '"   id="textflowcolumncolumnscrollarea027511" ref="textflowcolumncolumnscrollarea027511"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'applications,' }}</div>

   </div>
 <div  :class="'rectangle rectanglecolumnflowcolumncolumnscrollarea027533 '"   id="rectanglecolumnflowcolumncolumnscrollarea027533" ref="rectanglecolumnflowcolumncolumnscrollarea027533"  v-bind:style="{backgroundColor : '#23ff8a',}">  </div>  </div>
 <div  :class="'text textflowcolumncolumnscrollarea027515 '"   id="textflowcolumncolumnscrollarea027515" ref="textflowcolumncolumnscrollarea027515"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'et apprenez à ' }}</div>

   </div>
 <div  :class="'column columnflowcolumncolumnscrollarea0275346 ' + 'home-tag'"   id="columnflowcolumncolumnscrollarea0275346" ref="columnflowcolumncolumnscrollarea0275346"  v-bind:style="{}">
 <div  :class="'text textflowcolumncolumnscrollarea027511 '"   id="textflowcolumncolumnscrollarea027511" ref="textflowcolumncolumnscrollarea027511"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'coder.' }}</div>

   </div>
 <div  :class="'rectangle rectanglecolumnflowcolumncolumnscrollarea027533 '"   id="rectanglecolumnflowcolumncolumnscrollarea027533" ref="rectanglecolumnflowcolumncolumnscrollarea027533"  v-bind:style="{backgroundColor : '#ff9717',}">  </div>  </div>  </div>
 <div  :class="'row rowcolumncolumnscrollarea02727 '"   id="rowcolumncolumnscrollarea02727" ref="rowcolumncolumnscrollarea02727" v-if="loginSininVisible"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#ff6b00'" 
 :rounded = "true" 
 :dark = "true" 
 @click="showLogin" :class="'button buttonrowitem0511 '"   id="buttonrowitem0511" ref="buttonrowitem0511"  v-bind:style="{}"><span>{{ 'Se connecter' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#ff6b00'" 
 :rounded = "true" 
 :dark = "true" 
 @click="showSignup" :class="'button buttonrowitem0512 '"   id="buttonrowitem0512" ref="buttonrowitem0512"  v-bind:style="{}"><span>{{ 'S\' enregistre' }}</span>
</v-btn>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'HomeBrand',

    components: {},
	props: {
	
		showSignup: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showLogin: {			type: Function,			required: false,			default: () =>  (()=>{})	,		},
		loginSininVisible: {					required: false,			default: () =>  true,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#HomeBrand{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.tagsFlow{	
    justify-content: center;
}
.button{
	padding: 10px !important;
}



.column.columncolumnscrollarea0272{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:5px ; background-color: transparent ; 

.image.imagecolumncolumnscrollarea0271{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

width:80px!important ; 
height:80px!important ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.row.rowcolumncolumnscrollarea0273{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

width:auto!important ; 
height:50px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;background-color:transparent ; 

	.text.textrowcolumncolumnscrollarea02731{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	color:#414a51 ; 
	font-size:32px ; 
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.item.itemrowcolumncolumnscrollarea02733{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	width:10px!important ; 
	height:1px!important ; 
	

	
}

	.rectangle.rectangle013{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:100px!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	background-color:#46505d ; 
	border-radius:4px ; 

		.text.textrectangle01{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		color:#fff ; 
		font-size:20px ; 
		text-align:center;
		align-items:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.rectangle.rectanglerectangle03{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:2px ; 
		
		top: 2px ; 
		width:4px!important ; 
		height:4px!important ; 
		
		background-color:#ffffff ; 
		border-radius:2px ; 

		
}

		.rectangle.rectanglerectangle033{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:2px ; 
		
		bottom: 2px ; 
		width:4px!important ; 
		height:4px!important ; 
		
		background-color:#ffffff ; 
		border-radius:2px ; 

		
}

		.rectangle.rectanglerectangle034{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: 2px ; 
		right: 2px ; 
		width:4px!important ; 
		height:4px!important ; 
		
		background-color:#ffffff ; 
		border-radius:2px ; 

		
}

		.rectangle.rectanglerectangle0335{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		right: 2px ; 
		bottom: 2px ; 
		width:4px!important ; 
		height:4px!important ; 
		
		background-color:#ffffff ; 
		border-radius:2px ; 

		
}

	
}


}

.flow.flowcolumncolumnscrollarea0275{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 20px ; 

margin-right: 20px ; 
height:auto!important ; 
width: calc(100% - (20px + 20px)) !important;

display: flex;
flex-wrap: wrap;
flex: initial;
gap:8px ; background-color:transparent ; 

	.text.textflowcolumncolumnscrollarea02751{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	margin-top: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	color:#414a51 ; 
	font-family:Comic Sans MS ; 
	font-size:32px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.column.columnflowcolumncolumnscrollarea02753{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	margin-top: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:3px ; background-color: transparent ; 

		.text.textflowcolumncolumnscrollarea027511{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		width:auto!important ; 
		height:auto!important ; 
		
		color:#414a51 ; 
		font-family:Comic Sans MS ; 
		font-size:32px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:8px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		background-color:#25baff ; 
		border-radius:4px ; 

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027533{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	margin-top: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.text.textflowcolumncolumnscrollarea027511{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		width:auto!important ; 
		height:auto!important ; 
		
		color:#414a51 ; 
		font-family:Comic Sans MS ; 
		font-size:32px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:8px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		background-color:#9d26ff ; 
		border-radius:4px ; 

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027534{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	margin-top: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.text.textflowcolumncolumnscrollarea027511{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		width:auto!important ; 
		height:auto!important ; 
		
		color:#414a51 ; 
		font-family:Comic Sans MS ; 
		font-size:32px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:8px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		background-color:#23ff8a ; 
		border-radius:4px ; 

		
}

	
}

	.text.textflowcolumncolumnscrollarea027515{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	margin-top: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	color:#414a51 ; 
	font-family:Comic Sans MS ; 
	font-size:32px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.column.columnflowcolumncolumnscrollarea0275346{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	margin-top: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.text.textflowcolumncolumnscrollarea027511{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		width:auto!important ; 
		height:auto!important ; 
		
		color:#414a51 ; 
		font-family:Comic Sans MS ; 
		font-size:32px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:8px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		background-color:#ff9717 ; 
		border-radius:4px ; 

		
}

	
}


}

.row.rowcolumncolumnscrollarea02727{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

width:auto!important ; 
height:40px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:20px ; background-color:transparent ; 

	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:32px!important ; 
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}

	.button.buttonrowitem0512{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:32px!important ; 
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}


}


}


@media (max-width: 600px) {


.column.columncolumnscrollarea0272{


position:relative !important; 









.image.imagecolumncolumnscrollarea0271{


position:relative !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.row.rowcolumncolumnscrollarea0273{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumncolumnscrollarea02731{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.item.itemrowcolumncolumnscrollarea02733{

	
	position:relative !important; 
	
	
	
	
	

	
}

	.rectangle.rectangle013{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectangle01{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglerectangle03{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle033{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle034{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle0335{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

	
}


}

.flow.flowcolumncolumnscrollarea0275{


position:relative !important; 









	.text.textflowcolumncolumnscrollarea02751{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columnflowcolumncolumnscrollarea02753{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027533{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027534{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.text.textflowcolumncolumnscrollarea027515{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columnflowcolumncolumnscrollarea0275346{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}


}

.row.rowcolumncolumnscrollarea02727{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowitem0512{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 601px) {


.column.columncolumnscrollarea0272{


position:relative !important; 









.image.imagecolumncolumnscrollarea0271{


position:relative !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.row.rowcolumncolumnscrollarea0273{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumncolumnscrollarea02731{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.item.itemrowcolumncolumnscrollarea02733{

	
	position:relative !important; 
	
	
	
	
	

	
}

	.rectangle.rectangle013{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectangle01{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglerectangle03{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle033{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle034{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle0335{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

	
}


}

.flow.flowcolumncolumnscrollarea0275{


position:relative !important; 









	.text.textflowcolumncolumnscrollarea02751{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columnflowcolumncolumnscrollarea02753{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027533{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027534{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.text.textflowcolumncolumnscrollarea027515{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columnflowcolumncolumnscrollarea0275346{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}


}

.row.rowcolumncolumnscrollarea02727{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowitem0512{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 961px) {


.column.columncolumnscrollarea0272{


position:relative !important; 









.image.imagecolumncolumnscrollarea0271{


position:relative !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.row.rowcolumncolumnscrollarea0273{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumncolumnscrollarea02731{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.item.itemrowcolumncolumnscrollarea02733{

	
	position:relative !important; 
	
	
	
	
	

	
}

	.rectangle.rectangle013{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectangle01{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglerectangle03{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle033{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle034{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle0335{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

	
}


}

.flow.flowcolumncolumnscrollarea0275{


position:relative !important; 



margin-left: 100px ; 

margin-right: 100px ; 
width: calc(100% - (100px + 100px)) !important;





	.text.textflowcolumncolumnscrollarea02751{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columnflowcolumncolumnscrollarea02753{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027533{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027534{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.text.textflowcolumncolumnscrollarea027515{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columnflowcolumncolumnscrollarea0275346{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}


}

.row.rowcolumncolumnscrollarea02727{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowitem0512{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1265px) {


.column.columncolumnscrollarea0272{


position:relative !important; 









.image.imagecolumncolumnscrollarea0271{


position:relative !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.row.rowcolumncolumnscrollarea0273{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumncolumnscrollarea02731{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.item.itemrowcolumncolumnscrollarea02733{

	
	position:relative !important; 
	
	
	
	
	

	
}

	.rectangle.rectangle013{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectangle01{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglerectangle03{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle033{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle034{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle0335{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

	
}


}

.flow.flowcolumncolumnscrollarea0275{


position:relative !important; 



margin-left: 150px ; 

margin-right: 150px ; 
width: calc(100% - (150px + 150px)) !important;





	.text.textflowcolumncolumnscrollarea02751{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columnflowcolumncolumnscrollarea02753{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027533{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027534{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.text.textflowcolumncolumnscrollarea027515{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columnflowcolumncolumnscrollarea0275346{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}


}

.row.rowcolumncolumnscrollarea02727{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowitem0512{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1905px) {


.column.columncolumnscrollarea0272{


position:relative !important; 









.image.imagecolumncolumnscrollarea0271{


position:relative !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.row.rowcolumncolumnscrollarea0273{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumncolumnscrollarea02731{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.item.itemrowcolumncolumnscrollarea02733{

	
	position:relative !important; 
	
	
	
	
	

	
}

	.rectangle.rectangle013{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectangle01{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglerectangle03{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle033{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle034{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.rectanglerectangle0335{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

	
}


}

.flow.flowcolumncolumnscrollarea0275{


position:relative !important; 









	.text.textflowcolumncolumnscrollarea02751{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columnflowcolumncolumnscrollarea02753{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027533{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.column.columnflowcolumncolumnscrollarea027534{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}

	.text.textflowcolumncolumnscrollarea027515{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.column.columnflowcolumncolumnscrollarea0275346{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textflowcolumncolumnscrollarea027511{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectanglecolumnflowcolumncolumnscrollarea027533{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

	
}


}

.row.rowcolumncolumnscrollarea02727{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowitem0512{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}
}
</style>
