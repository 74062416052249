<template>
 <div>  
<div id="SignUp"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectangleitem011 '"   id="mousearearectangleitem011" ref="mousearearectangleitem011"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'rectangle rectangleitem03 ' + 'noOverflow'"   id="rectangleitem03" ref="rectangleitem03"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerectangleitem031 '"   id="rectanglerectangleitem031" ref="rectanglerectangleitem031"  v-bind:style="{backgroundColor : '#e9ebec',}">
 <v-img  :class="'image imagerectanglerectangleitem0311 '"   id="imagerectanglerectangleitem0311" ref="imagerectanglerectangleitem0311"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../../Resources/background/confetis.png')" 
>  </v-img>
 <div  :class="'row rowrectanglerectangleitem0314 '"   id="rowrectanglerectangleitem0314" ref="rowrectanglerectangleitem0314"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#8974ff'" 
 :onlyIcon = "true" 
 :dark = "true" 
 @click="previous" :class="'button buttonrowrectanglerectangleitem03145 '"   id="buttonrowrectanglerectangleitem03145" ref="buttonrowrectanglerectangleitem03145" v-if="buttonVisible"  v-bind:style="{}"><span v-if = "(!false) || (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-arrow-dropleft' !== undefined"  :color = "'#fff'" 
>{{ 'ion-md-arrow-dropleft' }}
</v-icon></v-btn>
 <Logo :class="'object logorectanglerectangleitem0313 '"   id="logorectanglerectangleitem0313" ref="logorectanglerectangleitem0313"  v-bind:style="{}">
</Logo>
 <div  :class="'text textrowrectanglerectangleitem03143 '"   id="textrowrectanglerectangleitem03143" ref="textrowrectanglerectangleitem03143"  v-bind:style="{}">
<div class="text-content">{{ 'S\' enregistrer' }}</div>

   </div>  </div>  </div>
 <div  :class="'item itemrectangleitem033 '"   id="itemrectangleitem033" ref="itemrectangleitem033"  v-bind:style="{}">
 <div  :class="'item mainContent '"   id="mainContent" ref="mainContent"  v-bind:style="{}">
 <div  :class="'column step1 '"   id="step1" ref="step1" v-show="currentStep == 0"  v-bind:style="{}">
 <div  :class="'text footerLeft1 '"   id="footerLeft1" ref="footerLeft1"  v-bind:style="{color : '#ff6900',}">
<div class="text-content">{{ 'Commencons!' }}</div>

   </div>
 <div  :class="'text footerLeft12 '"   id="footerLeft12" ref="footerLeft12"  v-bind:style="{color : '#9fa0a7',}">
<div class="text-content">{{ 'Choisis un nom d\' utilisateur.' }}</div>

   </div>
 <div  :class="'rectangle rectanglestep15 '"   id="rectanglestep15" ref="rectanglestep15"  v-bind:style="{}">
 <v-text-field  :placeholder = "'Nom d\' utilisateur'" 
 solo :class="'textfield textfieldrectanglestep151 '"   id="textfieldrectanglestep151" ref="textfieldrectanglestep151" v-model="name" v-on:keyupenter="validateStep1"   v-bind:style="{}"></v-text-field>  </div>
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :rounded = "true" 
 :dark = "true" 
 @click="validateStep1" :class="'button buttonstep17 '"   id="buttonstep17" ref="buttonstep17"  v-bind:style="{}"><span>{{ 'Continuer' }}</span>
</v-btn>  </div>
 <div  :class="'column step2 '"   id="step2" ref="step2" v-show="currentStep == 1"  v-bind:style="{}">
 <div  :class="'text footerLeft1 '"   id="footerLeft1" ref="footerLeft1"  v-bind:style="{color : '#ff6900',}">
<div class="text-content">{{ 'Bienvenue dans notre communauté' }}</div>

   </div>
 <div  :class="'text footerLeft12 '"   id="footerLeft12" ref="footerLeft12"  v-bind:style="{color : '#8974ff',}">
<div class="text-content">{{ name }}</div>

   </div>
 <div  :class="'text footerLeft125 '"   id="footerLeft125" ref="footerLeft125"  v-bind:style="{color : '#66676b',}">
<div class="text-content">{{ 'Choisis un mot de passe pour ton compte. Ton mote de passe doit avoir au moins 8 charactères.' }}</div>

   </div>
 <div  :class="'rectangle rectanglestep15 '"   id="rectanglestep15" ref="rectanglestep15"  v-bind:style="{}">
 <div  :class="'column columnrectanglestep152 '"   id="columnrectanglestep152" ref="columnrectanglestep152"  v-bind:style="{justifyContent : 'space-between',}">
 <v-text-field  :placeholder = "'Mot de passe'" 
 :append-icon = "passIcon" 
 :type = "passType" 
 solo :class="'textfield textfieldrectanglestep151 '"   id="textfieldrectanglestep151" ref="textfieldrectanglestep151" v-model="pass" v-on:keyupenter="validateStep2"   v-bind:style="{}" @click:append="showPass"></v-text-field>
 <v-text-field  :placeholder = "'Verification du mot de passe'" 
 :append-icon = "passIcon" 
 :type = "passType" 
 solo :class="'textfield textfieldrectanglestep1512 '"   id="textfieldrectanglestep1512" ref="textfieldrectanglestep1512" v-model="checkPass" v-on:keyupenter="validateStep2"   v-bind:style="{}" @click:append="showPass"></v-text-field>  </div>  </div>
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :rounded = "true" 
 :dark = "true" 
 @click="validateStep2" :class="'button buttonstep17 '"   id="buttonstep17" ref="buttonstep17"  v-bind:style="{}"><span>{{ 'Continuer' }}</span>
</v-btn>  </div>
 <div  :class="'column step3 '"   id="step3" ref="step3" v-show="currentStep == 2"  v-bind:style="{}">
 <div  :class="'text footerLeft1 '"   id="footerLeft1" ref="footerLeft1"  v-bind:style="{color : '#ff6900',}">
<div class="text-content">{{ 'Bienvenue dans notre communauté' }}</div>

   </div>
 <div  :class="'text footerLeft12 '"   id="footerLeft12" ref="footerLeft12"  v-bind:style="{color : '#8974ff',}">
<div class="text-content">{{ name }}</div>

   </div>
 <div  :class="'text footerLeft125 '"   id="footerLeft125" ref="footerLeft125"  v-bind:style="{color : '#66676b',}">
<div class="text-content">{{ 'Tout compte abcCode nécessite une adresse mail valide pour pouvoir partager et visiter des creations.' }}</div>

   </div>
 <div  :class="'rectangle rectanglestep15 '"   id="rectanglestep15" ref="rectanglestep15"  v-bind:style="{}">
 <v-text-field  :placeholder = "'name@email.com'" 
 solo :class="'textfield textfieldrectanglestep151 '"   id="textfieldrectanglestep151" ref="textfieldrectanglestep151" v-model="email" v-on:keyupenter="validateStep3"   v-bind:style="{}"></v-text-field>  </div>
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :rounded = "true" 
 :dark = "true" 
 @click="validateStep3" :class="'button buttonstep17 '"   id="buttonstep17" ref="buttonstep17"  v-bind:style="{}"><span>{{ 'Terminer' }}</span>
</v-btn>  </div>  </div>
 <div  :class="'column columnitemrectangleitem0334 '"   id="columnitemrectangleitem0334" ref="columnitemrectangleitem0334"  v-bind:style="{}">
 <div  :class="'text errorText '"   id="errorText" ref="errorText" v-if="errorVisible"  v-bind:style="{color : '#ff4608',}">
<div class="text-content">{{ error }}</div>

   </div>
 <div  :class="'row footer ' + 'rowWrap'"   id="footer" ref="footer"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text footerLeft '"   id="footerLeft" ref="footerLeft"  v-bind:style="{color : '#9fa0a7',}">
<div class="text-content">{{ 'Privacy Policy' }}</div>

   </div>
 <div  :class="'row footerRight ' + 'rowWrap'"   id="footerRight" ref="footerRight"  v-bind:style="{}">
 <div  :class="'text textcolgriditemrectangleitem0331111 '"   id="textcolgriditemrectangleitem0331111" ref="textcolgriditemrectangleitem0331111"  v-bind:style="{color : '#9fa0a7',}">
<div class="text-content">{{ 'Avez vous dejà un compte?' }}</div>

   </div>
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :text = "true" 
 @click="switchLogIn" :class="'button buttonrowcolgriditemrectangleitem03313123 '"   id="buttonrowcolgriditemrectangleitem03313123" ref="buttonrowcolgriditemrectangleitem03313123"  v-bind:style="{}"><span>{{ 'Se connecter' }}</span>
</v-btn>  </div>  </div>  </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import Logo from '../../../At/Components/Logo'

import axios from 'axios';
export default {
	name: 'SignUp',

    components: {Logo,},
	methods: {
	
		showPass: function() { 		
	if(this.passVisible){

		this.passType = 'text'

		this.passIcon = 'ion-ios-eye-off'

		this.passVisible = false

	} else {

		this.passType = 'password'

		this.passIcon = 'ion-ios-eye'

		this.passVisible = true

	}

}
,
		showError: function(message) { 		
	this.error = message

	this.errorVisible = true

}
,
		hideError: function() { 		
	this.error = ""

	this.errorVisible = false

}
,
		previous: function() { 		
	this.currentStep--

}
,
		validateStep1: function() { 		
		if(this.name != null){

				this.hideError()

			this.currentStep++

		} else {

			this.showError("Il faut entrer un nom.")

		}

}
,
		validateStep2: function() { 		
		if(this.pass != null){

			if(this.checkPass != null){

				if(this.pass === this.checkPass){

					this.hideError()

					this.currentStep++

				} else {

					this.showError("Le mot de passe et sa verification ne correspondent pas.")

				}

			} else {

				this.showError("Il faut entrer la verification.")

			}

		} else {

			this.showError("Il faut entrer un mot de passe.")

		}

}
,
		validateStep3: function() { 		
		if(this.email != null){

			//TODO : We need to verify if the email is alrady used

				this.hideError()

			this.signUp()

		} else {

			this.showError("Il faut entrer un email.")

		}

}
,
		signUp: function() { 		
	axios

  .post(this.$store.state.apiURL + '/auth/local/register', {

    username: this.name,

    email: this.email,

    password: this.pass,

  })

  .then(response => {

    // Handle success.

		this.close()

  })

  .catch(error => {

    // Handle error.

    this.showError(error.response.data

												.message[0]

												.messages[0]

												.message);

  });

}
,
		switchLogIn: function() { 		
	this.showLogIn()

	this.close()

}
,
	},
	computed: {
	
		buttonVisible: function() { 		
	return (this.currentStep>0 && this.currentStep<2)

}
,
	},
	data(){
	return {
		
		currentStep:  0		,
		name:  null		,
		email:  null		,
		pass:  null		,
		checkPass:  null		,
		error:  ""		,
		errorVisible:  false		,
		passVisible:  false		,
		passIcon:  'ion-ios-eye'		,
		passType:  "password"		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showLogIn: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SignUp{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.noOverflow{
		overflow: hidden;
}

.rowWrap{
    flex-wrap: wrap !important;
}
.button{
	padding: 10px !important;
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.4 ;  filter: alpha(opacity=40) ; 

background-color:#000 ; 

	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
width:320px!important ; 
height:625px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 

	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	height:56px!important ; 
	
	background-color:#e9ebec ; 

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		width:254px!important ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:45px ; 
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:40px!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:8px ; background-color:transparent ; 

			.button.buttonrowrectanglerectangleitem03145{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:30px!important ; 
			height:30px!important ; 
			
			border-radius:15px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:40px!important ; 
			height:40px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:auto!important ; 
			
			font-family:Comic Sans MS ; 
			font-size:20px ; 
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 20px ; 
	
	top: 70px ; 
	right: 20px ; 
	bottom: 20px ; 
	

		.item.mainContent{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 35px ; 
		

			.column.step1{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:15px ; background-color: transparent ; 

				.text.footerLeft1{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#ff6900 ; 
				font-family:Comic Sans MS ; 
				font-size:17px ; 
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#9fa0a7 ; 
				font-family:Comic Sans MS ; 
				font-size:17px ; 
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:100px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				background-color:#ffffff ; 
				border-radius:8px ; 
				border-style:solid ; 
				border-width:1px ; 
				border-color:#d5d5d5 ; 

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
					
					left: 20px ; 
					
					top: calc(50% + 0px) ; 
					right: 20px ; 
					height:57.83333333325572px!important ; 
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:100px!important ; 
				height:40px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step2{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:15px ; background-color: transparent ; 

				.text.footerLeft1{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#ff6900 ; 
				font-family:Comic Sans MS ; 
				font-size:17px ; 
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#8974ff ; 
				font-family:Comic Sans MS ; 
				font-size:40px ; 
				text-overflow: ellipsis ; 
				.text-content{
				width: 100%;
				white-space: nowrap;
				overflow: hidden ;
				text-overflow: ellipsis ; 
				font-weight: bold;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#66676b ; 
				font-family:Comic Sans MS ; 
				font-size:17px ; 
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:170px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				background-color:#ffffff ; 
				border-radius:8px ; 
				border-style:solid ; 
				border-width:1px ; 
				border-color:#d5d5d5 ; 

					.column.columnrectanglestep152{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 20px ; 
					
					top: 20px ; 
					right: 20px ; 
					bottom: 20px ; 
					
					display: flex;
					flex-wrap: nowrap;
					flex: initial;
					flex-direction: column;
					justify-content:space-between ; background-color: transparent ; 

						.textfield.textfieldrectanglestep151{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:57.83333333325572px!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldrectanglestep1512{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-right: 0px ; 
						height:57.83333333325572px!important ; 
						width: calc(100% - (0px + 0px)) !important;
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

					
}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:100px!important ; 
				height:40px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step3{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:15px ; background-color: transparent ; 

				.text.footerLeft1{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#ff6900 ; 
				font-family:Comic Sans MS ; 
				font-size:17px ; 
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#8974ff ; 
				font-family:Comic Sans MS ; 
				font-size:40px ; 
				text-overflow: ellipsis ; 
				.text-content{
				width: 100%;
				white-space: nowrap;
				overflow: hidden ;
				text-overflow: ellipsis ; 
				font-weight: bold;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#66676b ; 
				font-family:Comic Sans MS ; 
				font-size:17px ; 
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:100px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				background-color:#ffffff ; 
				border-radius:8px ; 
				border-style:solid ; 
				border-width:1px ; 
				border-color:#d5d5d5 ; 

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
					
					left: 20px ; 
					
					top: calc(50% + 0px) ; 
					right: 20px ; 
					height:57.83333333325572px!important ; 
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:100px!important ; 
				height:40px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.column.columnitemrectangleitem0334{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		right: 0px ; 
		bottom: 0px ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:10px ; background-color: transparent ; 

			.text.errorText{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#ff4608 ; 
			.text-content{
			width: 100%;
			}

			
}

			.row.footer{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			justify-content:space-between ; background-color:transparent ; 

				.text.footerLeft{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#9fa0a7 ; 
				font-family:Comic Sans MS ; 
				font-size:15px ; 
				.text-content{
				width: 100%;
				}

				
}

				.row.footerRight{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-right: 0px ; 
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;
				gap:5px ; background-color:transparent ; 

					.text.textcolgriditemrectangleitem0331111{

					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					margin-top: 0px ; 
					width:auto!important ; 
					height:auto!important ; 
					
					color:#9fa0a7 ; 
					font-family:Comic Sans MS ; 
					font-size:13px ; 
					.text-content{
					width: 100%;
					}

					
}

					.button.buttonrowcolgriditemrectangleitem03313123{

					height: auto;
					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					margin-top: 0px ; 
					width:auto!important ; 
					height:auto!important ; 
					
					font-size:13px ; 
					font-family:"Comic Sans MS" ; 
					text-transform:unset ; 
					min-width: auto;
					padding-left: 16px;
					padding-right: 16px;

					i.v-icon{
					font-size:13px ; 
					}
					}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 







	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowrectanglerectangleitem03145{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	
	
	
	
	

		.item.mainContent{

		
		position:absolute !important; 
		
		
		
		
		

			.column.step1{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step2{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.column.columnrectanglestep152{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.textfield.textfieldrectanglestep151{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldrectanglestep1512{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

					
}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.column.columnitemrectangleitem0334{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.errorText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.footer{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.footerLeft{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.footerRight{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textcolgriditemrectangleitem0331111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.button.buttonrowcolgriditemrectangleitem03313123{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 




width:480px!important ; 
height:510px!important ; 



	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowrectanglerectangleitem03145{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	
	
	
	
	

		.item.mainContent{

		
		position:absolute !important; 
		
		
		
		
		

			.column.step1{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step2{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.column.columnrectanglestep152{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.textfield.textfieldrectanglestep151{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldrectanglestep1512{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

					
}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.column.columnitemrectangleitem0334{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.errorText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.footer{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.footerLeft{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.footerRight{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textcolgriditemrectangleitem0331111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.button.buttonrowcolgriditemrectangleitem03313123{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 







	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowrectanglerectangleitem03145{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	
	
	
	
	

		.item.mainContent{

		
		position:absolute !important; 
		
		
		
		
		

			.column.step1{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step2{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.column.columnrectanglestep152{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.textfield.textfieldrectanglestep151{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldrectanglestep1512{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

					
}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.column.columnitemrectangleitem0334{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.errorText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.footer{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.footerLeft{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.footerRight{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textcolgriditemrectangleitem0331111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.button.buttonrowcolgriditemrectangleitem03313123{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 







	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowrectanglerectangleitem03145{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	
	
	
	
	

		.item.mainContent{

		
		position:absolute !important; 
		
		
		
		
		

			.column.step1{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step2{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.column.columnrectanglestep152{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.textfield.textfieldrectanglestep151{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldrectanglestep1512{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

					
}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.column.columnitemrectangleitem0334{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.errorText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.footer{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.footerLeft{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.footerRight{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textcolgriditemrectangleitem0331111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.button.buttonrowcolgriditemrectangleitem03313123{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.rectangleitem03{


position:absolute !important; 







	.rectangle.rectanglerectangleitem031{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglerectangleitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.row.rowrectanglerectangleitem0314{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowrectanglerectangleitem03145{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.object.logorectanglerectangleitem0313{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.text.textrowrectanglerectangleitem03143{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrectangleitem033{

	
	position:absolute !important; 
	
	
	
	
	

		.item.mainContent{

		
		position:absolute !important; 
		
		
		
		
		

			.column.step1{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step2{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.column.columnrectanglestep152{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.textfield.textfieldrectanglestep151{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

						.textfield.textfieldrectanglestep1512{

						
						position:relative !important; 
						
						
						
						
						
						::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
						
::v-deep &.v-input{
							flex-grow: 0;
input{

}
						}
						}

					
}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.step3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.footerLeft1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft12{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.footerLeft125{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.rectangle.rectanglestep15{

				
				position:relative !important; 
				
				
				
				
				
				

					.textfield.textfieldrectanglestep151{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.button.buttonstep17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.column.columnitemrectangleitem0334{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.errorText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.footer{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.footerLeft{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.footerRight{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textcolgriditemrectangleitem0331111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.button.buttonrowcolgriditemrectangleitem03313123{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
