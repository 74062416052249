<template>
 <div>  
<div id="Banner"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : cBgColor,}">
 <div  :class="'item itemrectangleitem011 '"   id="itemrectangleitem011" ref="itemrectangleitem011"  v-bind:style="{}">
 <div  :class="'column columnitemrectangleitem0113 '"   id="columnitemrectangleitem0113" ref="columnitemrectangleitem0113"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textcolumnitemrectangleitem01131 '"   id="textcolumnitemrectangleitem01131" ref="textcolumnitemrectangleitem01131"  v-bind:style="{color : textColor,}">
<div class="text-content">{{ title }}</div>

   </div>
 <div  :class="'text textcolumnitemrectangleitem011314 '"   id="textcolumnitemrectangleitem011314" ref="textcolumnitemrectangleitem011314"  v-bind:style="{color : textColor,}">
<div class="text-content">{{ cTtitle }}</div>

   </div>  </div>
 <div  :class="'text descriptionText4 '"   id="descriptionText4" ref="descriptionText4"  v-bind:style="{color : textColor,}">
<div class="text-content">{{ cText }}</div>

   </div>
 <div  :class="'row rowcolumnitemrectangleitem011353 '"   id="rowcolumnitemrectangleitem011353" ref="rowcolumnitemrectangleitem011353"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#fff'" 
 :outlined = "true" 
 :rounded = "true" 
 :dark = "true" 
 @click="bannerAction" :class="'button buttonrowcolumnitemrectangleitem011351 ' + 'padded'"   id="buttonrowcolumnitemrectangleitem011351" ref="buttonrowcolumnitemrectangleitem011351"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-ios-play' !== undefined"  :left = "true" 
 :color = "'#ffdb0f'" 
>{{ 'ion-ios-play' }}
</v-icon><span>{{ buttonText }}</span>
</v-btn>  </div>
 <div  :class="'transition transitiongroupitemrectangleitem0115 '"   id="transitiongroupitemrectangleitem0115" ref="transitiongroupitemrectangleitem0115"  v-bind:style="{}">
 <transition  :enter-active-class = "'animated bounceInRight'" 
>
 <a  :class="'link linktransitiongroupitemrectangleitem01152 '"   id="linktransitiongroupitemrectangleitem01152" ref="linktransitiongroupitemrectangleitem01152" v-if="imageVisible"  v-bind:style="{}">
 <v-card 
 :class="'card cardtransitiongroupitemrectangleitem01151 ' + 'imageArea'"   id="cardtransitiongroupitemrectangleitem01151" ref="cardtransitiongroupitemrectangleitem01151"  v-bind:style="{}">
 <v-img  :class="'networkimage imagecardtransitiongroupitemrectangleitem011511 '"   id="imagecardtransitiongroupitemrectangleitem011511" ref="imagecardtransitiongroupitemrectangleitem011511"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "currentImage" 
>  </v-img>  </v-card>  </a>  </transition></div>  </div>
 <div  :class="'item itemitem012 '"   id="itemitem012" ref="itemitem012"  v-bind:style="{}">
 <div  :class="'row rowitemitem011 '"   id="rowitemitem011" ref="rowitemitem011"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerowitemitem0111 '"   id="rectanglerowitemitem0111" ref="rectanglerowitemitem0111" v-for="(item,index) in numberMessages" :key="'rectanglerowitemitem0111' + index"  v-bind:style="{backgroundColor : (currentImageIndex === index? '#000' : '#b1b1b1'),}">
 <div  :class="'mousearea mousearearectanglerowitemitem01111 '"   id="mousearearectanglerowitemitem01111" ref="mousearearectanglerowitemitem01111"  v-bind:style="{}" @click="showImage(index)"></div>  </div>  </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'Banner',

    components: {},
	methods: {
	
		showImage: function(index){ 		
	this.imageVisible = false

	this.currentImageIndex = index

	this.imageVisible = true

}
,
	},
	mounted(){
			
	setTimeout(function(){ this.imageVisible = true }.bind(this), 1000);

	},
	computed: {
	
		numberMessages: function(){		
	return this.bannerMessages.length

}
,
		currentMessage: function() { 		
	let curr = this.bannerMessages[this.currentImageIndex]

	

	if(curr){

		return curr

	}

	return ({})

}
,
		cTtitle: function() { 		
	return this.currentMessage.title

}
,
		cText: function() { 		
	return this.currentMessage.message

}
,
		cBgColor: function() { 		
	return this.currentMessage.bgColor

}
,
		currentImage: function() { 		
	if(this.currentMessage){

		return this.$store.state.apiURL + this.currentMessage.image.url

	}

	return ""

}
,
	},
	data(){
	return {
		
		currentImageIndex:  0		,
		imageVisible:  false		,
		}
	},
	props: {
	
		title: {					required: false,			default: () =>  "abcCode",		},
		bannerMessages: {					required: false,			default: () =>  [],		},
		buttonText: {					required: false,			default: () =>  "Block Based Coding",		},
		bannerAction: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		miniLogo: {					required: false,			default: () =>  "Resources/abcCode_Logo_N_G_transparent.png",		},
		textColor: {					required: false,			default: () =>  "#fff",		},
		maxNumberImage: {					required: false,			default: () =>  4,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Banner{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.padded{
	padding: 16px !important;
}
.imageArea{
	cursor: pointer;
	&:hover{
    transform: scale(1.01);
	}
}
.descriptionText{
	.text-content{
    height: 100%;
	}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 5px ; 

top: 5px ; 
right: 5px ; 
bottom: 5px ; 

background-color:#ffffff ; 
border-radius:30px ; 

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 38px ; 
	
	top: 38px ; 
	right: 38px ; 
	bottom: 38px ; 
	

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		top: 0.25px ; 
		width:40%!important ; 
		height:64.75px!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		justify-content:space-between ; background-color: transparent ; 

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			opacity: 0.7 ;  filter: alpha(opacity=70) ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:18px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			font-weight: bold;
			}

			
}

			.text.textcolumnitemrectangleitem011314{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			font-size:25px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			font-weight: bold;
			}

			
}

		
}

		.text.descriptionText4{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		top: 75px ; 
		bottom: 40px ; 
		opacity: 0.7 ;  filter: alpha(opacity=70) ; 
		width:40%!important ; 
		
		font-size:18px ; 
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: normal;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		}

		
}

		.row.rowcolumnitemrectangleitem011353{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		bottom: 0px ; 
		width:auto!important ; 
		height:40px!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;background-color:transparent ; 

			.button.buttonrowcolumnitemrectangleitem011351{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			width:auto!important ; 
			height:40px!important ; 
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		 display:block !important; 
		width:50%!important ; 
		

			.link.linktransitiongroupitemrectangleitem01152{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			display: block;

				.card.cardtransitiongroupitemrectangleitem01151{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				background-color:#ffffff ; 
				border-radius:10px ; max-width: initial;min-width: initial;

					.networkimage.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 0px ; 
					
					top: 0px ; 
					right: 0px ; 
					bottom: 0px ; 
					
					display: inherit;

					flex: unset;
					border-radius:10px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

					
}

				
}

			
}

		
}

	
}

	.item.itemitem012{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	right: 0px ; 
	bottom: 5px ; 
	height:20px!important ; 
	

		.row.rowitemitem011{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
		
		left: calc(50% + 0px) ; 
		
		top: 0px ; 
		bottom: 0px ; 
		width:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:5px ; background-color:transparent ; 

			.rectangle.rectanglerowitemitem0111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:10px!important ; 
			height:10px!important ; 
			
			background-color:#ffffff ; 
			border-radius:7px ; 

				.mousearea.mousearearectanglerowitemitem01111{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				cursor: pointer;
				}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		width:100%!important ; 
		
		
		
		

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textcolumnitemrectangleitem011314{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.descriptionText4{

		
		position:absolute !important; 
		
		
		
		
		width:100%!important ; 
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnitemrectangleitem011353{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcolumnitemrectangleitem011351{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		display:none !important; 
		

			.link.linktransitiongroupitemrectangleitem01152{

			
			position:absolute !important; 
			
			
			
			
			
			display: block;

				.card.cardtransitiongroupitemrectangleitem01151{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.networkimage.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

			
}

		
}

	
}

	.item.itemitem012{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowitemitem011{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.rectangle.rectanglerowitemitem0111{

			
			position:relative !important; 
			
			
			
			
			
			

				.mousearea.mousearearectanglerowitemitem01111{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textcolumnitemrectangleitem011314{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.descriptionText4{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnitemrectangleitem011353{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcolumnitemrectangleitem011351{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.link.linktransitiongroupitemrectangleitem01152{

			
			position:absolute !important; 
			
			
			
			
			
			display: block;

				.card.cardtransitiongroupitemrectangleitem01151{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.networkimage.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

			
}

		
}

	
}

	.item.itemitem012{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowitemitem011{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.rectangle.rectanglerowitemitem0111{

			
			position:relative !important; 
			
			
			
			
			
			

				.mousearea.mousearearectanglerowitemitem01111{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textcolumnitemrectangleitem011314{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.descriptionText4{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnitemrectangleitem011353{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcolumnitemrectangleitem011351{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.link.linktransitiongroupitemrectangleitem01152{

			
			position:absolute !important; 
			
			
			
			
			
			display: block;

				.card.cardtransitiongroupitemrectangleitem01151{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.networkimage.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

			
}

		
}

	
}

	.item.itemitem012{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowitemitem011{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.rectangle.rectanglerowitemitem0111{

			
			position:relative !important; 
			
			
			
			
			
			

				.mousearea.mousearearectanglerowitemitem01111{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textcolumnitemrectangleitem011314{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.descriptionText4{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnitemrectangleitem011353{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcolumnitemrectangleitem011351{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.link.linktransitiongroupitemrectangleitem01152{

			
			position:absolute !important; 
			
			
			
			
			
			display: block;

				.card.cardtransitiongroupitemrectangleitem01151{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.networkimage.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

			
}

		
}

	
}

	.item.itemitem012{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowitemitem011{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.rectangle.rectanglerowitemitem0111{

			
			position:relative !important; 
			
			
			
			
			
			

				.mousearea.mousearearectanglerowitemitem01111{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textcolumnitemrectangleitem011314{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.text.descriptionText4{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowcolumnitemrectangleitem011353{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcolumnitemrectangleitem011351{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.link.linktransitiongroupitemrectangleitem01152{

			
			position:absolute !important; 
			
			
			
			
			
			display: block;

				.card.cardtransitiongroupitemrectangleitem01151{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.networkimage.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

			
}

		
}

	
}

	.item.itemitem012{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowitemitem011{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.rectangle.rectanglerowitemitem0111{

			
			position:relative !important; 
			
			
			
			
			
			

				.mousearea.mousearearectanglerowitemitem01111{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

		
}

	
}


}


}
}
}
</style>
