<template>
 <div>  
<div id="SecondBanner"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-card 
 :class="'card rectangleitem01 ' + 'bannerArea'"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : bgColor,}">
 <v-img  :class="'image imagerectangleitem013 '"   id="imagerectangleitem013" ref="imagerectangleitem013"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + bgImg + '')" 
>  </v-img>
 <div  :class="'item itemrectangleitem011 '"   id="itemrectangleitem011" ref="itemrectangleitem011"  v-bind:style="{}">
 <v-img  :class="'image imageitemrectangleitem011143 '"   id="imageitemrectangleitem011143" ref="imageitemrectangleitem011143"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + miniLogo + '')" 
>  </v-img>
 <div  :class="'column columnitemrectangleitem0113 '"   id="columnitemrectangleitem0113" ref="columnitemrectangleitem0113"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text title '"   id="title" ref="title"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ title }}</div>

   </div>
 <div  :class="'text descriptionText '"   id="descriptionText" ref="descriptionText"  v-bind:style="{color : '#e6eafa',}">
<div class="text-content">{{ text }}</div>

   </div>
 <v-btn tag="div" 
 :color = "'#fff'" 
 :outlined = "true" 
 :rounded = "true" 
 :dark = "true" 
 @click="bannerAction" :class="'button buttonrowcolumnitemrectangleitem0113514 ' + 'padded'"   id="buttonrowcolumnitemrectangleitem0113514" ref="buttonrowcolumnitemrectangleitem0113514"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "buttonIcon !== undefined"  :left = "true" 
 :color = "'#ffdb0f'" 
>{{ buttonIcon }}
</v-icon><span>{{ buttonText }}</span>
</v-btn>  </div>
 <div  :class="'transition transitiongroupitemrectangleitem0115 '"   id="transitiongroupitemrectangleitem0115" ref="transitiongroupitemrectangleitem0115"  v-bind:style="{}">
 <transition >
 <v-card 
 :class="'card cardtransitiongroupitemrectangleitem01151 '"   id="cardtransitiongroupitemrectangleitem01151" ref="cardtransitiongroupitemrectangleitem01151"  v-bind:style="{backgroundColor : miniColor1,}">
 <v-img  :class="'image imagecardtransitiongroupitemrectangleitem011511 '"   id="imagecardtransitiongroupitemrectangleitem011511" ref="imagecardtransitiongroupitemrectangleitem011511"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + miniImg1 + '')" 
>  </v-img>
 <v-card 
 :class="'card cardtransitiongroupitemrectangleitem011512 '"   id="cardtransitiongroupitemrectangleitem011512" ref="cardtransitiongroupitemrectangleitem011512"  v-bind:style="{backgroundColor : miniColor2,}">
 <v-img  :class="'image imagecardtransitiongroupitemrectangleitem011511 '"   id="imagecardtransitiongroupitemrectangleitem011511" ref="imagecardtransitiongroupitemrectangleitem011511"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + miniImg2 + '')" 
>  </v-img>  </v-card>
 <v-card 
 :class="'card cardtransitiongroupitemrectangleitem011513 '"   id="cardtransitiongroupitemrectangleitem011513" ref="cardtransitiongroupitemrectangleitem011513"  v-bind:style="{backgroundColor : miniColor3,}">
 <v-img  :class="'image imagecardtransitiongroupitemrectangleitem011511 '"   id="imagecardtransitiongroupitemrectangleitem011511" ref="imagecardtransitiongroupitemrectangleitem011511"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + miniImg3 + '')" 
>  </v-img>  </v-card>  </v-card>  </transition></div>  </div>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'SecondBanner',

    components: {},
	props: {
	
		miniLogo: {					required: false,			default: () =>  "Resources/abcCode_Logo_N_G_transparent.png",		},
		title: {					required: false,			default: () =>  "abcCode",		},
		text: {					required: false,			default: () =>  "Learn coding fundamentals. Simple blocks become powerful Javascript apps. You don’t need coding knowledge to start!",		},
		buttonText: {					required: false,			default: () =>  "Block Based Coding",		},
		bannerAction: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		miniImg1: {					required: false,			default: () =>  "Resources/abcCode_Logo_N_G_transparent.png",		},
		miniImg2: {					required: false,			default: () =>  "Resources/abcCode_Logo_N_G_transparent.png",		},
		miniImg3: {					required: false,			default: () =>  "Resources/abcCode_Logo_N_G_transparent.png",		},
		bgColor: {					required: false,			default: () =>  "#414a51",		},
		bgImg: {					required: false,			default: () =>  "Resources/background/confetti.png",		},
		buttonIcon: {					required: false,			default: () =>  "ion-ios-play",		},
		miniColor1: {					required: false,			default: () =>  "#fff",		},
		miniColor2: {					required: false,			default: () =>  "#fff",		},
		miniColor3: {					required: false,			default: () =>  "#fff",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SecondBanner{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.bannerArea{
	cursor: pointer;
	&:hover{
    transform: scale(1.01);
	}
}
.padded{
	padding: 16px !important;
}
	

.descriptionText{
	.text-content{
    height: 100%;
	}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.card.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 5px ; 

top: 5px ; 
right: 5px ; 
bottom: 5px ; 
background-color:#ffffff ; 
border-radius:30px ; max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}
	::v-deep .v-image__image.v-image__image--cover {
    background-size: unset;
    background-repeat: repeat;
}


	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 38px ; 
	
	top: 38px ; 
	right: 38px ; 
	bottom: 38px ; 
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		top: 0px ; 
		width:56px!important ; 
		height:56px!important ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 70px ; 
		right: 65px ; 
		bottom: 0px ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		justify-content:space-between ; background-color: transparent ; 

			.text.title{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#fff ; 
			font-size:30px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			font-weight: bold;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:40%!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#e6eafa ; 
			font-size:16px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			}

			
}

			.button.buttonrowcolumnitemrectangleitem0113514{

			height: auto;
			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			width:auto!important ; 
			height:40px!important ; 
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: -49px ; 
		right: 0px ; 
		 display:block !important; 
		width:150px!important ; 
		height:150px!important ; 
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			top: 0px ; 
			width:150px!important ; 
			height:150px!important ; 
			background-color:#ffffff ; 
			border-radius:8px ; max-width: initial;min-width: initial;

				.image.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

				
}

				.card.cardtransitiongroupitemrectangleitem011512{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
				
				left: -42px ; 
				
				top: calc(50% + 0px) ; 
				width:84px!important ; 
				height:84px!important ; 
				background-color:#ffffff ; 
				border-radius:8px ; max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 0px ; 
					
					top: 0px ; 
					right: 0px ; 
					bottom: 0px ; 
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

					
}

				
}

				.card.cardtransitiongroupitemrectangleitem011513{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				right: -52px ; 
				bottom: -52px ; 
				width:105px!important ; 
				height:105px!important ; 
				background-color:#ffffff ; 
				border-radius:8px ; max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 0px ; 
					
					top: 0px ; 
					right: 0px ; 
					bottom: 0px ; 
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.card.rectangleitem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.title{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcolumnitemrectangleitem0113514{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		display:none !important; 
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.image.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.card.cardtransitiongroupitemrectangleitem011512{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

				.card.cardtransitiongroupitemrectangleitem011513{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.card.rectangleitem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.title{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcolumnitemrectangleitem0113514{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.image.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.card.cardtransitiongroupitemrectangleitem011512{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

				.card.cardtransitiongroupitemrectangleitem011513{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.card.rectangleitem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.title{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcolumnitemrectangleitem0113514{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.image.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.card.cardtransitiongroupitemrectangleitem011512{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

				.card.cardtransitiongroupitemrectangleitem011513{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.card.rectangleitem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.title{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcolumnitemrectangleitem0113514{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.image.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.card.cardtransitiongroupitemrectangleitem011512{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

				.card.cardtransitiongroupitemrectangleitem011513{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.card.rectangleitem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.image.imagerectangleitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.title{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.descriptionText{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.button.buttonrowcolumnitemrectangleitem0113514{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.transition.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.image.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.card.cardtransitiongroupitemrectangleitem011512{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

				.card.cardtransitiongroupitemrectangleitem011513{

				
				position:absolute !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.image.imagecardtransitiongroupitemrectangleitem011511{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
