<template>
 <div>  
<div id="Ep_CommentPost"> 
         
 <div  :class="'rectangle rectangle0 ' + 'commentBox'"   id="rectangle0" ref="rectangle0"  v-bind:style="{height : getUnit(explicitHeigth,'px'),backgroundColor : '#eeedf0',}">
 <v-img  :class="'networkimage networkimagerowrectangle0111 '"   id="networkimagerowrectangle0111" ref="networkimagerowrectangle0111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "userImage" 
>  </v-img>
 <div  :class="'column columnrectangle04 '"   id="columnrectangle04" ref="columnrectangle04"  v-bind:style="{}">
 <v-text-field  :placeholder = "'Commenter'" 
 :append-icon = "'ion-ios-nutrition'" 
 :rounded = "true" 
 solo :class="'textfield commentField '"   id="commentField" ref="commentField" v-model="commentValue"  v-bind:style="{color : '#ff6900',}" @click:append="addComments"></v-text-field>
 <div  :class="'row rowitem052 '"   id="rowitem052" ref="rowitem052" v-if="focused"  v-bind:style="{justifyContent : 'flex-end',}">
 <v-btn tag="div" 
 :color = "'#ff6b00'" 
 :rounded = "true" 
 :dark = "true" 
 @click="addComments" :class="'button buttonrowitem051 '"   id="buttonrowitem051" ref="buttonrowitem051"  v-bind:style="{}"><span>{{ 'Envoyer' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#656a6e'" 
 :rounded = "true" 
 :dark = "true" 
 @click="lostFocus" :class="'button buttonrowitem0512 '"   id="buttonrowitem0512" ref="buttonrowitem0512"  v-bind:style="{}"><span>{{ 'Annuler' }}</span>
</v-btn>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as utils from '../../../Js/utils.js'
export default {
	name: 'Ep_CommentPost',

    components: {},
	methods: {
	
		getFocus: function() { 		
	this.focused = true

}
,
		lostFocus: function() {  		
	this.focused = false

}	
,
		addComments: function(){		
	let filter = {

		project: this.projectID,

		value: this.commentValue,

		user: this.$store.state.user.id

	}

	utils.addComments(filter)

	.then((response)=>{

		this.reloadComments()

		this.getFocus()

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
	},
	mounted(){
			
	document.querySelector('#commentField').addEventListener("focusin", this.getFocus);

	},
	computed: {
	
		explicitHeigth: function() { 		
	return (this.focused? 130 : 88)

}
,
	},
	data(){
	return {
		
		focused:  false		,
		commentValue:  null		,
		}
	},
	props: {
	
		userImage: {					required: false,			default: () =>  "https://picsum.photos/500",		},
		projectID: {					required: false,			default: () =>  0,		},
		reloadComments: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Ep_CommentPost{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.commentBox{
	padding-bottom: 15px;
}
.button{
	padding: 10px !important;
}



.rectangle.rectangle0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
width: calc(100% - (0px + 0px)) !important;

background-color:#eeedf0 ; 
border-radius:20px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#a7a7a7 ; 

.networkimage.networkimagerowrectangle0111{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:20px ; 

top: 20px ; 
width:37px!important ; 
height:37px!important ; 

display: inherit;

flex: unset;
border-radius:18.5px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}


}

.column.columnrectangle04{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 70px ; 

top: 20px ; 
right: 20px ; 
height:auto!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

	.textfield.commentField{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:48px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	color:#ff6900 ; 
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.row.rowitem052{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:40px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:8px ; 
	justify-content:flex-end ; background-color:transparent ; 

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:32px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:32px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

	
}


}


}


@media (max-width: 600px) {


.rectangle.rectangle0{


position:relative !important; 







.networkimage.networkimagerowrectangle0111{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.column.columnrectangle04{


position:absolute !important; 









	.textfield.commentField{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.row.rowitem052{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 601px) {


.rectangle.rectangle0{


position:relative !important; 







.networkimage.networkimagerowrectangle0111{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.column.columnrectangle04{


position:absolute !important; 









	.textfield.commentField{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.row.rowitem052{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 961px) {


.rectangle.rectangle0{


position:relative !important; 







.networkimage.networkimagerowrectangle0111{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.column.columnrectangle04{


position:absolute !important; 









	.textfield.commentField{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.row.rowitem052{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1265px) {


.rectangle.rectangle0{


position:relative !important; 







.networkimage.networkimagerowrectangle0111{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.column.columnrectangle04{


position:absolute !important; 









	.textfield.commentField{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.row.rowitem052{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1905px) {


.rectangle.rectangle0{


position:relative !important; 







.networkimage.networkimagerowrectangle0111{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.column.columnrectangle04{


position:absolute !important; 









	.textfield.commentField{

	
	position:relative !important; 
	
	
	
	
	
	::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
	
::v-deep &.v-input{
		flex-grow: 0;
input{

}
	}
	}

	.row.rowitem052{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}
}
</style>
