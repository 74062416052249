<template>
 <div>  
<div id="Footer"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'item itemitem01 '"   id="itemitem01" ref="itemitem01"  v-bind:style="{}">
 <div  :class="'row rowitemitem011 '"   id="rowitemitem011" ref="rowitemitem011"  v-bind:style="{}">
 <router-link  :class="'routerlink routerlinkrowitemitem0111 '"   id="routerlinkrowitemitem0111" ref="routerlinkrowitemitem0111"  v-bind:style="{}" :to = "'/term-of-use'" 
>
 <div  :class="'text textrouterlinkrowitemitem01111 '"   id="textrouterlinkrowitemitem01111" ref="textrouterlinkrowitemitem01111"  v-bind:style="{color : '#656a6e',}">
<div class="text-content">{{ 'Termes d\' utilisation' }}</div>

   </div>  </router-link>
 <div  :class="'item itemrowitemitem0113 '"   id="itemrowitemitem0113" ref="itemrowitemitem0113"  v-bind:style="{}">  </div>
 <router-link  :class="'routerlink routerlinkrowitemitem01113 '"   id="routerlinkrowitemitem01113" ref="routerlinkrowitemitem01113"  v-bind:style="{}" :to = "'/term-of-use'" 
>
 <div  :class="'text textrouterlinkrowitemitem01111 '"   id="textrouterlinkrowitemitem01111" ref="textrouterlinkrowitemitem01111"  v-bind:style="{color : '#656a6e',}">
<div class="text-content">{{ 'Termes d\' utilisation' }}</div>

   </div>  </router-link>  </div>
 <div  :class="'row rowitemitem013 '"   id="rowitemitem013" ref="rowitemitem013"  v-bind:style="{}">
 <div  :class="'text textrowitemitem0131 '"   id="textrowitemitem0131" ref="textrowitemitem0131"  v-bind:style="{color : '#656a6e',}">
<div class="text-content">{{ '2021 CAYSTI. All Rights Reserved.' }}</div>

   </div>
 <div  :class="'item itemrowitemitem0135 '"   id="itemrowitemitem0135" ref="itemrowitemitem0135"  v-bind:style="{}">  </div>
 <v-btn tag="div" 
 :color = "'#ff6b00'" 
 :rounded = "true" 
 :dark = "true" 
 :class="'button buttonrowitem0512 '"   id="buttonrowitem0512" ref="buttonrowitem0512"  v-bind:style="{}"><span>{{ 'Politique de confidentialité' }}</span>
</v-btn>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'Footer',

    components: {},
	methods: {
	
		increment: function() {		
   this.count++

}
,
	},
	data(){
	return {
		
		count:  1		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Footer{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.button{
	padding: 10px !important;
  margin-right: 5px !important;
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.item.itemitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 13px ; 

top: 30px ; 
right: 13px ; 
bottom: 20px ; 


	.row.rowitemitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left:0px ; 
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;background-color:transparent ; 

		.routerlink.routerlinkrowitemitem0111{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:auto!important ; 
		height:auto!important ; 
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			margin-top: 0px ; 
			width:auto!important ; 
			height:auto!important ; 
			
			color:#656a6e ; 
			font-size:15px ; 
			.text-content{
			width: 100%;text-decoration: underline
			}

			
}

		
}

		.item.itemrowitemitem0113{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:20px!important ; 
		height:1px!important ; 
		

		
}

		.routerlink.routerlinkrowitemitem01113{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:auto!important ; 
		height:auto!important ; 
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			margin-top: 0px ; 
			width:auto!important ; 
			height:auto!important ; 
			
			color:#656a6e ; 
			font-size:15px ; 
			.text-content{
			width: 100%;text-decoration: underline
			}

			
}

		
}

	
}

	.row.rowitemitem013{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	width:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;background-color:transparent ; 

		.text.textrowitemitem0131{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:auto!important ; 
		
		color:#656a6e ; 
		.text-content{
		width: 100%;
		}

		
}

		.item.itemrowitemitem0135{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:10px!important ; 
		height:1px!important ; 
		

		
}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:32px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.item.itemitem01{


position:absolute !important; 






	.row.rowitemitem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.routerlink.routerlinkrowitemitem0111{

		
		position:relative !important; 
		
		
		
		
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.item.itemrowitemitem0113{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.routerlink.routerlinkrowitemitem01113{

		
		position:relative !important; 
		
		
		
		
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowitemitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowitemitem0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemrowitemitem0135{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.item.itemitem01{


position:absolute !important; 






	.row.rowitemitem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.routerlink.routerlinkrowitemitem0111{

		
		position:relative !important; 
		
		
		
		
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.item.itemrowitemitem0113{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.routerlink.routerlinkrowitemitem01113{

		
		position:relative !important; 
		
		
		
		
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowitemitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowitemitem0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemrowitemitem0135{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.item.itemitem01{


position:absolute !important; 






	.row.rowitemitem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.routerlink.routerlinkrowitemitem0111{

		
		position:relative !important; 
		
		
		
		
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.item.itemrowitemitem0113{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.routerlink.routerlinkrowitemitem01113{

		
		position:relative !important; 
		
		
		
		
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowitemitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowitemitem0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemrowitemitem0135{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.item.itemitem01{


position:absolute !important; 






	.row.rowitemitem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.routerlink.routerlinkrowitemitem0111{

		
		position:relative !important; 
		
		
		
		
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.item.itemrowitemitem0113{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.routerlink.routerlinkrowitemitem01113{

		
		position:relative !important; 
		
		
		
		
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowitemitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowitemitem0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemrowitemitem0135{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.item.itemitem01{


position:absolute !important; 






	.row.rowitemitem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.routerlink.routerlinkrowitemitem0111{

		
		position:relative !important; 
		
		
		
		
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

		.item.itemrowitemitem0113{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.routerlink.routerlinkrowitemitem01113{

		
		position:relative !important; 
		
		
		
		
		

			.text.textrouterlinkrowitemitem01111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowitemitem013{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowitemitem0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemrowitemitem0135{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}
}
</style>
