<template>
 <div>  
<div id="Logo"> 
         
 <div  :class="'rectangle rectangle0 '"   id="rectangle0" ref="rectangle0"  v-bind:style="{backgroundColor : bgColor,borderRadius : getUnit(radius,'px'),}">
 <div  :class="'flow flow02221 '"   id="flow02221" ref="flow02221"  v-bind:style="{left : getUnit(padding,'px'),top : getUnit(padding,'px'),right : getUnit(padding,'px'),bottom : getUnit(padding,'px'),}">
 <div  :class="'rectangle rectangleflow01 '"   id="rectangleflow01" ref="rectangleflow01"  v-bind:style="{backgroundColor : '#ff38de',}">  </div>
 <div  :class="'rectangle rectangleflow02 '"   id="rectangleflow02" ref="rectangleflow02"  v-bind:style="{backgroundColor : '#fff834',}">  </div>
 <div  :class="'rectangle rectangleflow03 '"   id="rectangleflow03" ref="rectangleflow03"  v-bind:style="{backgroundColor : '#657cff',}">  </div>
 <div  :class="'rectangle rectangleflow04 '"   id="rectangleflow04" ref="rectangleflow04"  v-bind:style="{backgroundColor : '#54ff93',}">  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'Logo',

    components: {},
	props: {
	
		bgColor: {					required: false,			default: () =>  "transparent",		},
		padding: {					required: false,			default: () =>  0,		},
		radius: {					required: false,			default: () =>  0,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Logo{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.rectangle.rectangle0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ffffff ; 

.flow.flow02221{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;




display: flex;
flex-wrap: wrap;
flex: initial;
gap:5% ; background-color:transparent ; 

	.rectangle.rectangleflow01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:45%!important ; 
	height:45%!important ; 
	
	background-color:#ff38de ; 
	border-radius:100% ; 

	
}

	.rectangle.rectangleflow02{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:45%!important ; 
	height:45%!important ; 
	
	background-color:#fff834 ; 

	
}

	.rectangle.rectangleflow03{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:45%!important ; 
	height:45%!important ; 
	
	background-color:#657cff ; 

	
}

	.rectangle.rectangleflow04{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:45%!important ; 
	height:45%!important ; 
	
	background-color:#54ff93 ; 
	border-radius:100% ; 

	
}


}


}


@media (max-width: 600px) {


.rectangle.rectangle0{


position:absolute !important; 







.flow.flow02221{


position:absolute !important; 









	.rectangle.rectangleflow01{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow02{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow03{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow04{

	
	position:relative !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 601px) {


.rectangle.rectangle0{


position:absolute !important; 







.flow.flow02221{


position:absolute !important; 









	.rectangle.rectangleflow01{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow02{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow03{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow04{

	
	position:relative !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 961px) {


.rectangle.rectangle0{


position:absolute !important; 







.flow.flow02221{


position:absolute !important; 









	.rectangle.rectangleflow01{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow02{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow03{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow04{

	
	position:relative !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 1265px) {


.rectangle.rectangle0{


position:absolute !important; 







.flow.flow02221{


position:absolute !important; 









	.rectangle.rectangleflow01{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow02{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow03{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow04{

	
	position:relative !important; 
	
	
	
	
	
	

	
}


}


}
}

@media (min-width: 1905px) {


.rectangle.rectangle0{


position:absolute !important; 







.flow.flow02221{


position:absolute !important; 









	.rectangle.rectangleflow01{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow02{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow03{

	
	position:relative !important; 
	
	
	
	
	
	

	
}

	.rectangle.rectangleflow04{

	
	position:relative !important; 
	
	
	
	
	
	

	
}


}


}
}
}
</style>
