<template>
 <div>  
<div id="Challenges"> 
         
 <TemplatePage :class="'object templatepage0 '"   id="templatepage0" ref="templatepage0"  v-bind:style="{}" :pageData = "pageData" 
 :currentPage = "1" 
>
 <template  v-slot:main>
 <div  :class="'slottemplate slottemplatetemplatepage01 '"   id="slottemplatetemplatepage01" ref="slottemplatetemplatepage01"  v-bind:style="{}">
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <Banner :class="'object herderBanner '"   id="herderBanner" ref="herderBanner"  v-bind:style="{}" :bannerAction = "goToFirstChallenge" 
 :bannerMessages = "pageData.banner_messages" 
 :title = "'Challenges'" 
 :buttonText = "'Allons y !'" 
>
</Banner>
 <div  :class="'scrollarea hScrollType '"   id="hScrollType" ref="hScrollType"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'row rowcolumncolumnscrollarea021031 '"   id="rowcolumncolumnscrollarea021031" ref="rowcolumncolumnscrollarea021031"  v-bind:style="{}">
 <ButtonNetworkImage :class="'object buttonimagerowcolumncolumnscrollarea021031 '"   id="buttonimagerowcolumncolumnscrollarea021031" ref="buttonimagerowcolumncolumnscrollarea021031" v-for="(item,index) in challengeTypes" :key="'buttonimagerowcolumncolumnscrollarea021031' + index" @clickIt="selectType(index)"   v-bind:style="{}" :active = "(selectedType == index)" 
 :icon = "item.icon.url" 
 :text = "item.name" 
>
</ButtonNetworkImage>  </div>  </div>
 <LandingSection :class="'object landingSection1 '"   id="landingSection1" ref="landingSection1"  v-bind:style="{}" :expendSection = "expendChallenge" 
 :buttonText = "btnText" 
 :expended = "expendedChallenge" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate landingSlot '"   id="landingSlot" ref="landingSlot"  v-bind:style="{}">
 <v-row  :class="'grid gridslottemplatelandingsectioncolumncolumnscrollarea0210511 '"   id="gridslottemplatelandingsectioncolumncolumnscrollarea0210511" ref="gridslottemplatelandingsectioncolumncolumnscrollarea0210511"  v-bind:style="{}">
 <v-col  :class="'col colgridslottemplatelandingsectioncolumncolumnscrollarea02105111 '"   id="colgridslottemplatelandingsectioncolumncolumnscrollarea02105111" ref="colgridslottemplatelandingsectioncolumncolumnscrollarea02105111" v-for="(item,index) in numberShowedChallenge" :key="'colgridslottemplatelandingsectioncolumncolumnscrollarea02105111' + index"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <ChallengeCard :class="'object challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111 '"   id="challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111" ref="challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111"  v-bind:style="{}" :href = "'challenges/' + challengeCats[index].id" 
 :backImage = "challengeCats[index].description_image.url" 
 :topic = "challengeCats[index].name" 
 :logoImage = "challengeCats[index].mini_image.url" 
>
</ChallengeCard></div></v-col>  </v-row></div></template>
</LandingSection>
 <LandingBox :class="'object landingBox '"   id="landingBox" ref="landingBox"  v-bind:style="{}" :title = "'Tutoriels libres'" 
>
 <template  v-slot:content>
 <div  :class="'slottemplate landingSlot2 '"   id="landingSlot2" ref="landingSlot2"  v-bind:style="{}">
 <v-row  :class="'grid gridslottemplatelandingsectioncolumncolumnscrollarea0210511 '"   id="gridslottemplatelandingsectioncolumncolumnscrollarea0210511" ref="gridslottemplatelandingsectioncolumncolumnscrollarea0210511"  v-bind:style="{}">
 <v-col  :class="'col colgridslottemplatelandingsectioncolumncolumnscrollarea02105111 '"   id="colgridslottemplatelandingsectioncolumncolumnscrollarea02105111" ref="colgridslottemplatelandingsectioncolumncolumnscrollarea02105111" v-for="(item,index) in tutorieleList" :key="'colgridslottemplatelandingsectioncolumncolumnscrollarea02105111' + index"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <ChallengeCard :class="'object challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111 '"   id="challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111" ref="challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111"  v-bind:style="{}" :showBrand = "false" 
 :href = "('tutoriels/' + item.id)" 
 :backImage = "item.description_image.url" 
 :logoImage = "item.mini_image.url" 
 :topic = "item.name" 
>
</ChallengeCard></div></v-col>  </v-row></div></template>
</LandingBox>  </div></div></template>
</TemplatePage>
 
      </div>
 </div> 
</template>
<script>
import TemplatePage from '../../At/Pages/TemplatePage'
import Banner from '../../At/Components/Banner'
import ButtonNetworkImage from '../../At/Components/ButtonNetworkImage'
import LandingSection from '../../At/Components/LandingSection'
import ChallengeCard from '../../At/Components/Challenges/ChallengeCard'
import LandingBox from '../../At/Components/LandingBox'

import * as utils from '../../Js/utils.js'
export default {
	name: 'Challenges',

    components: {TemplatePage,Banner,ButtonNetworkImage,LandingSection,ChallengeCard,LandingBox,},
	methods: {
	
		expendChallenge: function(){ 		
	this.expendedChallenge = !this.expendedChallenge

	if(this.expendedChallenge){

		this.btnText = "Masquer les defis"

	} else {

		this.btnText = "Voire tous les challenges"

	}

}
,
		selectType: function(i){		
	this.selectedType = i

}
,
		goToFirstChallenge: function(){ 		
	this.$router.push('/challenges/' + this.challengeList[0].id)

}
,
		getPageData: function() { 		
	utils.getPageData('challenge-page')

	.then((res) => {

		this.pageData = res.data

	})

}
,
	},
	mounted(){
			
	// Get images from api

	utils.getImages()

	.then((response)=>{

	  this.imagesList = response.data.filter(e => {

			return (e.mime.match("image/") != null)

		});

		this.imagesList = this.imagesList.map(e => {

			e.url = this.$store.state.apiURL + e.url

			return e

		})

	})

	.catch((error)=>{

	  console.log(error);

	});

	

	// Get challenge-types

	utils.getChallengeTypes()

	.then((response)=>{

		this.challengeTypes = response.data.map(t => {

			t.icon.url = this.$store.state.apiURL + t.icon.url

			t.chall_cat.map(e => {

				e.description_image.url = this.$store.state.apiURL + e.description_image.url

				e.mini_image.url = this.$store.state.apiURL + e.mini_image.url

				return e

			})

			

			return t

		})

		

		this.selectedType = 0

	})

	.catch((error)=>{

	  console.log(error);

	});

	

	// Get tutoriels-categories

	utils.getTutorielCategories()

	.then((response)=>{

		this.tutorieleList = response.data.map(e => {

			e.description_image.url = this.$store.state.apiURL + e.description_image.url

			e.mini_image.url = this.$store.state.apiURL + e.mini_image.url

			return e

		})

		console.log(this.tutorieleList)

	})

	.catch((error)=>{

	  console.log(error);

	});

	

	this.getPageData()

	},
	computed: {
	
		mixedImagesList: function(){		
	return utils.mixed(this.imagesList)

}
,
		challengeCats: function() { 		
	if(this.selectedType != null){

		return this.challengeTypes[this.selectedType].chall_cat

	} else {

		return []

	}

}
,
		numberShowedChallenge: function() { 		
	if(this.expendedChallenge){

		return this.challengeCats.length

	} else {

		return Math.min(this.challengeCats.length, 4)

	}

}
,
	},
	data(){
	return {
		
		pageData:  ({})		,
		imagesList:  []		,
		titleList:  ["Des défis", "Des concours", "Des tutoriels"]		,
		textList:  [		
	"Résolvez des défis tous les jours et augmentez votre rang dans le classement",

	"Participez à des concours et gagnez des lots et prix.",

	"Entrenez vous et augmentez vos compétances graces à de nombreux tutoriels."	

]
,
		challengeTypes:  []			,
		tutorieleList:  []		,
		expendedChallenge:  false		,
		btnText:  "Voire tous les challenges"		,
		selectedType:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Challenges{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.object.templatepage0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
width:100px!important ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column0{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:20px ; background-color: transparent ; 

		.object.herderBanner{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:300px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.scrollarea.hScrollType{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:48px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		overflow:auto ; 
		overflow-x:auto ; 
		overflow-y:hidden ; 
		background-color:transparent ; 

			.row.rowcolumncolumnscrollarea021031{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			top: 0px ; 
			width:auto!important ; 
			height:50px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:10px ; background-color:transparent ; 

				.object.buttonimagerowcolumncolumnscrollarea021031{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				height:50px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.object.landingSection1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			margin-top: 0px ; 
			width:100px!important ; 
			height:auto!important ; 
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-top: 0px ; 
				margin-right: 0px ; 
				width:100px!important ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				background-color: transparent ; 

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					margin: unset;
					transform-origin: top left;
					
					
					
					width:100px!important ; 
					height:200px!important ; 
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-top: 0px ; 
						margin-right: 0px ; 
						margin-bottom: 0px ; 
						width: calc(100% - (0px + 0px)) !important;
						height: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

		.object.landingBox{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot2{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			margin-top: 0px ; 
			width:100px!important ; 
			height:100px!important ; 
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 10px ; 
				
				margin-right: 10px ; 
				width:100px!important ; 
				height:auto!important ; 
				width: calc(100% - (10px + 10px)) !important;
				background-color: transparent ; 

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					margin: unset;
					transform-origin: top left;
					
					
					
					width:100px!important ; 
					height:200px!important ; 
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						margin-left: 0px ; 
						
						margin-top: 0px ; 
						margin-right: 0px ; 
						margin-bottom: 0px ; 
						width: calc(100% - (0px + 0px)) !important;
						height: calc(100% - (0px + 0px)) !important;
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column0{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.scrollarea.hScrollType{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcolumncolumnscrollarea021031{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.buttonimagerowcolumncolumnscrollarea021031{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.object.landingSection1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					
					
					
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

		.object.landingBox{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot2{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					
					
					
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column0{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.scrollarea.hScrollType{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcolumncolumnscrollarea021031{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.buttonimagerowcolumncolumnscrollarea021031{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.object.landingSection1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					
					
					
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

		.object.landingBox{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot2{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					
					
					
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column0{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.scrollarea.hScrollType{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcolumncolumnscrollarea021031{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.buttonimagerowcolumncolumnscrollarea021031{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.object.landingSection1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					
					
					
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

		.object.landingBox{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot2{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					
					
					
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column0{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.scrollarea.hScrollType{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcolumncolumnscrollarea021031{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.buttonimagerowcolumncolumnscrollarea021031{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.object.landingSection1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					
					
					
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

		.object.landingBox{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot2{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					
					
					
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.column0{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.scrollarea.hScrollType{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcolumncolumnscrollarea021031{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.buttonimagerowcolumncolumnscrollarea021031{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.object.landingSection1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					
					
					
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

		.object.landingBox{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

			.slottemplate.landingSlot2{

			
			position:relative !important; 
			
			
			
			
			
			left: 0;
			top:0;
			right: 0;
			bottom: 0;
			width: 100% !important;
			height: 100% !important;

				.grid.gridslottemplatelandingsectioncolumncolumnscrollarea0210511{

				
				position:relative !important; 
				
				
				
				
				

					.col.colgridslottemplatelandingsectioncolumncolumnscrollarea02105111{

					
					position:relative !important; 
					
					
					
					

					::v-deep .col-container{position: relative;

					height: 100%;

					width: 100%;

						.object.challengecardcolgridslottemplatelandingsectioncolumncolumnscrollarea021051111{

						
						position:relative !important; 
						
						
						
						
						
							::v-deep > * {
								min-width: unset;
								min-height: unset;
							}

						
}

					
}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
