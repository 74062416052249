<template>
 <div>  
<div id="Ep_Filter"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <v-text-field  :placeholder = "$t('Explorer.pjName')" 
 :prepend-inner-icon = "'ion-ios-search'" 
 :rounded = "true" 
 :dense = "true" 
 solo :class="'textfield texteditcolumn03 '"   id="texteditcolumn03" ref="texteditcolumn03" v-model="m_searchText" :clearable = "true" 
  v-bind:style="{}"></v-text-field>
 <div  :class="'flow rowcolumncolumnscrollarea0210312 '"   id="rowcolumncolumnscrollarea0210312" ref="rowcolumncolumnscrollarea0210312"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "(activeFilter == index? '#ff6b00' : 'gray')" 
 :rounded = "true" 
 :text = "!(activeFilter == index)" 
 :dark = "(activeFilter == index)" 
 @click="switchFiter(index)" :class="'button buttonrowitem0511 '"   id="buttonrowitem0511" ref="buttonrowitem0511" v-for="(item,index) in filters" :key="'buttonrowitem0511' + index"  v-bind:style="{}"><span>{{ item.name }}</span>
</v-btn>
 <v-select
           :label = "'Tags'" 
 :prepend-inner-icon = "'ion-ios-at'" 
:items = "tagsList" :class="'select selectrowcolumncolumnscrollarea02103123 '"   id="selectrowcolumncolumnscrollarea02103123" ref="selectrowcolumncolumnscrollarea02103123" v-model="m_selectedTags" :multiple = "true" 
 :item-text = "'name'" 
 :item-value = "'id'" 
 :chips = "true" 
 :dense = "true" 
  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as utils from '../../../Js/utils.js'
export default {
	name: 'Ep_Filter',

    components: {},
	methods: {
	
		switchFiter: function(index) { 		
	this.activeFilter = index

	this.filters[index].action()

}
,
		getTags: function() { 		
	utils.getTags()

	.then((response)=>{

		this.tagsList = response.data

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
	},
	mounted(){
			
	this.getTags()

	},
	computed: {
	
		filter: function() { 		
	let filter = ({})

	

	if(this.m_searchText){

		filter.name_contains = this.m_searchText

	}

	

	if(this.m_selectedTags){

		filter.tags = this.m_selectedTags

	}

	

	return filter

}
,
	},
	watch: {
	
		m_selectedTags: function(val) { 		
	this.getProjects(this.filter)

}
,
		m_searchText: function(val) { 		
	this.getProjects(this.filter)

}
,
	},
	data(){
	return {
		
		activeFilter:  0		,
		filters:  [{name:'all', action: this.showAll}, 		
								{name:'loved', action: this.showLiked}]
,
		tagsList:  []		,
		m_selectedTags:  null		,
		m_searchText:  null		,
		}
	},
	props: {
	
		showAll: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showLiked: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		getProjects: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Ep_Filter{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.button{
	padding: 10px !important;
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
width:auto!important ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

.textfield.texteditcolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.flow.rowcolumncolumnscrollarea0210312{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: wrap;
flex: initial;
gap:10px ; background-color:transparent ; 

	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left:0px ; 
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:32px!important ; 
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}

	.select.selectrowcolumncolumnscrollarea02103123{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	item-text:name !important; 
	item-value:id !important; 
	
	}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.textfield.texteditcolumn03{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.flow.rowcolumncolumnscrollarea0210312{


position:relative !important; 









	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.select.selectrowcolumncolumnscrollarea02103123{

	
	position:relative !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.textfield.texteditcolumn03{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.flow.rowcolumncolumnscrollarea0210312{


position:relative !important; 









	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.select.selectrowcolumncolumnscrollarea02103123{

	
	position:relative !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.textfield.texteditcolumn03{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.flow.rowcolumncolumnscrollarea0210312{


position:relative !important; 









	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.select.selectrowcolumncolumnscrollarea02103123{

	
	position:relative !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.textfield.texteditcolumn03{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.flow.rowcolumncolumnscrollarea0210312{


position:relative !important; 









	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.select.selectrowcolumncolumnscrollarea02103123{

	
	position:relative !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.textfield.texteditcolumn03{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.flow.rowcolumncolumnscrollarea0210312{


position:relative !important; 









	.button.buttonrowitem0511{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.select.selectrowcolumncolumnscrollarea02103123{

	
	position:relative !important; 
	
	
	
	
	
	}


}


}
}
}
</style>
