<template>
 <div>  
<div id="NavPanel"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectangleitem011 '"   id="mousearearectangleitem011" ref="mousearearectangleitem011"  v-bind:style="{}" @click="closePanel"></div>  </div>
 <v-card 
 :class="'card carditem03 '"   id="carditem03" ref="carditem03"  v-bind:style="{backgroundColor : '#f2f2f2',}">
 <div  :class="'scrollarea scrollareacarditem032 '"   id="scrollareacarditem032" ref="scrollareacarditem032"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'column columncarditem034 '"   id="columncarditem034" ref="columncarditem034"  v-bind:style="{}">
 <div  :class="'row rowcarditem038 '"   id="rowcarditem038" ref="rowcarditem038"  v-bind:style="{}">
 <router-link  :class="'routerlink routerlinkitem062 '"   id="routerlinkitem062" ref="routerlinkitem062"  v-bind:style="{}" :to = "'/home'" 
>
 <div  :class="'rectangle rectangle01 '"   id="rectangle01" ref="rectangle01"  v-bind:style="{backgroundColor : '#46505d',}">
 <div  :class="'text textrectangle01 '"   id="textrectangle01" ref="textrectangle01"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ 'abcCode' }}</div>

   </div>
 <div  :class="'rectangle rectanglerectangle03 '"   id="rectanglerectangle03" ref="rectanglerectangle03"  v-bind:style="{}">  </div>
 <div  :class="'rectangle rectanglerectangle033 '"   id="rectanglerectangle033" ref="rectanglerectangle033"  v-bind:style="{}">  </div>
 <div  :class="'rectangle rectanglerectangle034 '"   id="rectanglerectangle034" ref="rectanglerectangle034"  v-bind:style="{}">  </div>
 <div  :class="'rectangle rectanglerectangle0335 '"   id="rectanglerectangle0335" ref="rectanglerectangle0335"  v-bind:style="{}">  </div>  </div>  </router-link>
 <v-btn tag="div" 
 :color = "'#ff6b00'" 
 :fab = "true" 
 :dark = "true" 
 @click="closePanel" :class="'button buttonrowitem055452 '"   id="buttonrowitem055452" ref="buttonrowitem055452"  v-bind:style="{}"><span v-if = "(!true) || (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-close-circle' !== undefined"  :color = "'#fff'" 
>{{ 'ion-md-close-circle' }}
</v-icon></v-btn>  </div>
 <div  :class="'column rowitem0332 '"   id="rowitem0332" ref="rowitem0332"  v-bind:style="{}">
 <ButtonA :class="'object buttonarowitem031 '"   id="buttonarowitem031" ref="buttonarowitem031"  v-bind:style="{}" :active = "(curentPage == 0)" 
 :href = "'/home'" 
>
</ButtonA>
 <ButtonA :class="'object buttonarowitem0312 '"   id="buttonarowitem0312" ref="buttonarowitem0312"  v-bind:style="{}" :text = "'Challenges'" 
 :active = "(curentPage == 1)" 
 :icon = "'ion-ios-flash'" 
 :href = "'/challenges'" 
>
</ButtonA>
 <ButtonA :class="'object buttonarowitem0313 '"   id="buttonarowitem0313" ref="buttonarowitem0313"  v-bind:style="{}" :text = "'Explorer'" 
 :active = "(curentPage == 2)" 
 :icon = "'ion-md-planet'" 
 :href = "'/explore'" 
>
</ButtonA>
 <ButtonB :class="'object buttonarowitem0314 '"   id="buttonarowitem0314" ref="buttonarowitem0314"  v-bind:style="{}" :click = "showLunch" 
 :text = "'Exécuter'" 
 :active = "(curentPage == 3)" 
 :icon = "'ion-ios-play'" 
>
</ButtonB>
 <div  :class="'column rowitem0535 '"   id="rowitem0535" ref="rowitem0535" v-if="loginSininVisible"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#ff6b00'" 
 :rounded = "true" 
 :dark = "true" 
 @click="showSignup" :class="'button buttonrowitem051 ' + 'textButton'"   id="buttonrowitem051" ref="buttonrowitem051"  v-bind:style="{}"><span>{{ 'S\' enregistre' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#656a6e'" 
 :rounded = "true" 
 :dark = "true" 
 @click="showLogin" :class="'button buttonrowitem0512 ' + 'textButton'"   id="buttonrowitem0512" ref="buttonrowitem0512"  v-bind:style="{}"><span>{{ 'Se connecter' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#656a6e'" 
 :fab = "true" 
 :onlyIcon = "true" 
 :dark = "true" 
 :class="'button buttonrowitem055 '"   id="buttonrowitem055" ref="buttonrowitem055"  v-bind:style="{}"><span v-if = "(!true) || (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-information-circle' !== undefined" >{{ 'ion-ios-information-circle' }}
</v-icon></v-btn>  </div>  </div>  </div>  </div>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>
import ButtonA from '../../At/Components/ButtonA'
import ButtonB from '../../At/Components/ButtonB'


export default {
	name: 'NavPanel',

    components: {ButtonA,ButtonB,},
	computed: {
	
		loginSininVisible: function(){		
	return (this.userData? false : true)

}
,
		userData: function(){ 		
	return this.$store.state.user;

}
,
	},
	props: {
	
		curentPage: {					required: false,			default: () =>  0,		},
		showLunch: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		closePanel: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showSignup: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showLogin: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#NavPanel{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.textButton{
	padding: 10px !important;
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.5 ;  filter: alpha(opacity=50) ; 

background-color:#000 ; 

	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	}


}

.card.carditem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

top: 0px ; 
bottom: 0px ; 
width:75%!important ; 

background-color:#f2f2f2 ; 
border-radius:0px ; max-width: initial;min-width: initial;

	.scrollarea.scrollareacarditem032{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	overflow-x:hidden ; 
	overflow-y:scroll ; 
	background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #ffe4c9;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #ffe4c9;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #ff6b00;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

		.column.columncarditem034{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: 10px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:50px ; background-color: transparent ; 

			.row.rowcarditem038{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:10px ; 
			
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:4px ; background-color:transparent ; 

				.routerlink.routerlinkitem062{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				width:80px!important ; 
				height:32px!important ; 
				

					.rectangle.rectangle01{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 0px ; 
					
					top: 0px ; 
					right: 0px ; 
					bottom: 0px ; 
					
					background-color:#46505d ; 
					border-radius:4px ; 

						.text.textrectangle01{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left: 0px ; 
						
						top: 0px ; 
						right: 0px ; 
						bottom: 0px ; 
						
						color:#fff ; 
						font-size:15px ; 
						text-align:center;
						align-items:center;
						display: flex;
						.text-content{
						width: 100%;
						font-weight: bold;
						}

						
}

						.rectangle.rectanglerectangle03{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left:2px ; 
						
						top: 2px ; 
						width:4px!important ; 
						height:4px!important ; 
						
						background-color:#ffffff ; 
						border-radius:2px ; 

						
}

						.rectangle.rectanglerectangle033{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left:2px ; 
						
						bottom: 2px ; 
						width:4px!important ; 
						height:4px!important ; 
						
						background-color:#ffffff ; 
						border-radius:2px ; 

						
}

						.rectangle.rectanglerectangle034{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						
						top: 2px ; 
						right: 2px ; 
						width:4px!important ; 
						height:4px!important ; 
						
						background-color:#ffffff ; 
						border-radius:2px ; 

						
}

						.rectangle.rectanglerectangle0335{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						
						right: 2px ; 
						bottom: 2px ; 
						width:4px!important ; 
						height:4px!important ; 
						
						background-color:#ffffff ; 
						border-radius:2px ; 

						
}

					
}

				
}

				.button.buttonrowitem055452{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				 display:flex !important; 
				width:32px!important ; 
				height:32px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.rowitem0332{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.object.buttonarowitem031{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:60px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0312{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:60px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0313{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:60px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0314{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:60px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.rowitem0535{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
				
				left: calc(50% + 0px) ; 
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:10px ; background-color: transparent ; 

					.button.buttonrowitem051{

					height: auto;
					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left:0px ; 
					
					width:auto!important ; 
					height:32px!important ; 
					
					text-transform:unset ; 
					min-width: auto;
					padding-left: 16px;
					padding-right: 16px;

					i.v-icon{
					}
					}

					.button.buttonrowitem0512{

					height: auto;
					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left:0px ; 
					
					width:auto!important ; 
					height:32px!important ; 
					
					text-transform:unset ; 
					min-width: auto;
					padding-left: 16px;
					padding-right: 16px;

					i.v-icon{
					}
					}

					.button.buttonrowitem055{

					height: auto;
					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					width:32px!important ; 
					height:32px!important ; 
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.carditem03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.scrollarea.scrollareacarditem032{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columncarditem034{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.row.rowcarditem038{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.routerlink.routerlinkitem062{

				
				position:relative !important; 
				
				
				
				
				

					.rectangle.rectangle01{

					
					position:absolute !important; 
					
					
					
					
					
					

						.text.textrectangle01{

						
						position:absolute !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.rectangle.rectanglerectangle03{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle033{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle034{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle0335{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

					
}

				
}

				.button.buttonrowitem055452{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.rowitem0332{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.buttonarowitem031{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0312{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0313{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0314{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.rowitem0535{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonrowitem051{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.buttonrowitem0512{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.buttonrowitem055{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.carditem03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.scrollarea.scrollareacarditem032{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columncarditem034{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.row.rowcarditem038{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.routerlink.routerlinkitem062{

				
				position:relative !important; 
				
				
				
				
				

					.rectangle.rectangle01{

					
					position:absolute !important; 
					
					
					
					
					
					

						.text.textrectangle01{

						
						position:absolute !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.rectangle.rectanglerectangle03{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle033{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle034{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle0335{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

					
}

				
}

				.button.buttonrowitem055452{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.rowitem0332{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.buttonarowitem031{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0312{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0313{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0314{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.rowitem0535{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonrowitem051{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.buttonrowitem0512{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.buttonrowitem055{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.carditem03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.scrollarea.scrollareacarditem032{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columncarditem034{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.row.rowcarditem038{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.routerlink.routerlinkitem062{

				
				position:relative !important; 
				
				
				
				
				

					.rectangle.rectangle01{

					
					position:absolute !important; 
					
					
					
					
					
					

						.text.textrectangle01{

						
						position:absolute !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.rectangle.rectanglerectangle03{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle033{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle034{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle0335{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

					
}

				
}

				.button.buttonrowitem055452{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				display:none !important; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.rowitem0332{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.buttonarowitem031{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0312{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0313{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0314{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.rowitem0535{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonrowitem051{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.buttonrowitem0512{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.buttonrowitem055{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.carditem03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.scrollarea.scrollareacarditem032{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columncarditem034{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.row.rowcarditem038{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.routerlink.routerlinkitem062{

				
				position:relative !important; 
				
				
				
				
				

					.rectangle.rectangle01{

					
					position:absolute !important; 
					
					
					
					
					
					

						.text.textrectangle01{

						
						position:absolute !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.rectangle.rectanglerectangle03{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle033{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle034{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle0335{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

					
}

				
}

				.button.buttonrowitem055452{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.rowitem0332{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.buttonarowitem031{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0312{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0313{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0314{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.rowitem0535{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonrowitem051{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.buttonrowitem0512{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.buttonrowitem055{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.carditem03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.scrollarea.scrollareacarditem032{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columncarditem034{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.row.rowcarditem038{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.routerlink.routerlinkitem062{

				
				position:relative !important; 
				
				
				
				
				

					.rectangle.rectangle01{

					
					position:absolute !important; 
					
					
					
					
					
					

						.text.textrectangle01{

						
						position:absolute !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.rectangle.rectanglerectangle03{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle033{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle034{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

						.rectangle.rectanglerectangle0335{

						
						position:absolute !important; 
						
						
						
						
						
						

						
}

					
}

				
}

				.button.buttonrowitem055452{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.column.rowitem0332{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.object.buttonarowitem031{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0312{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0313{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.buttonarowitem0314{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.rowitem0535{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.button.buttonrowitem051{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.buttonrowitem0512{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.buttonrowitem055{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
