<template>
 <div>  
<div id="SegmentContent"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'text textcolumn01 ' + 'segment-title'"   id="textcolumn01" ref="textcolumn01"  v-bind:style="{color : titleColor,}">
<div class="text-content">{{ title }}</div>

   </div>
 <div  :class="'text textcolumn012 '"   id="textcolumn012" ref="textcolumn012"  v-bind:style="{color : infoColor,}">
<div class="text-content">{{ info }}</div>

   </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'SegmentContent',

    components: {},
	props: {
	
		title: {					required: false,			default: () =>  "abcCode",		},
		info: {					required: false,			default: () =>  "Lorem impus doloress",		},
		titleColor: {					required: false,			default: () =>  "#de3cff",		},
		infoColor: {					required: false,			default: () =>  "#b7b8c3",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SegmentContent{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.segment-title{
    text-shadow: 0 2px 2px rgb(0 0 0 / 15%);
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
width:100px!important ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

.text.textcolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

font-family:Comic Sans MS ; 
font-size:30px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;
}


}

.text.textcolumn012{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

font-family:Comic Sans MS ; 
font-size:22px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.text.textcolumn01{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textcolumn012{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.text.textcolumn01{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textcolumn012{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.text.textcolumn01{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textcolumn012{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.text.textcolumn01{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textcolumn012{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.text.textcolumn01{


position:relative !important; 





.text-content{
width: 100%;
}


}

.text.textcolumn012{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}
}
</style>
