<template>
 <div>  
<div id="Ep_ChatSection"> 
         
 <div  :class="'column chatSection2 ' + 'chatSection'"   id="chatSection2" ref="chatSection2"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'column columncolumnscrollarea02101 '"   id="columncolumnscrollarea02101" ref="columncolumnscrollarea02101"  v-bind:style="{}">
 <div  :class="'row rowcolumncolumnscrollarea021011 '"   id="rowcolumncolumnscrollarea021011" ref="rowcolumncolumnscrollarea021011"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'row rowrowcolumncolumnscrollarea0210111 '"   id="rowrowcolumncolumnscrollarea0210111" ref="rowrowcolumncolumnscrollarea0210111"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageitemcard0131 '"   id="networkimageitemcard0131" ref="networkimageitemcard0131"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "m_projectImage" 
>  </v-img>
 <div  :class="'column columncolumncard0312 '"   id="columncolumncard0312" ref="columncolumncard0312"  v-bind:style="{}">
 <div  :class="'text textcolumncolumncard0311 '"   id="textcolumncolumncard0311" ref="textcolumncolumncard0311"  v-bind:style="{color : '#585858',}">
<div class="text-content">{{ projectName }}</div>

   </div>
 <div  :class="'text textcolumncolumncard03112 '"   id="textcolumncolumncard03112" ref="textcolumncolumncard03112"  v-bind:style="{color : '#b8b8b8',}">
<div class="text-content">{{ ('par ' + creatorName) }}</div>

   </div>
 <div  :class="'row rowcolumncolumncard0315 '"   id="rowcolumncolumncard0315" ref="rowcolumncolumncard0315"  v-bind:style="{}">
 <div  :class="'icon iconrowcolumncolumncard03151 '"   id="iconrowcolumncolumncard03151" ref="iconrowcolumncolumncard03151"  v-bind:style="{color : '#b8b8b8',fontSize : getUnit(15,'px'),}"><i  :class="'ion-md-alarm'"></i></div>
 <div  :class="'text textcolumncolumncard031122 '"   id="textcolumncolumncard031122" ref="textcolumncolumncard031122"  v-bind:style="{color : '#b8b8b8',}">
<div class="text-content">{{ publishedTime }}</div>

   </div>  </div>  </div>  </div>
 <WorldButton :class="'object worldbuttonrowcolumncolumnscrollarea02105112 '"   id="worldbuttonrowcolumncolumnscrollarea02105112" ref="worldbuttonrowcolumncolumnscrollarea02105112"  v-bind:style="{}" :click = "unOderFollow" 
 :iconName = "'ion-md-person-add'" 
 :value = "'Suivre'" 
 :textColor = "(followed? '#88c440' : '#dfdfe7')" 
>
</WorldButton>  </div>
 <div  :class="'rectangle rectanglecolumncolumnscrollarea021013 ' + 'scaleHover'"   id="rectanglecolumncolumnscrollarea021013" ref="rectanglecolumncolumnscrollarea021013" v-if="(!connected)"  v-bind:style="{backgroundColor : '#eeedf0',}">
 <div  :class="'text textrectanglecolumncolumnscrollarea0210131 '"   id="textrectanglecolumncolumnscrollarea0210131" ref="textrectanglecolumncolumnscrollarea0210131"  v-bind:style="{color : '#66666b',}">
<div class="text-content">{{ 'Connecte toi pour laisser un commentaire..' }}</div>

   </div>
 <div  :class="'mousearea mousearearectanglecolumncolumnscrollarea0210133 '"   id="mousearearectanglecolumncolumnscrollarea0210133" ref="mousearearectanglecolumncolumnscrollarea0210133"  v-bind:style="{}"></div>  </div>
 <Ep_CommentPost :class="'object ep_commentpost '"   id="ep_commentpost" ref="ep_commentpost" v-if="connected"  v-bind:style="{}" :reloadComments = "getComments" 
 :userImage = "userImage" 
 :projectID = "projectID" 
>
</Ep_CommentPost>
 <Ep_Comment :class="'object ep_comment '"   id="ep_comment" ref="ep_comment" v-for="(item,index) in commentList" :key="'ep_comment' + index"  v-bind:style="{}" :comment = "item.value" 
 :userImage = "item.user.profile_image" 
 :userName = "item.user.username" 
 :publishedTime = "item.updated_at" 
 :numberLike = "item.likes" 
 :projectID = "projectID" 
 :commentID = "item.id" 
>
</Ep_Comment>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import WorldButton from '../../../At/Components/WorldButton'
import Ep_CommentPost from '../../../At/Components/Explore/Ep_CommentPost'
import Ep_Comment from '../../../At/Components/Explore/Ep_Comment'

import * as utils from '../../../Js/utils.js'
export default {
	name: 'Ep_ChatSection',

    components: {WorldButton,Ep_CommentPost,Ep_Comment,},
	methods: {
	
		getComments: function(){		
	let filter = {

		project: this.projectID,

		is_subcomment: false

	}

	utils.getComments(filter)

	.then((response)=>{

		this.commentList = response.data

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
		follow: function(){		
	let filter = {

		followed: this.creatorID,

		follower: this.$store.state.user.id

	}

	utils.follow(filter)

	.then((response)=>{

		this.followed = true

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
		unfollow: function(){		
	let filter = {

		followed: this.creatorID,

		follower: this.$store.state.user.id

	}

	utils.unfollow(filter)

	.then((response)=>{

		this.followed = true

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
		unOderFollow: function(){ 		
	if(this.user){

		if(this.followed){

			this.unfollow()

		} else {

			this.follow()

		}

	}

}
,
		checkIfFollowed: function(){ 		
	if(this.user){

		let filter = {

			followed: this.creatorID,

			follower: this.$store.state.user.id

		}

		utils.followers(filter)

		.then((response)=>{

			if(response.data.length > 0){

				this.followID = response.data[0].id

				this.followed = true

			}

		})

		.catch((error)=>{

		  console.log(error);

		});

	}

}
,
	},
	mounted(){
			
	},
	computed: {
	
		user: function(){		
	return this.$store.state.user

}
,
		m_projectImage: function() { 		
	if(this.projectImage){

		return this.$store.state.apiURL + this.projectImage.url

	} else {

		return "https://picsum.photos/200"

	}

}
,
		userImage: function(){		
	if(this.user && this.user.profile_image){

		return this.$store.state.apiURL + this.user.profile_image.url

	} else {

		return "https://picsum.photos/200"

	}

}
,
		connected: function(){		
	return (this.user? true : false)

}
,
	},
	watch: {
	
		projectID: function(val) { 		
	this.getComments()

}
,
		creatorID: function(val) { 		
	this.checkIfFollowed()

}
,
	},
	data(){
	return {
		
		commentList:  []		,
		followed:  false		,
		followID:  null		,
		}
	},
	props: {
	
		projectImage: {					required: false,			default: () =>  null,		},
		creatorName: {					required: false,			default: () =>  "dannick",		},
		publishedTime: {					required: false,			default: () =>  "11.21.2020",		},
		numberLike: {					required: false,			default: () =>  0,		},
		projectName: {					required: false,			default: () =>  "abcCode",		},
		projectID: {					required: false,			default: () =>  0,		},
		creatorID: {					required: false,			default: () =>  0,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Ep_ChatSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.scaleHover{
	&:hover{
    transform: scale(1.01);		
	}
}
	
.chatSection{
	padding-top: 22px !important;
	padding-bottom: 22px !important;
}



.column.chatSection2{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; 
background-color:#fff ; 

.column.columncolumnscrollarea02101{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 10px ; 

margin-right: 10px ; 
height:auto!important ; 
width: calc(100% - (10px + 10px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:20px ; background-color: transparent ; 

	.row.rowcolumncolumnscrollarea021011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	justify-content:space-between ; background-color:transparent ; 

		.row.rowrowcolumncolumnscrollarea0210111{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:15px ; background-color:transparent ; 

			.networkimage.networkimageitemcard0131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			width:40px!important ; 
			height:40px!important ; 
			
			display: inherit;

			flex: unset;
			border-radius:20px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			
}

			.column.columncolumncard0312{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-top: 0px ; 
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.textcolumncolumncard0311{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#585858 ; 
				font-size:22px ; 
				text-overflow: ellipsis ; 
				.text-content{
				width: 100%;
				white-space: nowrap;
				overflow: hidden ;
				text-overflow: ellipsis ; 
				font-weight: bold;
				}

				
}

				.text.textcolumncolumncard03112{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#b8b8b8 ; 
				font-size:15px ; 
				text-overflow: ellipsis ; 
				.text-content{
				width: 100%;
				white-space: nowrap;
				overflow: hidden ;
				text-overflow: ellipsis ; 
				font-weight: bold;
				}

				
}

				.row.rowcolumncolumncard0315{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;
				gap:5px ; background-color:transparent ; 

					.icon.iconrowcolumncolumncard03151{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					margin-top: 0px ; 
					width:20px!important ; 
					height:20px!important ; 
					
					color:#b8b8b8 ; 
					font-size:15px ; 
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.text.textcolumncolumncard031122{

					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-top: 0px ; 
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#b8b8b8 ; 
					font-size:15px ; 
					text-overflow: ellipsis ; 
					.text-content{
					width: 100%;
					white-space: nowrap;
					overflow: hidden ;
					text-overflow: ellipsis ; 
					font-weight: bold;
					}

					
}

				
}

			
}

		
}

		.object.worldbuttonrowcolumncolumnscrollarea02105112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:118px!important ; 
		height:48px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolumncolumnscrollarea021013{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:92px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	background-color:#eeedf0 ; 
	border-radius:20px ; 
	border-style:solid ; 
	border-width:1px ; 
	border-color:#a7a7a7 ; 

		.text.textrectanglecolumncolumnscrollarea0210131{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left: 30px ; 
		
		top: calc(50% + 0px) ; 
		right: 30px ; 
		height:auto!important ; 
		
		color:#66666b ; 
		font-size:20px ; 
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		}

		
}

		.mousearea.mousearearectanglecolumncolumnscrollarea0210133{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		cursor: pointer;
		}

	
}

	.object.ep_commentpost{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.ep_comment{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}


@media (max-width: 600px) {


.column.chatSection2{


position:relative !important; 









.column.columncolumnscrollarea02101{


position:relative !important; 









	.row.rowcolumncolumnscrollarea021011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.row.rowrowcolumncolumnscrollarea0210111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimageitemcard0131{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.column.columncolumncard0312{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolumncard0311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolumncard03112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.rowcolumncolumncard0315{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.icon.iconrowcolumncolumncard03151{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.text.textcolumncolumncard031122{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.object.worldbuttonrowcolumncolumnscrollarea02105112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolumncolumnscrollarea021013{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectanglecolumncolumnscrollarea0210131{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.mousearea.mousearearectanglecolumncolumnscrollarea0210133{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.object.ep_commentpost{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.ep_comment{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 601px) {


.column.chatSection2{


position:relative !important; 









.column.columncolumnscrollarea02101{


position:relative !important; 









	.row.rowcolumncolumnscrollarea021011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.row.rowrowcolumncolumnscrollarea0210111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimageitemcard0131{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.column.columncolumncard0312{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolumncard0311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolumncard03112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.rowcolumncolumncard0315{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.icon.iconrowcolumncolumncard03151{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.text.textcolumncolumncard031122{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.object.worldbuttonrowcolumncolumnscrollarea02105112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolumncolumnscrollarea021013{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectanglecolumncolumnscrollarea0210131{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.mousearea.mousearearectanglecolumncolumnscrollarea0210133{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.object.ep_commentpost{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.ep_comment{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 961px) {


.column.chatSection2{


position:relative !important; 









.column.columncolumnscrollarea02101{


position:relative !important; 



margin-left: 18% ; 

margin-right: 18% ; 
width: calc(100% - (18% + 18%)) !important;





	.row.rowcolumncolumnscrollarea021011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.row.rowrowcolumncolumnscrollarea0210111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimageitemcard0131{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.column.columncolumncard0312{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolumncard0311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolumncard03112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.rowcolumncolumncard0315{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.icon.iconrowcolumncolumncard03151{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.text.textcolumncolumncard031122{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.object.worldbuttonrowcolumncolumnscrollarea02105112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolumncolumnscrollarea021013{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectanglecolumncolumnscrollarea0210131{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.mousearea.mousearearectanglecolumncolumnscrollarea0210133{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.object.ep_commentpost{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.ep_comment{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1265px) {


.column.chatSection2{


position:relative !important; 









.column.columncolumnscrollarea02101{


position:relative !important; 









	.row.rowcolumncolumnscrollarea021011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.row.rowrowcolumncolumnscrollarea0210111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimageitemcard0131{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.column.columncolumncard0312{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolumncard0311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolumncard03112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.rowcolumncolumncard0315{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.icon.iconrowcolumncolumncard03151{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.text.textcolumncolumncard031122{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.object.worldbuttonrowcolumncolumnscrollarea02105112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolumncolumnscrollarea021013{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectanglecolumncolumnscrollarea0210131{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.mousearea.mousearearectanglecolumncolumnscrollarea0210133{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.object.ep_commentpost{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.ep_comment{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1905px) {


.column.chatSection2{


position:relative !important; 









.column.columncolumnscrollarea02101{


position:relative !important; 









	.row.rowcolumncolumnscrollarea021011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.row.rowrowcolumncolumnscrollarea0210111{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.networkimageitemcard0131{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.column.columncolumncard0312{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumncolumncard0311{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumncolumncard03112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.row.rowcolumncolumncard0315{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.icon.iconrowcolumncolumncard03151{

					
					position:relative !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.text.textcolumncolumncard031122{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

		.object.worldbuttonrowcolumncolumnscrollarea02105112{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolumncolumnscrollarea021013{

	
	position:relative !important; 
	
	
	
	
	
	

		.text.textrectanglecolumncolumnscrollarea0210131{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.mousearea.mousearearectanglecolumncolumnscrollarea0210133{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.object.ep_commentpost{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.ep_comment{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}
}
</style>
