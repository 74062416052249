<template>
 <div>  
<div id="LunchZone"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectangleitem011 '"   id="mousearearectangleitem011" ref="mousearearectangleitem011"  v-bind:style="{}" @click="close"></div>  </div>
 <LunchBar :class="'object lunchBar2 '"   id="lunchBar2" ref="lunchBar2"  v-bind:style="{}">
</LunchBar>  </div>
 
      </div>
 </div> 
</template>
<script>
import LunchBar from '../../At/Components/LunchBar'


export default {
	name: 'LunchZone',

    components: {LunchBar,},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LunchZone{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.5 ;  filter: alpha(opacity=50) ; 

background-color:#000 ; 

	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.object.lunchBar2{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
height:auto!important ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.object.lunchBar2{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.object.lunchBar2{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.object.lunchBar2{


position:absolute !important; 




height:230px!important ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.object.lunchBar2{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.object.lunchBar2{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
