<template>
 <div>  
<div id="NotificationZone"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectangleitem011 '"   id="mousearearectangleitem011" ref="mousearearectangleitem011"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'transition transitionitem03 '"   id="transitionitem03" ref="transitionitem03"  v-bind:style="{}">
 <transition  :enter-active-class = "'animated swing'" 
>
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0" v-if="notifBoxVisible"  v-bind:style="{}">
 <div  :class="'column columncardtransitionitem0311 '"   id="columncardtransitionitem0311" ref="columncardtransitionitem0311"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textcolumncardtransitionitem03111 '"   id="textcolumncardtransitionitem03111" ref="textcolumncardtransitionitem03111"  v-bind:style="{color : '#4b4c50',}">
<div class="text-content">{{ 'Notifications' }}</div>

   </div>
 <v-card 
 :class="'card card1 '"   id="card1" ref="card1"  v-bind:style="{}">
 <v-img  :class="'image imagecardcolumncardtransitionitem031131 '"   id="imagecardcolumncardtransitionitem031131" ref="imagecardcolumncardtransitionitem031131"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/background/4864484.jpg')" 
>  </v-img>
 <div  :class="'rectangle rectanglecardcolumncardtransitionitem031133 '"   id="rectanglecardcolumncardtransitionitem031133" ref="rectanglecardcolumncardtransitionitem031133"  v-bind:style="{backgroundColor : '#ff4d2e',}">
 <div  :class="'text textrectanglecardcolumncardtransitionitem0311331 '"   id="textrectanglecardcolumncardtransitionitem0311331" ref="textrectanglecardcolumncardtransitionitem0311331"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ 'Nouveau' }}</div>

   </div>  </div>
 <v-btn tag="div" 
 :rounded = "true" 
 :class="'button buttoncardcolumncardtransitionitem031135 ' + 'textButton'"   id="buttoncardcolumncardtransitionitem031135" ref="buttoncardcolumncardtransitionitem031135"  v-bind:style="{}"><span>{{ 'Nouvel éditeur' }}</span>
</v-btn>
 <div  :class="'rectangle rectanglecard074 '"   id="rectanglecard074" ref="rectanglecard074"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'flow flowrectanglecard071 '"   id="flowrectanglecard071" ref="flowrectanglecard071"  v-bind:style="{}">
 <div  :class="'text textflowrectanglecard0711 '"   id="textflowrectanglecard0711" ref="textflowrectanglecard0711"  v-bind:style="{color : '#0bdeff',}">
<div class="text-content">{{ 'C' }}</div>

   </div>
 <div  :class="'text textflowrectanglecard07112 '"   id="textflowrectanglecard07112" ref="textflowrectanglecard07112"  v-bind:style="{color : '#cd3de7',}">
<div class="text-content">{{ 'L' }}</div>

   </div>
 <div  :class="'text textflowrectanglecard07113 '"   id="textflowrectanglecard07113" ref="textflowrectanglecard07113"  v-bind:style="{color : '#ff2081',}">
<div class="text-content">{{ 'U' }}</div>

   </div>
 <div  :class="'text textflowrectanglecard07114 '"   id="textflowrectanglecard07114" ref="textflowrectanglecard07114"  v-bind:style="{color : '#ffba0c',}">
<div class="text-content">{{ 'B' }}</div>

   </div>  </div>  </div>
 <div  :class="'mousearea mouseareacard19 '"   id="mouseareacard19" ref="mouseareacard19"  v-bind:style="{}"></div>  </v-card>
 <div  :class="'text textcolumncardtransitionitem031113 '"   id="textcolumncardtransitionitem031113" ref="textcolumncardtransitionitem031113"  v-bind:style="{color : '#4b4c50',}">
<div class="text-content">{{ 'Vous n\' avez aucune notification' }}</div>

   </div>  </div>  </v-card>  </transition></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'NotificationZone',

    components: {},
	mounted(){
			
	setTimeout(()=>{ this.notifBoxVisible = true }, 1000);

	},
	data(){
	return {
		
		notifBoxVisible:  true		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  (e=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#NotificationZone{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.textButton{
	padding: 10px !important;
}

#card1{
	&:hover{
    transform: scale(1.01);
	}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.5 ;  filter: alpha(opacity=50) ; 

background-color:#000 ; 

	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.transition.transitionitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: 50px ; 
right: 20px ; 
width:315.75px!important ; 
height:224px!important ; 


	.card.card0{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:312px!important ; 
	height:220px!important ; 
	background-color:#ffffff ; 
	border-radius:8px ; max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 12px ; 
		
		top: 12px ; 
		right: 12px ; 
		bottom: 12px ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		justify-content:space-between ; background-color: transparent ; 

			.text.textcolumncardtransitionitem03111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#4b4c50 ; 
			font-size:18px ; 
			.text-content{
			width: 100%;
			}

			
}

			.card.card1{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			width:286px!important ; 
			height:126px!important ; 
			background-color:#ffffff ; 
			border-radius:8px ; max-width: initial;min-width: initial;

				.image.imagecardcolumncardtransitionitem031131{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

				
}

				.rectangle.rectanglecardcolumncardtransitionitem031133{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 10px ; 
				
				top: 10px ; 
				width:100px!important ; 
				height:27px!important ; 
				
				background-color:#ff4d2e ; 
				border-radius:14px ; 
				border-style:solid ; 
				border-width:2px ; 
				border-color:#fff ; 

					.text.textrectanglecardcolumncardtransitionitem0311331{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 3px ; 
					
					top: 3px ; 
					right: 3px ; 
					bottom: 3px ; 
					
					color:#fff ; 
					font-size:15px ; 
					text-align:center;
					align-items:center;
					display: flex;
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

				
}

				.button.buttoncardcolumncardtransitionitem031135{

				height: auto;
				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 10px ; 
				
				bottom: 10px ; 
				width:auto!important ; 
				height:37px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

				.rectangle.rectanglecard074{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				top: -5px ; 
				right: 10px ; 
				width:48px!important ; 
				height:60px!important ; 
				
				background-color:#000 ; 
				border-radius:8px ; 

					.flow.flowrectanglecard071{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 5px ; 
					
					top: 10px ; 
					right: 5px ; 
					bottom: 5px ; 
					
					display: flex;
					flex-wrap: wrap;
					flex: initial;background-color:transparent ; 

						.text.textflowrectanglecard0711{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left:0px ; 
						
						margin-top: 0px ; 
						width:50%!important ; 
						height:50%!important ; 
						
						color:#0bdeff ; 
						font-size:20px ; 
						text-align:center;
						align-items:center;
						display: flex;
						.text-content{
						width: 100%;
						font-weight: bold;
						}

						
}

						.text.textflowrectanglecard07112{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left:0px ; 
						
						margin-top: 0px ; 
						width:50%!important ; 
						height:50%!important ; 
						
						color:#cd3de7 ; 
						font-size:20px ; 
						text-align:center;
						align-items:center;
						display: flex;
						.text-content{
						width: 100%;
						font-weight: bold;
						}

						
}

						.text.textflowrectanglecard07113{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left:0px ; 
						
						margin-top: 0px ; 
						width:50%!important ; 
						height:50%!important ; 
						
						color:#ff2081 ; 
						font-size:20px ; 
						text-align:center;
						align-items:center;
						display: flex;
						.text-content{
						width: 100%;
						font-weight: bold;
						}

						
}

						.text.textflowrectanglecard07114{

						
						position:relative !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left:0px ; 
						
						margin-top: 0px ; 
						width:50%!important ; 
						height:50%!important ; 
						
						color:#ffba0c ; 
						font-size:20px ; 
						text-align:center;
						align-items:center;
						display: flex;
						.text-content{
						width: 100%;
						font-weight: bold;
						}

						
}

					
}

				
}

				.mousearea.mouseareacard19{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				cursor: pointer;
				}

			
}

			.text.textcolumncardtransitionitem031113{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#4b4c50 ; 
			font-size:18px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.transition.transitionitem03{


position:absolute !important; 






	.card.card0{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncardtransitionitem03111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.card.card1{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.image.imagecardcolumncardtransitionitem031131{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.rectanglecardcolumncardtransitionitem031133{

				
				position:absolute !important; 
				
				
				
				
				
				

					.text.textrectanglecardcolumncardtransitionitem0311331{

					
					position:absolute !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoncardcolumncardtransitionitem031135{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.rectangle.rectanglecard074{

				
				position:absolute !important; 
				
				
				
				
				
				

					.flow.flowrectanglecard071{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.text.textflowrectanglecard0711{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07112{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07113{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07114{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

				
}

				.mousearea.mouseareacard19{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.text.textcolumncardtransitionitem031113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.transition.transitionitem03{


position:absolute !important; 






	.card.card0{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncardtransitionitem03111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.card.card1{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.image.imagecardcolumncardtransitionitem031131{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.rectanglecardcolumncardtransitionitem031133{

				
				position:absolute !important; 
				
				
				
				
				
				

					.text.textrectanglecardcolumncardtransitionitem0311331{

					
					position:absolute !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoncardcolumncardtransitionitem031135{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.rectangle.rectanglecard074{

				
				position:absolute !important; 
				
				
				
				
				
				

					.flow.flowrectanglecard071{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.text.textflowrectanglecard0711{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07112{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07113{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07114{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

				
}

				.mousearea.mouseareacard19{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.text.textcolumncardtransitionitem031113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.transition.transitionitem03{


position:absolute !important; 






	.card.card0{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncardtransitionitem03111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.card.card1{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.image.imagecardcolumncardtransitionitem031131{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.rectanglecardcolumncardtransitionitem031133{

				
				position:absolute !important; 
				
				
				
				
				
				

					.text.textrectanglecardcolumncardtransitionitem0311331{

					
					position:absolute !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoncardcolumncardtransitionitem031135{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.rectangle.rectanglecard074{

				
				position:absolute !important; 
				
				
				
				
				
				

					.flow.flowrectanglecard071{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.text.textflowrectanglecard0711{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07112{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07113{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07114{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

				
}

				.mousearea.mouseareacard19{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.text.textcolumncardtransitionitem031113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.transition.transitionitem03{


position:absolute !important; 






	.card.card0{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncardtransitionitem03111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.card.card1{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.image.imagecardcolumncardtransitionitem031131{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.rectanglecardcolumncardtransitionitem031133{

				
				position:absolute !important; 
				
				
				
				
				
				

					.text.textrectanglecardcolumncardtransitionitem0311331{

					
					position:absolute !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoncardcolumncardtransitionitem031135{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.rectangle.rectanglecard074{

				
				position:absolute !important; 
				
				
				
				
				
				

					.flow.flowrectanglecard071{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.text.textflowrectanglecard0711{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07112{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07113{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07114{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

				
}

				.mousearea.mouseareacard19{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.text.textcolumncardtransitionitem031113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.transition.transitionitem03{


position:absolute !important; 






	.card.card0{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumncardtransitionitem03111{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.card.card1{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.image.imagecardcolumncardtransitionitem031131{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.rectanglecardcolumncardtransitionitem031133{

				
				position:absolute !important; 
				
				
				
				
				
				

					.text.textrectanglecardcolumncardtransitionitem0311331{

					
					position:absolute !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoncardcolumncardtransitionitem031135{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.rectangle.rectanglecard074{

				
				position:absolute !important; 
				
				
				
				
				
				

					.flow.flowrectanglecard071{

					
					position:absolute !important; 
					
					
					
					
					
					
					
					

						.text.textflowrectanglecard0711{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07112{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07113{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

						.text.textflowrectanglecard07114{

						
						position:relative !important; 
						
						
						
						
						
						.text-content{
						width: 100%;
						}

						
}

					
}

				
}

				.mousearea.mouseareacard19{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.text.textcolumncardtransitionitem031113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}
}
</style>
