<template>
 <div>  
<div id="MainBanner"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : '#414a51',}">
 <div  :class="'item itemrectangleitem011 '"   id="itemrectangleitem011" ref="itemrectangleitem011"  v-bind:style="{}">
 <v-img  :class="'image imageitemrectangleitem011143 '"   id="imageitemrectangleitem011143" ref="imageitemrectangleitem011143"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + miniLogo + '')" 
>  </v-img>
 <div  :class="'column columnitemrectangleitem0113 '"   id="columnitemrectangleitem0113" ref="columnitemrectangleitem0113"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textcolumnitemrectangleitem01131 '"   id="textcolumnitemrectangleitem01131" ref="textcolumnitemrectangleitem01131"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ title }}</div>

   </div>
 <div  :class="'text textcolumnitemrectangleitem01133 '"   id="textcolumnitemrectangleitem01133" ref="textcolumnitemrectangleitem01133"  v-bind:style="{color : '#9a9ca7',}">
<div class="text-content">{{ text }}</div>

   </div>
 <div  :class="'row rowcolumnitemrectangleitem01135 '"   id="rowcolumnitemrectangleitem01135" ref="rowcolumnitemrectangleitem01135"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#8e969d'" 
 :rounded = "true" 
 :dark = "true" 
 @click="bannerAction" :class="'button buttonrowcolumnitemrectangleitem011351 ' + 'padded'"   id="buttonrowcolumnitemrectangleitem011351" ref="buttonrowcolumnitemrectangleitem011351"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-ios-play' !== undefined"  :left = "true" 
 :color = "'#ffdb0f'" 
>{{ 'ion-ios-play' }}
</v-icon><span>{{ buttonText }}</span>
</v-btn>  </div>  </div>
 <div  :class="'transitiongroup transitiongroupitemrectangleitem0115 ' + 'autoplay'"   id="transitiongroupitemrectangleitem0115" ref="transitiongroupitemrectangleitem0115"  v-bind:style="{}">
 <transition-group >
 <v-card 
 :class="'card cardtransitiongroupitemrectangleitem01151 '"   id="cardtransitiongroupitemrectangleitem01151" ref="cardtransitiongroupitemrectangleitem01151" v-for="(item,index) in numberImages" :key="'cardtransitiongroupitemrectangleitem01151' + index"  v-bind:style="{right : getUnit(((index+1)*20),'px'),bottom : getUnit(((index+1)*20),'px'),}">
 <v-img  :class="'networkimage imagecardtransitiongroupitemrectangleitem011511 '"   id="imagecardtransitiongroupitemrectangleitem011511" ref="imagecardtransitiongroupitemrectangleitem011511"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "m_imageList[index]" 
>  </v-img>  </v-card>  </transition-group></div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as Main from '../../Js/main.js'
export default {
	name: 'MainBanner',

    components: {},
	computed: {
	
		numberImages: function() { 		
	if(this.imageList.length > 4){

		return 4

	} else {

		return this.imageList.length

	}

}
,
		m_imageList: function() { 		
	return this.imageList.map(i => {

		return Main.getImgUrl({image: i})

	})

}
,
	},
	props: {
	
		miniLogo: {					required: false,			default: () =>  "Resources/abcCode_Logo_N_G_transparent.png",		},
		title: {					required: false,			default: () =>  "abcCode",		},
		text: {					required: false,			default: () =>  "Learn coding fundamentals. Simple blocks become powerful Javascript apps. You don’t need coding knowledge to start!",		},
		buttonText: {					required: false,			default: () =>  "Ouvrir l' éditeur",		},
		bannerAction: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		imageList: {					required: false,			default: () =>  [],		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#MainBanner{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.padded{
	padding: 16px !important;
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 5px ; 

top: 5px ; 
right: 5px ; 
bottom: 5px ; 

background-color:#414a51 ; 
border-radius:30px ; 

	.item.itemrectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 38px ; 
	
	top: 38px ; 
	right: 38px ; 
	bottom: 38px ; 
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		top: 0px ; 
		width:56px!important ; 
		height:56px!important ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		top: 70px ; 
		bottom: 0px ; 
		width:35%!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:15px ; 
		justify-content:space-between ; background-color: transparent ; 

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#fff ; 
			font-size:35px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			font-weight: bold;
			}

			
}

			.text.textcolumnitemrectangleitem01133{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#9a9ca7 ; 
			font-size:15px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			}

			
}

			.row.rowcolumnitemrectangleitem01135{

			align-self: flex-start;
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			width:auto!important ; 
			height:40px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;background-color:transparent ; 

				.button.buttonrowcolumnitemrectangleitem011351{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				width:auto!important ; 
				height:40px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

			
}

		
}

		.transitiongroup.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		 display:block !important; 
		width:50%!important ; 
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:370px!important ; 
			height:277px!important ; 
			background-color:#ffffff ; 
			border-radius:10px ; max-width: initial;min-width: initial;

				.networkimage.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: inherit;

				flex: unset;
				border-radius:10px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		width:100%!important ; 
		
		
		
		

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textcolumnitemrectangleitem01133{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumnitemrectangleitem01135{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrowcolumnitemrectangleitem011351{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.transitiongroup.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		display:none !important; 
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textcolumnitemrectangleitem01133{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumnitemrectangleitem01135{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrowcolumnitemrectangleitem011351{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.transitiongroup.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textcolumnitemrectangleitem01133{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumnitemrectangleitem01135{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrowcolumnitemrectangleitem011351{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.transitiongroup.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textcolumnitemrectangleitem01133{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumnitemrectangleitem01135{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrowcolumnitemrectangleitem011351{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.transitiongroup.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.item.itemrectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	

		.image.imageitemrectangleitem011143{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.column.columnitemrectangleitem0113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textcolumnitemrectangleitem01131{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textcolumnitemrectangleitem01133{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.row.rowcolumnitemrectangleitem01135{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonrowcolumnitemrectangleitem011351{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.transitiongroup.transitiongroupitemrectangleitem0115{

		
		position:absolute !important; 
		
		
		
		
		

			.card.cardtransitiongroupitemrectangleitem01151{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.networkimage.imagecardtransitiongroupitemrectangleitem011511{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
