<template>
 <div>  
<div id="ActionBanner"> 
         
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0"  v-bind:style="{backgroundColor : '#4e66f8',}">
 <v-img  :class="'image imagecard01 '"   id="imagecard01" ref="imagecard01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + bgImg1 + '')" 
>  </v-img>
 <div  :class="'item itemcard03 '"   id="itemcard03" ref="itemcard03"  v-bind:style="{}">
 <v-img  :class="'image imageitemcard031 ' + 'topImg'"   id="imageitemcard031" ref="imageitemcard031"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + bgImg2 + '')" 
>  </v-img>
 <Logo :class="'object logoitemcard033 '"   id="logoitemcard033" ref="logoitemcard033"  v-bind:style="{}" :bgColor = "'#000'" 
 :padding = "'10'" 
 :radius = "'8'" 
>
</Logo>  </div>
 <div  :class="'column itemcard05 '"   id="itemcard05" ref="itemcard05"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textitemcard053 '"   id="textitemcard053" ref="textitemcard053"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ title }}</div>

   </div>
 <div  :class="'text textitemcard0533 '"   id="textitemcard0533" ref="textitemcard0533"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ message }}</div>

   </div>
 <v-btn tag="div" 
 :color = "buttonColor" 
 :rounded = "true" 
 :dark = "true" 
 @click="buttonAction" :class="'button buttonitemcard051 ' + 'padded'"   id="buttonitemcard051" ref="buttonitemcard051"  v-bind:style="{}"><span>{{ buttonValue }}</span>
</v-btn>  </div>  </v-card>
 
      </div>
 </div> 
</template>
<script>
import Logo from '../../At/Components/Logo'


export default {
	name: 'ActionBanner',

    components: {Logo,},
	props: {
	
		bgImg1: {					required: false,			default: () =>  "Resources/background/0112419d011ec5139aa01521350ec49a.png",		},
		bgImg2: {					required: false,			default: () =>  "Resources/background/5b1bb23e40b160b72c0ba3b342993dbd.jpg",		},
		title: {					required: false,			default: () =>  "Join the club",		},
		message: {					required: false,			default: () =>  "First, try the tools. Then, become a pro. Kano Club members get unique challenges, video training, and upgrades in every part of the studio.",		},
		buttonValue: {					required: false,			default: () =>  "Join the club",		},
		buttonColor: {					required: false,			default: () =>  "#ff6900",		},
		buttonAction: {					required: false,			default: () =>  (() => {}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ActionBanner{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.padded{
	padding: 16px !important;
}
.topImg{
    border-radius: 20px 20px 0 0;
}



.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 5px ; 

top: 5px ; 
right: 5px ; 
bottom: 5px ; 

background-color:#4e66f8 ; 
border-radius:20px ; max-width: initial;min-width: initial;

.image.imagecard01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: inherit;

flex: unset;
border-radius:20px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.item.itemcard03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
height:156px!important ; 


	.image.imageitemcard031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}

	.object.logoitemcard033{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	bottom: -30px ; 
	width:100px!important ; 
	height:100px!important ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.column.itemcard05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 60px ; 

top: 200px ; 
right: 60px ; 
bottom: 40px ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
justify-content:space-between ; background-color: transparent ; 

	.text.textitemcard053{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:50px!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	color:#fff ; 
	font-size:35px ; 
	text-align:center;
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}

	.text.textitemcard0533{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 55px ; 
	margin-right: 0px ; 
	margin-bottom: 55px ; 
	width: calc(100% - (0px + 0px)) !important;
	height: calc(100% - (55px + 55px)) !important;
	
	color:#fff ; 
	font-size:18px ; 
	text-align:center;
	align-items:center;
	display: flex;
	text-overflow: ellipsis ; 
	.text-content{
	width: 100%;
	overflow: hidden ;
	text-overflow: ellipsis ; 
	font-weight: bold;
	}

	
}

	.button.buttonitemcard051{

	height: auto;
	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	margin-bottom: 0px ; 
	width:auto!important ; 
	height:50px!important ; 
	
	text-transform:unset ; 
	min-width: auto;
	padding-left: 16px;
	padding-right: 16px;

	i.v-icon{
	}
	}


}


}


@media (max-width: 600px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.image.imagecard01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.item.itemcard03{


position:absolute !important; 






	.image.imageitemcard031{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.object.logoitemcard033{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.column.itemcard05{


position:absolute !important; 









	.text.textitemcard053{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textitemcard0533{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttonitemcard051{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 601px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.image.imagecard01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.item.itemcard03{


position:absolute !important; 






	.image.imageitemcard031{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.object.logoitemcard033{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.column.itemcard05{


position:absolute !important; 









	.text.textitemcard053{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textitemcard0533{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttonitemcard051{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 961px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.image.imagecard01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.item.itemcard03{


position:absolute !important; 






	.image.imageitemcard031{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.object.logoitemcard033{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.column.itemcard05{


position:absolute !important; 









	.text.textitemcard053{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textitemcard0533{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttonitemcard051{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1265px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.image.imagecard01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.item.itemcard03{


position:absolute !important; 






	.image.imageitemcard031{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.object.logoitemcard033{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.column.itemcard05{


position:absolute !important; 









	.text.textitemcard053{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textitemcard0533{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttonitemcard051{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1905px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.image.imagecard01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.item.itemcard03{


position:absolute !important; 






	.image.imageitemcard031{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.object.logoitemcard033{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.column.itemcard05{


position:absolute !important; 









	.text.textitemcard053{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textitemcard0533{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.button.buttonitemcard051{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}
}
</style>
