<template>
 <div>  
<div id="ProfileCreation"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'row rowcolumn01 '"   id="rowcolumn01" ref="rowcolumn01"  v-bind:style="{}">
 <div  :class="'icon iconrowcolumn011 '"   id="iconrowcolumn011" ref="iconrowcolumn011"  v-bind:style="{color : '#5f5f5f',fontSize : getUnit(25,'px'),}"><i  :class="'ion-md-apps'"></i></div>
 <div  :class="'text textrowcolumn013 '"   id="textrowcolumn013" ref="textrowcolumn013"  v-bind:style="{color : '#5f5f5f',}">
<div class="text-content">{{ 'Mes crétations' }}</div>

   </div>  </div>
 <Ep_Filter :class="'object ep_filtercolumncolumnscrollarea021035 '"   id="ep_filtercolumncolumnscrollarea021035" ref="ep_filtercolumncolumnscrollarea021035"  v-bind:style="{}" :showAll = "togelLiked" 
 :showLiked = "togelLiked" 
>
</Ep_Filter>
 <v-card 
 :class="'card cardcolumn03 '"   id="cardcolumn03" ref="cardcolumn03" v-if="(m_projectsCount == 0)"  v-bind:style="{}">
 <div  :class="'column columncardcolumn031 '"   id="columncardcolumn031" ref="columncardcolumn031"  v-bind:style="{justifyContent : 'space-around',}">
 <div  :class="'text textrowcolumn0131 '"   id="textrowcolumn0131" ref="textrowcolumn0131"  v-bind:style="{color : '#5f5f5f',}">
<div class="text-content">{{ 'Vous n\'avez encore partagé aucune création' }}</div>

   </div>
 <v-btn tag="div" 
 :color = "'#ff6b00'" 
 :rounded = "true" 
 :dark = "true" 
 :class="'button button ' + 'textButton'"   id="button" ref="button"  v-bind:style="{}"><span>{{ 'Défis' }}</span>
</v-btn>  </div>  </v-card>
 <v-row  :class="'grid gridcolumncolumnscrollarea021053 '"   id="gridcolumncolumnscrollarea021053" ref="gridcolumncolumnscrollarea021053"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumnscrollarea021051 '"   id="colgridcolumncolumnscrollarea021051" ref="colgridcolumncolumnscrollarea021051" v-for="(item,index) in projectList" :key="'colgridcolumncolumnscrollarea021051' + index"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
 :sm = "6" 
><div class="col-container">
 <ShareCard :class="'object sharecardcolgridcolumncolumnscrollarea0210511 '"   id="sharecardcolgridcolumncolumnscrollarea0210511" ref="sharecardcolgridcolumncolumnscrollarea0210511"  v-bind:style="{}" :like = "likeProject" 
 :dislike = "dislikeProject" 
 :href = "('/explore/'+ item.id)" 
 :item = "item" 
 :liked = "checkIfPjWasLiked(item.id)" 
 :projectId = "item.id" 
>
</ShareCard></div></v-col>  </v-row>
 <v-pagination 
 :color = "'#ff6b00'" 
 :length = "projectsCount" 
 :circle = "true" 
 :class="'pagination paginationcolumncolumnscrollarea021074 '"   id="paginationcolumncolumnscrollarea021074" ref="paginationcolumncolumnscrollarea021074" v-model="currentPage"  v-bind:style="{}"></v-pagination>  </div>
 
      </div>
 </div> 
</template>
<script>
import Ep_Filter from '../../../At/Components/Explore/Ep_Filter'
import ShareCard from '../../../At/Components/Explore/ShareCard'

import * as utils from '../../../Js/utils.js'
export default {
	name: 'ProfileCreation',

    components: {Ep_Filter,ShareCard,},
	methods: {
	
		getProject: function() { 		
	let filter2 = {

		_start: (this.currentPage - 1)* this.maxItemPerPage,

		_limit: this.maxItemPerPage,

		user: this.targetId

	}

	utils.getProjects(filter2)

	.then((response)=>{

		this.projectList = response.data.map(e => {

			e.description_image.url = this.$store.state.apiURL + e.description_image.url

			return e

		})

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
		getLikedProject: function() { 		
	let list = this.likedProjectList.map((p)=>{

		return p.project.id

	})

	

	let filter2 = {

		_start: (this.currentPage - 1)* this.maxItemPerPage,

		_limit: this.maxItemPerPage,

		user: this.targetId,

		_where: {

			id: list

		}

	}

	utils.getProjects(filter2)

	.then((response)=>{

		this.projectList = response.data.map(e => {

			e.description_image.url = this.$store.state.apiURL + e.description_image.url

			return e

		})

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
		getLikedProjectList: function() { 		
	let filter2 = {

		user: this.targetId

	}

	utils.getLikedProjects(filter2)

	.then((response)=>{

		this.likedProjectList = response.data

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
		checkIfPjWasLiked: function(pjId){ 		
	let list = this.likedProjectList.map((p)=>{

			return p.project.id

		})

	return list.includes(pjId)

}
,
		likeProject: function(pjId){ 		
	let filter = {

		user: this.targetId,

		project: pjId

	}

	utils.likeProject(filter)

	.catch((error)=>{

	  console.log(error);

	});

}
,
		dislikeProject: function(pjId){ 		
	let list = this.likedProjectList.filter((p)=>{

			return (p.project.id == pjId)

	})

	utils.dislikeProject(list[0].id)

	.catch((error)=>{

	  console.log(error);

	});

}
,
		togelLiked: function() { 		
	if(this.likedMode){

		this.getProject()

	} else {

		this.getLikedProjectList()

		this.getLikedProject()

	}

	this.likedMode = !this.likedMode

}
,
	},
	mounted(){
			
	// Get images from api

	utils.getImages()

	.then((response)=>{

	  this.imagesList = response.data.filter(e => {

			return (e.mime.match("image/") != null)

		});

		this.imagesList = this.imagesList.map(e => {

			e.url = this.$store.state.apiURL + e.url

			return e

		})

	})

	.catch((error)=>{

	  console.log(error);

	});

	

	// Get count of project

	let filter = {

		user: this.targetId

	}

	utils.getCount('projects', filter)

	.then((response) => {

		this.m_projectsCount = Math.ceil(response.data/this.maxItemPerPage)

	})

	.catch(error => console.log(error))

	

	// Get the list of projects

	this.getProject()

	

	// Get liked projects

	this.getLikedProjectList()

	},
	computed: {
	
		mixedImagesList: function(){		
	return utils.mixed(this.imagesList)

}
,
		projectsCount: function() { 			
	return (this.likedMode?

		Math.ceil(this.projectList.length/this.maxItemPerPage) :

		this.m_projectsCount

	)

}
,
	},
	watch: {
	
		currentPage: function(newVal){ 		
	this.getProject()

}
,
	},
	data(){
	return {
		
		imagesList:  []		,
		titleList:  ["Des défis", "Des concours", "Des tutoriels"]		,
		textList:  [		
	"Résolvez des défis tous les jours et augmentez votre rang dans le classement",

	"Participez à des concours et gagnez des lots et prix.",

	"Entrenez vous et augmentez vos compétances graces à de nombreux tutoriels."	

]
,
		projectList:  []		,
		m_projectsCount:  0		,
		likedProjectList:  []		,
		currentPage:  1		,
		maxItemPerPage:  6		,
		likedMode:  false		,
		}
	},
	props: {
	
		targetId: {					required: false,			default: () =>  0,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ProfileCreation{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.textButton{
	padding: 10px !important;
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

.row.rowcolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:50px!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;background-color:transparent ; 

	.icon.iconrowcolumn011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	width:50px!important ; 
	height:50px!important ; 
	
	color:#5f5f5f ; 
	font-size:25px ; 
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrowcolumn013{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:auto!important ; 
	
	color:#5f5f5f ; 
	font-size:20px ; 
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}


}

.object.ep_filtercolumncolumnscrollarea021035{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.card.cardcolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:120px!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color:#ffffff ; 
border-radius:8px ; max-width: initial;min-width: initial;

	.column.columncardcolumn031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 10px ; 
	
	top: 10px ; 
	right: 10px ; 
	bottom: 10px ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	justify-content:space-around ; background-color: transparent ; 

		.text.textrowcolumn0131{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#5f5f5f ; 
		font-size:20px ; 
		text-align:center;
		display: flex;
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		font-weight: bold;
		}

		
}

		.button.button{

		height: auto;
		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
		
		left: calc(50% + 0px) ; 
		
		width:auto!important ; 
		height:25px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

	
}


}

.grid.gridcolumncolumnscrollarea021053{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumncolumnscrollarea021051{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	width:100px!important ; 
	height:350px!important ; 
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.sharecardcolgridcolumncolumnscrollarea0210511{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationcolumncolumnscrollarea021074{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:36px!important ; 
width: calc(100% - (0px + 0px)) !important;
}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconrowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.object.ep_filtercolumncolumnscrollarea021035{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.card.cardcolumn03{


position:relative !important; 




max-width: initial;min-width: initial;

	.column.columncardcolumn031{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textrowcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.button{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.grid.gridcolumncolumnscrollarea021053{


position:relative !important; 






	.col.colgridcolumncolumnscrollarea021051{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.sharecardcolgridcolumncolumnscrollarea0210511{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationcolumncolumnscrollarea021074{


position:relative !important; 




}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconrowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.object.ep_filtercolumncolumnscrollarea021035{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.card.cardcolumn03{


position:relative !important; 




max-width: initial;min-width: initial;

	.column.columncardcolumn031{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textrowcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.button{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.grid.gridcolumncolumnscrollarea021053{


position:relative !important; 






	.col.colgridcolumncolumnscrollarea021051{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.sharecardcolgridcolumncolumnscrollarea0210511{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationcolumncolumnscrollarea021074{


position:relative !important; 




}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconrowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.object.ep_filtercolumncolumnscrollarea021035{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.card.cardcolumn03{


position:relative !important; 




max-width: initial;min-width: initial;

	.column.columncardcolumn031{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textrowcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.button{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.grid.gridcolumncolumnscrollarea021053{


position:relative !important; 






	.col.colgridcolumncolumnscrollarea021051{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.sharecardcolgridcolumncolumnscrollarea0210511{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationcolumncolumnscrollarea021074{


position:relative !important; 




}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconrowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.object.ep_filtercolumncolumnscrollarea021035{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.card.cardcolumn03{


position:relative !important; 




max-width: initial;min-width: initial;

	.column.columncardcolumn031{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textrowcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.button{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.grid.gridcolumncolumnscrollarea021053{


position:relative !important; 






	.col.colgridcolumncolumnscrollarea021051{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.sharecardcolgridcolumncolumnscrollarea0210511{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationcolumncolumnscrollarea021074{


position:relative !important; 




}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.icon.iconrowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.object.ep_filtercolumncolumnscrollarea021035{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.card.cardcolumn03{


position:relative !important; 




max-width: initial;min-width: initial;

	.column.columncardcolumn031{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textrowcolumn0131{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.button.button{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}

.grid.gridcolumncolumnscrollarea021053{


position:relative !important; 






	.col.colgridcolumncolumnscrollarea021051{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.sharecardcolgridcolumncolumnscrollarea0210511{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.pagination.paginationcolumncolumnscrollarea021074{


position:relative !important; 




}


}
}
}
</style>
