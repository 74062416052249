<template>
 <div>  
<div id="HomeSection1"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <div  :class="'row rowcolumn01 '"   id="rowcolumn01" ref="rowcolumn01"  v-bind:style="{}">
 <Logo :class="'object logorowcolumn011 '"   id="logorowcolumn011" ref="logorowcolumn011"  v-bind:style="{}">
</Logo>
 <div  :class="'text textrowcolumn013 '"   id="textrowcolumn013" ref="textrowcolumn013"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'Code' }}</div>

   </div>
 <div  :class="'text textrowcolumn013314 '"   id="textrowcolumn013314" ref="textrowcolumn013314"  v-bind:style="{color : '#8b959c',}">
<div class="text-content">{{ 'Info' }}</div>

 
 <div  :data-balloon-length = "'medium'" 
 :aria-label = "'tooltip'" 
 :data-balloon-visible = "true" 
 :data-balloon-blunt = "true" 
 :class="'mousearea mouseareaitemrowcolumn01531 '"   id="mouseareaitemrowcolumn01531" ref="mouseareaitemrowcolumn01531"  v-bind:style="{}"></div>  </div>  </div>
 <MainBanner :class="'object mainbannercolumn03 '"   id="mainbannercolumn03" ref="mainbannercolumn03"  v-bind:style="{}" :bannerAction = "goToCode" 
 :imageList = "imageList" 
 :title = "pageData.block_title1" 
 :text = "pageData.block_text1" 
>
</MainBanner>
 <v-row  :class="'grid gridcolumn05 '"   id="gridcolumn05" ref="gridcolumn05"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumn051 '"   id="colgridcolumn051" ref="colgridcolumn051"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <SecondBanner :class="'object secondBanner1 '"   id="secondBanner1" ref="secondBanner1"  v-bind:style="{}" :bannerAction = "goToExplorer" 
 :bgColor = "'#7E5FEC'" 
 :text = "pageData.block_text2" 
 :title = "pageData.block_title2" 
 :buttonText = "'Ouvrir l\' éditeur'" 
 :buttonIcon = "'ion-ios-create'" 
 :miniImg1 = "'Resources/sectionBanner/sectionBanner1.png'" 
 :miniImg2 = "'Resources/sectionBanner/sectionBanner2.png'" 
 :miniImg3 = "'Resources/sectionBanner/sectionBanner3.png'" 
 :miniColor1 = "'#ffc100'" 
 :miniColor2 = "'#bbbbee'" 
 :miniColor3 = "'#fe5a69'" 
>
</SecondBanner></div></v-col>
 <v-col  :class="'col colgridcolumn053 '"   id="colgridcolumn053" ref="colgridcolumn053"  v-bind:style="{}" :cols = "12" 
 :md = "6" 
><div class="col-container">
 <SecondBanner :class="'object secondBanner2 '"   id="secondBanner2" ref="secondBanner2"  v-bind:style="{}" :bannerAction = "goToChallenges" 
 :bgColor = "'#56445D'" 
 :bgImg = "'Resources/background/confetti.png'" 
 :buttonText = "'voire les projets'" 
 :text = "pageData.block_text3" 
 :title = "pageData.block_title3" 
 :buttonIcon = "'ion-ios-flash'" 
 :miniImg1 = "'Resources/sectionBanner/sectionBanner4.png'" 
 :miniImg2 = "'Resources/sectionBanner/sectionBanner5.png'" 
 :miniImg3 = "'Resources/sectionBanner/sectionBanner6.png'" 
 :miniColor1 = "'#e0e0e0'" 
 :miniColor2 = "'#e438c8'" 
 :miniColor3 = "'#a3e438'" 
>
</SecondBanner></div></v-col>  </v-row>
 <div  :class="'row rowcolumn015 '"   id="rowcolumn015" ref="rowcolumn015"  v-bind:style="{}">
 <div  :class="'text textrowcolumn013 '"   id="textrowcolumn013" ref="textrowcolumn013"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'Nos autres sites' }}</div>

   </div>
 <div  :class="'text textrowcolumn013314 '"   id="textrowcolumn013314" ref="textrowcolumn013314"  v-bind:style="{color : '#8b959c',}">
<div class="text-content">{{ 'Info' }}</div>

 
 <div  :data-balloon-length = "'medium'" 
 :aria-label = "'tooltip'" 
 :data-balloon-visible = "true" 
 :data-balloon-blunt = "true" 
 :class="'mousearea mouseareaitemrowcolumn01531 '"   id="mouseareaitemrowcolumn01531" ref="mouseareaitemrowcolumn01531"  v-bind:style="{}"></div>  </div>  </div>
 <Banner :class="'object siteBanner '"   id="siteBanner" ref="siteBanner"  v-bind:style="{}" :bannerAction = "goToSite" 
 :bannerMessages = "pageData.site_messages" 
 :buttonText = "'Visitez notre site'" 
 :title = "'Site Web'" 
>
</Banner>
 <Banner :class="'object helpBanner '"   id="helpBanner" ref="helpBanner"  v-bind:style="{}" :bannerAction = "goToHelp" 
 :bannerMessages = "pageData.doc_messages" 
 :buttonText = "'Lire la documentation'" 
 :title = "'Documentation'" 
>
</Banner>
 <div  :class="'row rowcolumn0158 '"   id="rowcolumn0158" ref="rowcolumn0158"  v-bind:style="{}">
 <div  :class="'text textrowcolumn013 '"   id="textrowcolumn013" ref="textrowcolumn013"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'Club' }}</div>

   </div>
 <div  :class="'text textrowcolumn013314 '"   id="textrowcolumn013314" ref="textrowcolumn013314"  v-bind:style="{color : '#8b959c',}">
<div class="text-content">{{ 'Info' }}</div>

 
 <div  :data-balloon-length = "'medium'" 
 :aria-label = "'tooltip'" 
 :data-balloon-visible = "true" 
 :data-balloon-blunt = "true" 
 :class="'mousearea mouseareaitemrowcolumn01531 '"   id="mouseareaitemrowcolumn01531" ref="mouseareaitemrowcolumn01531"  v-bind:style="{}"></div>  </div>  </div>
 <ActionBanner :class="'object actionbannercolumn07 '"   id="actionbannercolumn07" ref="actionbannercolumn07"  v-bind:style="{}" :title = "pageData.block_club_title" 
 :message = "pageData.block_club_text" 
 :buttonValue = "'Joindre le club'" 
>
</ActionBanner>  </div>
 
      </div>
 </div> 
</template>
<script>
import Logo from '../../../At/Components/Logo'
import MainBanner from '../../../At/Components/MainBanner'
import SecondBanner from '../../../At/Components/SecondBanner'
import Banner from '../../../At/Components/Banner'
import ActionBanner from '../../../At/Components/ActionBanner'

import * as utils from '../../../Js/utils.js'
export default {
	name: 'HomeSection1',

    components: {Logo,MainBanner,SecondBanner,Banner,ActionBanner,},
	methods: {
	
		goToCode: function(){ 		
	window.open(this.$store.state.codeURL, '_blank').focus()

}
,
		goToHelp: function(){ 		
	window.open(this.$store.state.helpURL, '_blank').focus()

}
,
		goToSite: function(){ 		
	window.open(this.$store.state.webURL, '_blank').focus()

}
,
		goToExplorer: function(){ 		
	this.$router.push('/explorer')

}
,
		goToChallenges: function(){ 		
	this.$router.push('/challenges')

}
,
	},
	computed: {
	
		mixedImagesListSite: function(){		
	return this.imageList.filter((e)=>{

		return (e.name.match('website_') != null)

	})

}
,
		mixedImagesListHelp: function(){		
	return utils.mixed(this.imageList)

}
,
		imgListSecondBanner1: function(){		
	if(this.imageList.length > 4){

		return 4

	} else {

		return this.imageList.length

	}

}
,
	},
	data(){
	return {
		
		secondBText1:  "Utiliser des codes verbaux pour creer vos propres projets. Créz et jouer avec des images."		,
		secondBText2:  "Soyez creatifs et partagez vos realisations avec vos amis et utilisateurs de monde entier."		,
		siteTitleList:  [		
	"Visitez notre site web", 

	"Découvrez nos partenaires", 

	"Nos realisations"

]
,
		siteTextList:  [		
	"Aller sur notre site pour avoir plus d' informations sur abcCode.",

	"Nous travaillons avec de nombreux partenaires à travers le monde.",

	"De nombreux projets au tour d' abcCode ont été mis sur pied. Informez vous sur chaqun d' eux."	

]
,
		helpTitleList:  [		
	"Une documentation riche", 

	"Des details sur les instructions", 

	"Des explications"

]
,
		helpTextList:  [		
	"Documentez vous pour mieux utiliser abcCode.",

	"Decrouvrer chaques details d\' une instruction.",

	"Obtenez des explications détaillées avec de petits codes à l' appui."	

]
,
		}
	},
	props: {
	
		imageList: {					required: false,			default: () =>  [],		},
		pageData: {					required: false,			default: () =>  ({}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#HomeSection1{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:30px ; background-color: transparent ; 

.row.rowcolumn01{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

width:auto!important ; 
height:40px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:10px ; background-color:transparent ; 

	.object.logorowcolumn011{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:32px!important ; 
	height:32px!important ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.text.textrowcolumn013{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:auto!important ; 
	
	color:#414a51 ; 
	font-size:27px ; 
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	color:#8b959c ; 
	font-size:25px ; 
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;text-decoration: underline
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		cursor: help;
		}

	
}


}

.object.mainbannercolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:350px!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.grid.gridcolumn05{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color: transparent ; 

	.col.colgridcolumn051{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	width:100px!important ; 
	height:350px!important ; 
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumn053{

	
	position:relative !important; 
	margin: unset;
	transform-origin: top left;
	
	
	
	width:100px!important ; 
	height:350px!important ; 
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner2{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-top: 0px ; 
		margin-right: 0px ; 
		margin-bottom: 0px ; 
		width: calc(100% - (0px + 0px)) !important;
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.row.rowcolumn015{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

width:auto!important ; 
height:40px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:10px ; background-color:transparent ; 

	.text.textrowcolumn013{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:auto!important ; 
	
	color:#414a51 ; 
	font-size:27px ; 
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	color:#8b959c ; 
	font-size:25px ; 
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;text-decoration: underline
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		cursor: help;
		}

	
}


}

.object.siteBanner{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:300px!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.helpBanner{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:300px!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.row.rowcolumn0158{

align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

width:auto!important ; 
height:40px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:10px ; background-color:transparent ; 

	.text.textrowcolumn013{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:auto!important ; 
	
	color:#414a51 ; 
	font-size:27px ; 
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	color:#8b959c ; 
	font-size:25px ; 
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;text-decoration: underline
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		cursor: help;
		}

	
}


}

.object.actionbannercolumn07{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:500px!important ; 
width: calc(100% - (0px + 0px)) !important;

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.object.logorowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.mainbannercolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.grid.gridcolumn05{


position:relative !important; 






	.col.colgridcolumn051{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumn053{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner2{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.row.rowcolumn015{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.siteBanner{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.helpBanner{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.row.rowcolumn0158{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.actionbannercolumn07{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.object.logorowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.mainbannercolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.grid.gridcolumn05{


position:relative !important; 






	.col.colgridcolumn051{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumn053{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner2{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.row.rowcolumn015{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.siteBanner{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.helpBanner{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.row.rowcolumn0158{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.actionbannercolumn07{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.object.logorowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.mainbannercolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.grid.gridcolumn05{


position:relative !important; 






	.col.colgridcolumn051{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumn053{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner2{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.row.rowcolumn015{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.siteBanner{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.helpBanner{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.row.rowcolumn0158{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.actionbannercolumn07{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.object.logorowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.mainbannercolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.grid.gridcolumn05{


position:relative !important; 






	.col.colgridcolumn051{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumn053{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner2{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.row.rowcolumn015{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.siteBanner{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.helpBanner{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.row.rowcolumn0158{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.actionbannercolumn07{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.row.rowcolumn01{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.object.logorowcolumn011{

	
	position:relative !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.mainbannercolumn03{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.grid.gridcolumn05{


position:relative !important; 






	.col.colgridcolumn051{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}

	.col.colgridcolumn053{

	
	position:relative !important; 
	
	
	
	

	::v-deep .col-container{position: relative;

	height: 100%;

	width: 100%;

		.object.secondBanner2{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	
}


}

.row.rowcolumn015{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.siteBanner{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.helpBanner{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.row.rowcolumn0158{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.object.actionbannercolumn07{


position:relative !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
