<template>
 <div>  
<div id="ProfileSetting"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{}">
 <v-card 
 :class="'card card0 ' + 'paddedBottom'"   id="card0" ref="card0"  v-bind:style="{}">
 <v-row  :class="'grid gridcard01 '"   id="gridcard01" ref="gridcard01"  v-bind:style="{}">
 <v-col  :class="'col left '"   id="left" ref="left"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
><div class="col-container">
 <div  :class="'item itemleft1 '"   id="itemleft1" ref="itemleft1"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageitemleft11 '"   id="networkimageitemleft11" ref="networkimageitemleft11"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "userImage" 
>  </v-img>
 <v-btn tag="div" 
 :color = "'#ffbc13'" 
 :fab = "true" 
 :dark = "true" 
 @click="chooseIcon" :class="'button buttonitemleft13 '"   id="buttonitemleft13" ref="buttonitemleft13"  v-bind:style="{}"><span v-if = "(!true) || (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-camera' !== undefined"  :color = "'#fff'" 
>{{ 'ion-ios-camera' }}
</v-icon></v-btn>  </div></div></v-col>
 <v-col  :class="'col rigth '"   id="rigth" ref="rigth"  v-bind:style="{}" :cols = "12" 
 :md = "8" 
><div class="col-container">
 <div  :class="'column columnrigth1 '"   id="columnrigth1" ref="columnrigth1"  v-bind:style="{}">
 <div  :class="'column columnrigth11 '"   id="columnrigth11" ref="columnrigth11"  v-bind:style="{}">
 <div  :class="'text textcolumnrigth11 '"   id="textcolumnrigth11" ref="textcolumnrigth11"  v-bind:style="{color : '#797979',}">
<div class="text-content">{{ 'nom d\' utilisateur' }}</div>

   </div>
 <v-text-field  :class="'textfield textcolumnrigth112 '"   id="textcolumnrigth112" ref="textcolumnrigth112" v-model="userName"  v-bind:style="{color : '#51515d',}"></v-text-field>  </div>
 <div  :class="'column columnrigth12 '"   id="columnrigth12" ref="columnrigth12"  v-bind:style="{}">
 <div  :class="'text textcolumnrigth11 '"   id="textcolumnrigth11" ref="textcolumnrigth11"  v-bind:style="{color : '#797979',}">
<div class="text-content">{{ 'E-mail' }}</div>

   </div>
 <div  :class="'text textcolumnrigth112 '"   id="textcolumnrigth112" ref="textcolumnrigth112"  v-bind:style="{color : '#51515d',}">
<div class="text-content">{{ userData.email }}</div>

   </div>  </div>
 <v-btn tag="div" 
 :color = "'#ffbc13'" 
 :rounded = "true" 
 :dark = "true" 
 @click="updateUserData" :class="'button buttoncolumnrigth173 ' + 'textButton'"   id="buttoncolumnrigth173" ref="buttoncolumnrigth173"  v-bind:style="{}"><span>{{ 'Mettre à jour' }}</span>
</v-btn>  </div></div></v-col>  </v-row>  </v-card>
 <v-card 
 :class="'card card02 ' + 'paddedBottom'"   id="card02" ref="card02"  v-bind:style="{}">
 <v-row  :class="'grid gridcard01 '"   id="gridcard01" ref="gridcard01"  v-bind:style="{}">
 <v-col  :class="'col left '"   id="left" ref="left"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
><div class="col-container">
 <div  :class="'column columnleft1 '"   id="columnleft1" ref="columnleft1"  v-bind:style="{}">
 <div  :class="'text textcolumnrigth1121 '"   id="textcolumnrigth1121" ref="textcolumnrigth1121"  v-bind:style="{color : '#51515d',}">
<div class="text-content">{{ 'Mettre à jour le mot de passe' }}</div>

   </div>  </div></div></v-col>
 <v-col  :class="'col rigth '"   id="rigth" ref="rigth"  v-bind:style="{}" :cols = "12" 
 :md = "8" 
><div class="col-container">
 <div  :class="'column columnrigth1 '"   id="columnrigth1" ref="columnrigth1"  v-bind:style="{}">
 <div  :class="'column inputItem '"   id="inputItem" ref="inputItem"  v-bind:style="{}">
 <div  :class="'text textcolumnrigth111 '"   id="textcolumnrigth111" ref="textcolumnrigth111"  v-bind:style="{color : '#797979',}">
<div class="text-content">{{ 'Votre mot de passe actuel' }}</div>

   </div>
 <v-text-field  :placeholder = "'Mot de passe'" 
 :rounded = "true" 
 outlined :class="'textfield input '"   id="input" ref="input" v-model="actualPass"  v-bind:style="{}"></v-text-field>  </div>
 <div  :class="'column inputItem2 '"   id="inputItem2" ref="inputItem2"  v-bind:style="{}">
 <div  :class="'text textcolumnrigth111 '"   id="textcolumnrigth111" ref="textcolumnrigth111"  v-bind:style="{color : '#797979',}">
<div class="text-content">{{ 'Votre nouveau mot de passe' }}</div>

   </div>
 <v-text-field  :placeholder = "'Mot de passe'" 
 :rounded = "true" 
 outlined :class="'textfield input '"   id="input" ref="input" v-model="newPass"  v-bind:style="{}"></v-text-field>  </div>
 <div  :class="'column inputItem3 '"   id="inputItem3" ref="inputItem3"  v-bind:style="{}">
 <div  :class="'text textcolumnrigth111 '"   id="textcolumnrigth111" ref="textcolumnrigth111"  v-bind:style="{color : '#797979',}">
<div class="text-content">{{ 'Confirmez votre nouveau mot de passe' }}</div>

   </div>
 <v-text-field  :placeholder = "'Mot de passe'" 
 :rounded = "true" 
 outlined :class="'textfield input '"   id="input" ref="input" v-model="newPassConfirm"  v-bind:style="{}"></v-text-field>  </div>
 <div  :class="'text error1 '"   id="error1" ref="error1" v-if="error1Visible"  v-bind:style="{color : '#ff4a02',}">
<div class="text-content">{{ error1 }}</div>

   </div>
 <v-btn tag="div" 
 :color = "'#ffbc13'" 
 :rounded = "true" 
 :dark = "true" 
 :class="'button buttoncolumnrigth17 ' + 'textButton'"   id="buttoncolumnrigth17" ref="buttoncolumnrigth17"  v-bind:style="{}"><span>{{ 'Mettre à jour' }}</span>
</v-btn>  </div></div></v-col>  </v-row>  </v-card>
 <v-card 
 :class="'card card023 ' + 'paddedBottom'"   id="card023" ref="card023"  v-bind:style="{}">
 <v-row  :class="'grid gridcard01 '"   id="gridcard01" ref="gridcard01"  v-bind:style="{}">
 <v-col  :class="'col left '"   id="left" ref="left"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
><div class="col-container">
 <div  :class="'column columnleft1 '"   id="columnleft1" ref="columnleft1"  v-bind:style="{}">
 <div  :class="'text textcolumnrigth1121 '"   id="textcolumnrigth1121" ref="textcolumnrigth1121"  v-bind:style="{color : '#51515d',}">
<div class="text-content">{{ 'Supprimer le compte' }}</div>

   </div>
 <div  :class="'text textcolumnrigth1112 '"   id="textcolumnrigth1112" ref="textcolumnrigth1112"  v-bind:style="{color : '#797979',}">
<div class="text-content">{{ 'Confirmez votre mot de passe ici pour supprimer définitivement votre compte.' }}</div>

   </div>  </div></div></v-col>
 <v-col  :class="'col rigth '"   id="rigth" ref="rigth"  v-bind:style="{}" :cols = "12" 
 :md = "8" 
><div class="col-container">
 <div  :class="'column columnrigth1 '"   id="columnrigth1" ref="columnrigth1"  v-bind:style="{}">
 <div  :class="'column inputItem '"   id="inputItem" ref="inputItem"  v-bind:style="{}">
 <div  :class="'text textcolumnrigth111 '"   id="textcolumnrigth111" ref="textcolumnrigth111"  v-bind:style="{color : '#797979',}">
<div class="text-content">{{ 'Confirmez le mot de passe' }}</div>

   </div>
 <v-text-field  :placeholder = "'Mot de passe'" 
 :rounded = "true" 
 outlined :class="'textfield input '"   id="input" ref="input" v-model="passForDelete"  v-bind:style="{}"></v-text-field>  </div>
 <div  :class="'text error2 '"   id="error2" ref="error2" v-if="error2Visible"  v-bind:style="{color : '#ff4a02',}">
<div class="text-content">{{ error2 }}</div>

   </div>
 <v-btn tag="div" 
 :color = "'#ffbc13'" 
 :rounded = "true" 
 :dark = "true" 
 :class="'button buttoncolumnrigth17 ' + 'textButton'"   id="buttoncolumnrigth17" ref="buttoncolumnrigth17"  v-bind:style="{}"><span>{{ 'Supprimer le compte' }}</span>
</v-btn>  </div></div></v-col>  </v-row>  </v-card>
 <v-card 
 :class="'card card0234 ' + 'paddedBottom'"   id="card0234" ref="card0234"  v-bind:style="{}">
 <v-row  :class="'grid gridcard01 '"   id="gridcard01" ref="gridcard01"  v-bind:style="{}">
 <v-col  :class="'col left '"   id="left" ref="left"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
><div class="col-container">
 <div  :class="'column columnleft1 '"   id="columnleft1" ref="columnleft1"  v-bind:style="{}">
 <div  :class="'text textcolumnrigth1121 '"   id="textcolumnrigth1121" ref="textcolumnrigth1121"  v-bind:style="{color : '#51515d',}">
<div class="text-content">{{ 'Renvoyer un e-mail à mes parents' }}</div>

   </div>
 <div  :class="'text textcolumnrigth1112 '"   id="textcolumnrigth1112" ref="textcolumnrigth1112"  v-bind:style="{color : '#797979',}">
<div class="text-content">{{ 'Cliquez sur le bouton pour modifier votre paramètre d\'autorisation parentale' }}</div>

   </div>  </div></div></v-col>
 <v-col  :class="'col rigth '"   id="rigth" ref="rigth"  v-bind:style="{}" :cols = "12" 
 :md = "8" 
><div class="col-container">
 <div  :class="'column columnrigth1 '"   id="columnrigth1" ref="columnrigth1"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#ffbc13'" 
 :rounded = "true" 
 :dark = "true" 
 :class="'button buttoncolumnrigth17 ' + 'textButton'"   id="buttoncolumnrigth17" ref="buttoncolumnrigth17"  v-bind:style="{}"><span>{{ 'Ré-envoyer l\' email' }}</span>
</v-btn>  </div></div></v-col>  </v-row>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>

import ac from 'artefact-controller'
import * as utils from '../../../Js/utils.js'
export default {
	name: 'ProfileSetting',

    components: {},
	methods: {
	
		changePass: function(){ 		
	if(this.newPass == this.newPassConfirm){

	} else {

		this.showError1("Le nouveau mot de passe et la verification ne correspondent pas.")

	}

}
,
		showError1: function(error){ 		
	this.error1 = error

	this.error1Visible = true

}
,
		showError2: function(error){ 		
	this.error2 = error

	this.error2Visible = true

}
,
		updateUserData: function(){		
	this.userData.username = this.userName;

	

	if(this.imageChanged){

		this.updateImage()

	} else {

		this.updateUserDataNext()

	}

}
,
		updateUserDataNext: function(imgData){		
	utils.updateUser(this.userData, imgData)

	.then((response) => {

     this.$store.commit('registerUser', response.data);

  })

  .catch((error) => {

 		console.log(error);

	});

}
,
		updateImage: function(){			
	utils.uploadFile(this.imageBlob)

	.then((data) => {

		this.imageChanged = false

		this.updateUserDataNext(data.data[0])

  })

  .catch((error) => {

 		console.log(error);

	});

}
,
		chooseIcon: function(){		
	this.atController.fileOpen({multiple: false, accept: 'image/*' }, (file) => {		

  	const reader = new FileReader();

  	reader.addEventListener('load', (event) => {

    	this.userImage = event.target.result;

			this.imageChanged = true

  	});

  	reader.readAsDataURL(file[0]); 

		this.imageBlob = file[0];

	});

}
,
	},
	mounted(){
			
	this.userName = this.userData.username;

	

	if(this.userData &&  this.userData.profile_image){

		this.userImage = this.$store.state.apiURL + this.userData.profile_image.url

	} else {

		this.userImage = "https://picsum.photos/200"

	}

	},
	computed: {
	
		userData: function(){ 		
	return this.$store.state.user

}
,
	},
	data(){
	return {
		
		atController:  new ac()		,
		userName:  null		,
		userEmail:  null		,
		actualPass:  null		,
		newPass:  null		,
		newPassConfirm:  null		,
		imageChanged:  false			,
		imageBlob:  ""		,
		userImage:  ""		,
		error1:  ""		,
		error2:  ""		,
		error1Visible:  false		,
		error2Visible:  false		,
		passForDelete:  null		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#ProfileSetting{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.paddedBottom{
	padding: 15px 0;
}
.textButton{
	padding: 10px !important;
}



.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:20px ; background-color: transparent ; 

.card.card0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color:#ffffff ; max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 15px ; 
	
	margin-right: 15px ; 
	height:auto!important ; 
	width: calc(100% - (15px + 15px)) !important;
	background-color: transparent ; 

		.col.left{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		width:100px!important ; 
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.item.itemleft1{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			margin-top: 0px ; 
			width:160px!important ; 
			height:160px!important ; 
			

				.networkimage.networkimageitemleft11{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: inherit;

				flex: unset;
				border-radius:80px ; 
				border-style: solid ; 
				border-width:1px ; 
				border-color:#676767 ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

				
}

				.button.buttonitemleft13{

				height: auto;
				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				right: 0px ; 
				bottom: 0px ; 
				width:40px!important ; 
				height:40px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		width:100px!important ; 
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-top: 0px ; 
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:10px ; background-color: transparent ; 

				.column.columnrigth11{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:5px ; background-color: transparent ; 

					.text.textcolumnrigth11{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#797979 ; 
					font-size:12px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

					.textfield.textcolumnrigth112{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#51515d ; 
					font-size:22px ; 
					font-weight: bold;
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.columnrigth12{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:5px ; background-color: transparent ; 

					.text.textcolumnrigth11{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#797979 ; 
					font-size:12px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

					.text.textcolumnrigth112{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#51515d ; 
					font-size:22px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

				
}

				.button.buttoncolumnrigth173{

				height: auto;
				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:32px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card02{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color:#ffffff ; max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 15px ; 
	
	margin-right: 15px ; 
	height:auto!important ; 
	width: calc(100% - (15px + 15px)) !important;
	background-color: transparent ; 

		.col.left{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-top: 0px ; 
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#51515d ; 
				font-size:24px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-top: 0px ; 
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.column.inputItem{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:5px ; background-color: transparent ; 

					.text.textcolumnrigth111{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#797979 ; 
					font-size:12px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:56px!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem2{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:5px ; background-color: transparent ; 

					.text.textcolumnrigth111{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#797979 ; 
					font-size:12px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:56px!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem3{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:5px ; background-color: transparent ; 

					.text.textcolumnrigth111{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#797979 ; 
					font-size:12px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:56px!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error1{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#ff4a02 ; 
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:32px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card023{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color:#ffffff ; max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 15px ; 
	
	margin-right: 15px ; 
	height:auto!important ; 
	width: calc(100% - (15px + 15px)) !important;
	background-color: transparent ; 

		.col.left{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-top: 0px ; 
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:10px ; background-color: transparent ; 

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#51515d ; 
				font-size:24px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#797979 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-top: 0px ; 
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.column.inputItem{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:5px ; background-color: transparent ; 

					.text.textcolumnrigth111{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					color:#797979 ; 
					font-size:12px ; 
					.text-content{
					width: 100%;
					font-weight: bold;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:56px!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error2{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#ff4a02 ; 
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:32px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card0234{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color:#ffffff ; max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 15px ; 
	
	margin-right: 15px ; 
	height:auto!important ; 
	width: calc(100% - (15px + 15px)) !important;
	background-color: transparent ; 

		.col.left{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-top: 0px ; 
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:10px ; background-color: transparent ; 

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#51515d ; 
				font-size:24px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#797979 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				font-weight: bold;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		margin: unset;
		transform-origin: top left;
		
		
		
		width:100px!important ; 
		height:auto!important ; 
		

		::v-deep .col-container{position: relative;

		height: auto;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-top: 0px ; 
			margin-right: 0px ; 
			width:100px!important ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;
			gap:5px ; background-color: transparent ; 

				.button.buttoncolumnrigth17{

				height: auto;
				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0px ; 
				
				width:auto!important ; 
				height:32px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.item.itemleft1{

			
			position:relative !important; 
			
			
			
			
			

				.networkimage.networkimageitemleft11{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonitemleft13{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columnrigth11{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.textcolumnrigth112{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.columnrigth12{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrigth112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoncolumnrigth173{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card02{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.inputItem{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem2{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem3{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card023{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.inputItem{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error2{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card0234{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.item.itemleft1{

			
			position:relative !important; 
			
			
			
			
			

				.networkimage.networkimageitemleft11{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonitemleft13{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columnrigth11{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.textcolumnrigth112{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.columnrigth12{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrigth112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoncolumnrigth173{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card02{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.inputItem{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem2{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem3{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card023{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.inputItem{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error2{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card0234{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.item.itemleft1{

			
			position:relative !important; 
			
			
			
			
			

				.networkimage.networkimageitemleft11{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonitemleft13{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columnrigth11{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.textcolumnrigth112{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.columnrigth12{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrigth112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoncolumnrigth173{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card02{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.inputItem{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem2{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem3{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card023{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.inputItem{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error2{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card0234{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.item.itemleft1{

			
			position:relative !important; 
			
			
			
			
			

				.networkimage.networkimageitemleft11{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonitemleft13{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columnrigth11{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.textcolumnrigth112{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.columnrigth12{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrigth112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoncolumnrigth173{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card02{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.inputItem{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem2{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem3{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card023{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.inputItem{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error2{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card0234{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.item.itemleft1{

			
			position:relative !important; 
			
			
			
			
			

				.networkimage.networkimageitemleft11{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonitemleft13{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.columnrigth11{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.textcolumnrigth112{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.columnrigth12{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.text.textcolumnrigth112{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.button.buttoncolumnrigth173{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card02{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.inputItem{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem2{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.column.inputItem3{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error1{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card023{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.column.inputItem{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnrigth111{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.input{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

				.text.error2{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}

.card.card0234{


position:relative !important; 




max-width: initial;min-width: initial;

	.grid.gridcard01{

	
	position:relative !important; 
	
	
	
	
	

		.col.left{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnleft1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnrigth1121{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.text.textcolumnrigth1112{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		
}

		.col.rigth{

		
		position:relative !important; 
		
		
		
		

		::v-deep .col-container{position: relative;

		height: 100%;

		width: 100%;

			.column.columnrigth1{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.button.buttoncolumnrigth17{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		
}

	
}


}


}
}
}
</style>
