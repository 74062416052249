<template>
 <div>  
<div id="ExploreDetails"> 
         
 <TemplatePage :class="'object templatepageitem092 '"   id="templatepageitem092" ref="templatepageitem092"  v-bind:style="{}" :pageData = "pageData" 
>
 <template  v-slot:main>
 <div  :class="'slottemplate slottemplatetemplatepageitem091 '"   id="slottemplatetemplatepageitem091" ref="slottemplatetemplatepageitem091"  v-bind:style="{}">
 <div  :class="'column columnscrollarea021 '"   id="columnscrollarea021" ref="columnscrollarea021"  v-bind:style="{}">
 <div  :class="'column columncolumnscrollarea0210 '"   id="columncolumnscrollarea0210" ref="columncolumnscrollarea0210"  v-bind:style="{}">
 <div  :class="'row rowcolumncolumnscrollarea02101 '"   id="rowcolumncolumnscrollarea02101" ref="rowcolumncolumnscrollarea02101"  v-bind:style="{justifyContent : 'space-between',}">
 <WorldButton :class="'object worldbuttonrowcolumncolumnscrollarea021011 '"   id="worldbuttonrowcolumncolumnscrollarea021011" ref="worldbuttonrowcolumncolumnscrollarea021011"  v-bind:style="{}" :value = "''" 
 :iconName = "'ion-ios-arrow-dropleft-circle'" 
>
</WorldButton>
 <WorldButton :class="'object worldbuttonrowcolumncolumnscrollarea0210112 '"   id="worldbuttonrowcolumncolumnscrollarea0210112" ref="worldbuttonrowcolumncolumnscrollarea0210112"  v-bind:style="{}" :click = "compile" 
 :value = "''" 
 :iconName = "'ion-md-refresh'" 
>
</WorldButton>  </div>
 <v-card 
 :class="'card canvasBox '"   id="canvasBox" ref="canvasBox" :elevation = "2" 
  v-bind:style="{}">
 <div  :class="'item canvasContent '"   id="canvasContent" ref="canvasContent"  v-bind:style="{}">  </div>
 <v-btn tag="div" 
 :rounded = "true" 
 @click="setFocusOnIFrame" :class="'button buttoncanvasBox5 '"   id="buttoncanvasBox5" ref="buttoncanvasBox5"  v-bind:style="{}"><span>{{ $t('ExplorerDetail.getFocus') }}</span>
</v-btn>  </v-card>
 <div  :class="'scrollarea scrollareacolumncolumnscrollarea02107 '"   id="scrollareacolumncolumnscrollarea02107" ref="scrollareacolumncolumnscrollarea02107"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'row rowscrollareacolumncolumnscrollarea021072 '"   id="rowscrollareacolumncolumnscrollarea021072" ref="rowscrollareacolumncolumnscrollarea021072"  v-bind:style="{}">
 <NivItem :class="'object nivitemscrollareacolumncolumnscrollarea021071 '"   id="nivitemscrollareacolumncolumnscrollarea021071" ref="nivitemscrollareacolumncolumnscrollarea021071" v-for="(item,index) in 20" :key="'nivitemscrollareacolumncolumnscrollarea021071' + index"  v-bind:style="{}" :clo = "setCurrentStage" 
 :nivNumber = "(index+1)" 
 :active = "(currentStage == index)" 
>
</NivItem>  </div>  </div>
 <div  :class="'row rowcolumncolumnscrollarea02105 ' + 'wrapable-row'"   id="rowcolumncolumnscrollarea02105" ref="rowcolumncolumnscrollarea02105"  v-bind:style="{justifyContent : 'space-between',backgroundColor : 'transparent',}">
 <WorldButton :class="'object worldbuttonrowcolumncolumnscrollarea021051 '"   id="worldbuttonrowcolumncolumnscrollarea021051" ref="worldbuttonrowcolumncolumnscrollarea021051"  v-bind:style="{}" :click = "compile" 
 :backgroundColor = "'#ff6b00'" 
 :value = "'Jouer'" 
 :textColor = "'#fff'" 
 :iconName = "'ion-ios-play-circle'" 
>
</WorldButton>
 <div  :class="'row rowrowcolumncolumnscrollarea021053 '"   id="rowrowcolumncolumnscrollarea021053" ref="rowrowcolumncolumnscrollarea021053"  v-bind:style="{}">
 <WorldButton :class="'object worldbuttonrowcolumncolumnscrollarea0210511 '"   id="worldbuttonrowcolumncolumnscrollarea0210511" ref="worldbuttonrowcolumncolumnscrollarea0210511"  v-bind:style="{}" :click = "remixCode" 
 :iconName = "'ion-ios-shuffle'" 
 :value = "'Mixer'" 
>
</WorldButton>
 <WorldButton :class="'object worldbuttonrowcolumncolumnscrollarea0210512 '"   id="worldbuttonrowcolumncolumnscrollarea0210512" ref="worldbuttonrowcolumncolumnscrollarea0210512"  v-bind:style="{}" :value = "'Like ' + numberLike" 
 :textColor = "liked? 'red' : '#9d9da3'" 
>
</WorldButton>  </div>  </div>  </div>
 <Ep_ChatSection :class="'object ep_chatsectioncolumnscrollarea021146 '"   id="ep_chatsectioncolumnscrollarea021146" ref="ep_chatsectioncolumnscrollarea021146"  v-bind:style="{}" :projectImage = "project.description_image" 
 :creatorName = "creatorName" 
 :publishedTime = "publishedTime" 
 :projectName = "projectName" 
 :numberLike = "numberLike" 
 :projectID = "projectID" 
 :creatorID = "creatorID" 
>
</Ep_ChatSection>  </div></div></template>
</TemplatePage>
 
      </div>
 </div> 
</template>
<script>
import TemplatePage from '../../At/Pages/TemplatePage'
import WorldButton from '../../At/Components/WorldButton'
import NivItem from '../../At/Components/Explore/NivItem'
import Ep_ChatSection from '../../At/Components/Explore/Ep_ChatSection'

import languages from "../../Js/language.json"
import {compileFunction} from "../../Js/compilerFunction.js"
import * as utils from '../../Js/utils.js'
import langMap from '../../Js/languageMap.json'
import 'jquery';
export default {
	name: 'ExploreDetails',

    components: {TemplatePage,WorldButton,NivItem,Ep_ChatSection,},
	methods: {
	
		remixCode: function(){ 		
	window.open(this.$store.state.codeURL + '/#/app/mix/' + this.projectID, '_blank').focus()

}
,
		setCurrentStage: function(id) { 		
	this.currentStage = id

	this.compile()

}
,
		showConsole: function(){		
	this.consoleShowed = true

}
,
		hideConsole: function(){		
	this.consoleShowed = false

}
,
		compile: function(){		
	let res = compileFunction(this.pjData, this.projectLanguage, this.currentStage+1)

	this.compileRes = res

	

	if(res.console.alerted){

		this.consoleShowed = true

	}

	this.finalLog = res.console.alert

	this.load(res.final)

}
,
		load: function(finalCode) {		
  const contentDiv = document.getElementById("canvasContent");

  contentDiv.innerHTML = '<iframe id="abcFrame" style="z-index: 0; border: none; width: 100%; height: 100%" width="100%" height="100%"></iframe>'



	const iframe = document.querySelector('#abcFrame')

	iframe.srcdoc = finalCode

	

	this.setFocusOnIFrame()

}
,
		likeProject: function(){ 		
	let filter = {

		user: this.$store.state.user.id,

		project: this.projectID

	}

	utils.likeProject(filter)

	.catch((error)=>{

	  console.log(error);

	});

}
,
		dislikeProject: function(){ 		
	utils.dislikeProject(this.likedId)

	.catch((error)=>{

	  console.log(error);

	});

}
,
		getLikedProjectId: function() { 		
	if(this.$store.state.user){

		let filter = {

			user: this.$store.state.user.id,

			project: this.projectID

		}

		utils.getLikedProjects(filter)

		.then((response)=>{

			if(response.data.length > 0){

				this.likedId = response.data[0].id

			}

		})

		.catch((error)=>{

		  console.log(error);

		});

	}

}
,
		setFocusOnIFrame: function(){		
	let iframe = document.querySelector('#abcFrame')

	

	if(iframe){

  	iframe.contentWindow.focus();

	}

}
,
		getPageData: function() { 		
	utils.getPageData('explorer-page')

	.then((res) => {

		this.pageData = res.data

	})

}
,
	},
	mounted(){
			
	this.projectID = this.$route.params.id

	

	utils.getProjects({id: this.projectID})

	.then((response) => {

		console.log("??????")

		this.project = response.data[0]

		console.log("----", this.project.pj_setting)

		this.pjData = this.project.pj_setting

	})

	.catch(error => console.log(error))	

	

	// Get liked projects

	this.getLikedProjectId()

	

	this.getPageData()

	},
	computed: {
	
		language: function() { 		
	let lang = this.$store.state.language

	if(lang){

		return lang

	}

	return 'francais'

}
,
		creatorID: function(){		
	return this.project.user ? this.project.user.id : null

}
,
		projectName: function(){		
	return this.project.name ?? "abcCode"

}
,
		projectLanguage: function(){		
	let pjLang = this.pjData.langue.toLowerCase()

	

	if(langMap[pjLang]){

		return pjLang

	}

	

	for(let lang in langMap){

		let long = langMap[lang].toLowerCase()

		if(long == pjLang){

			return lang.toLowerCase()

		}

	}

	return this.language.toLowerCase()

}
,
		creatorName: function(){		
	return this.project.user ? this.project.user.username : "dannick"

}
,
		publishedTime: function(){		
	return this.project.updated_at ?? "11.21.2020"

}
,
		numberLike: function(){		
	return this.project.likes ? this.project.likes.length : 0

}
,
		liked: function(){		
	return (this.likedId !== null)

}
,
	},
	data(){
	return {
		
		pageData:  ({})		,
		currentStage:  0		,
		pjData:  ({})		,
		projectID:  0		,
		project:  ({})		,
		likedId:  null		,
		consoleShowed:  false		,
		finalLog:  ""		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#ExploreDetails{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.wrapable-row{
    flex-wrap: wrap !important;
}



.object.templatepageitem092{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepageitem091{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
width:100px!important ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnscrollarea021{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	width:100px!important ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:20px ; background-color: transparent ; 

		.column.columncolumnscrollarea0210{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:20px ; background-color: transparent ; 

			.row.rowcolumncolumnscrollarea02101{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			justify-content:space-between ; background-color:transparent ; 

				.object.worldbuttonrowcolumncolumnscrollarea021011{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				width:50px!important ; 
				height:40px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.worldbuttonrowcolumncolumnscrollarea0210112{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				width:50px!important ; 
				height:40px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.card.canvasBox{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:500px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			background-color:#ffffff ; 
			border-radius:20px ; max-width: initial;min-width: initial;

				.item.canvasContent{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				

				
}

				.button.buttoncanvasBox5{

				height: auto;
				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				right: 10px ; 
				bottom: 10px ; 
				width:auto!important ; 
				height:30px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				padding-left: 16px;
				padding-right: 16px;

				i.v-icon{
				}
				}

			
}

			.scrollarea.scrollareacolumncolumnscrollarea02107{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:90px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			overflow:auto ; 
			overflow-x:scroll ; 
			overflow-y:hidden ; 
			background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: transparent;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #ff6b00;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

				.row.rowscrollareacolumncolumnscrollarea021072{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
				
				left:0px ; 
				
				top: calc(50% + 0px) ; 
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;
				gap:10px ; background-color:transparent ; 

					.object.nivitemscrollareacolumncolumnscrollarea021071{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					margin-top: 0px ; 
					width:190px!important ; 
					height:74px!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

			.row.rowcolumncolumnscrollarea02105{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 80px ; 
			
			margin-right: 80px ; 
			height:auto!important ; 
			width: calc(100% - (80px + 80px)) !important;
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			justify-content:space-between ; 
			background-color:transparent ; 

				.object.worldbuttonrowcolumncolumnscrollarea021051{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				width:118px!important ; 
				height:48px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.row.rowrowcolumncolumnscrollarea021053{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;
				gap:10px ; background-color:transparent ; 

					.object.worldbuttonrowcolumncolumnscrollarea0210511{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					margin-top: 0px ; 
					width:118px!important ; 
					height:48px!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.worldbuttonrowcolumncolumnscrollarea0210512{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					margin-top: 0px ; 
					width:118px!important ; 
					height:48px!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.ep_chatsectioncolumnscrollarea021146{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.object.templatepageitem092{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepageitem091{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnscrollarea021{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumnscrollarea0210{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.row.rowcolumncolumnscrollarea02101{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.worldbuttonrowcolumncolumnscrollarea021011{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.worldbuttonrowcolumncolumnscrollarea0210112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.card.canvasBox{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.item.canvasContent{

				
				position:absolute !important; 
				
				
				
				
				

				
}

				.button.buttoncanvasBox5{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.scrollarea.scrollareacolumncolumnscrollarea02107{

			
			position:relative !important; 
			
			
			
			
			

				.row.rowscrollareacolumncolumnscrollarea021072{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.nivitemscrollareacolumncolumnscrollarea021071{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

			.row.rowcolumncolumnscrollarea02105{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.worldbuttonrowcolumncolumnscrollarea021051{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.row.rowrowcolumncolumnscrollarea021053{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.worldbuttonrowcolumncolumnscrollarea0210511{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.worldbuttonrowcolumncolumnscrollarea0210512{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.ep_chatsectioncolumnscrollarea021146{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.object.templatepageitem092{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepageitem091{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnscrollarea021{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumnscrollarea0210{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.row.rowcolumncolumnscrollarea02101{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.worldbuttonrowcolumncolumnscrollarea021011{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.worldbuttonrowcolumncolumnscrollarea0210112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.card.canvasBox{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.item.canvasContent{

				
				position:absolute !important; 
				
				
				
				
				

				
}

				.button.buttoncanvasBox5{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.scrollarea.scrollareacolumncolumnscrollarea02107{

			
			position:relative !important; 
			
			
			
			
			

				.row.rowscrollareacolumncolumnscrollarea021072{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.nivitemscrollareacolumncolumnscrollarea021071{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

			.row.rowcolumncolumnscrollarea02105{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.worldbuttonrowcolumncolumnscrollarea021051{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.row.rowrowcolumncolumnscrollarea021053{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.worldbuttonrowcolumncolumnscrollarea0210511{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.worldbuttonrowcolumncolumnscrollarea0210512{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.ep_chatsectioncolumnscrollarea021146{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.object.templatepageitem092{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepageitem091{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnscrollarea021{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumnscrollarea0210{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.row.rowcolumncolumnscrollarea02101{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.worldbuttonrowcolumncolumnscrollarea021011{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.worldbuttonrowcolumncolumnscrollarea0210112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.card.canvasBox{

			
			position:relative !important; 
			
			
			
			margin-left: 80px ; 
			
			margin-right: 80px ; 
			width: calc(100% - (80px + 80px)) !important;
			max-width: initial;min-width: initial;

				.item.canvasContent{

				
				position:absolute !important; 
				
				
				
				
				

				
}

				.button.buttoncanvasBox5{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.scrollarea.scrollareacolumncolumnscrollarea02107{

			
			position:relative !important; 
			
			
			
			margin-left: 80px ; 
			
			margin-right: 80px ; 
			width: calc(100% - (80px + 80px)) !important;
			

				.row.rowscrollareacolumncolumnscrollarea021072{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.nivitemscrollareacolumncolumnscrollarea021071{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

			.row.rowcolumncolumnscrollarea02105{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.worldbuttonrowcolumncolumnscrollarea021051{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.row.rowrowcolumncolumnscrollarea021053{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.worldbuttonrowcolumncolumnscrollarea0210511{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.worldbuttonrowcolumncolumnscrollarea0210512{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.ep_chatsectioncolumnscrollarea021146{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.object.templatepageitem092{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepageitem091{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnscrollarea021{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumnscrollarea0210{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.row.rowcolumncolumnscrollarea02101{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.worldbuttonrowcolumncolumnscrollarea021011{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.worldbuttonrowcolumncolumnscrollarea0210112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.card.canvasBox{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.item.canvasContent{

				
				position:absolute !important; 
				
				
				
				
				

				
}

				.button.buttoncanvasBox5{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.scrollarea.scrollareacolumncolumnscrollarea02107{

			
			position:relative !important; 
			
			
			
			
			

				.row.rowscrollareacolumncolumnscrollarea021072{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.nivitemscrollareacolumncolumnscrollarea021071{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

			.row.rowcolumncolumnscrollarea02105{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.worldbuttonrowcolumncolumnscrollarea021051{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.row.rowrowcolumncolumnscrollarea021053{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.worldbuttonrowcolumncolumnscrollarea0210511{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.worldbuttonrowcolumncolumnscrollarea0210512{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.ep_chatsectioncolumnscrollarea021146{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.object.templatepageitem092{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepageitem091{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columnscrollarea021{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.column.columncolumnscrollarea0210{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.row.rowcolumncolumnscrollarea02101{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.worldbuttonrowcolumncolumnscrollarea021011{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.worldbuttonrowcolumncolumnscrollarea0210112{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			.card.canvasBox{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.item.canvasContent{

				
				position:absolute !important; 
				
				
				
				
				

				
}

				.button.buttoncanvasBox5{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

			.scrollarea.scrollareacolumncolumnscrollarea02107{

			
			position:relative !important; 
			
			
			
			
			

				.row.rowscrollareacolumncolumnscrollarea021072{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.nivitemscrollareacolumncolumnscrollarea021071{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

			.row.rowcolumncolumnscrollarea02105{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.worldbuttonrowcolumncolumnscrollarea021051{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.row.rowrowcolumncolumnscrollarea021053{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.worldbuttonrowcolumncolumnscrollarea0210511{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.object.worldbuttonrowcolumncolumnscrollarea0210512{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.object.ep_chatsectioncolumnscrollarea021146{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
