<template>
 <div>  
<div id="TutorielDetails"> 
         
 <TemplatePage :class="'object templatepage0 '"   id="templatepage0" ref="templatepage0"  v-bind:style="{}" :currentPage = "1" 
>
 <template  v-slot:main>
 <div  :class="'slottemplate slottemplatetemplatepage01 '"   id="slottemplatetemplatepage01" ref="slottemplatetemplatepage01"  v-bind:style="{}">
 <div  :class="'column columncolumnscrollarea02101 '"   id="columncolumnscrollarea02101" ref="columncolumnscrollarea02101"  v-bind:style="{}">
 <Banner :class="'object herderBanner '"   id="herderBanner" ref="herderBanner"  v-bind:style="{}" :bannerImages = "mixedImagesList" 
 :backgroundColor = "'#6633b9'" 
 :titleList = "titleList" 
 :textList = "textList" 
>
</Banner>
 <v-row  :class="'grid gridcolumncolumnscrollarea02101 '"   id="gridcolumncolumnscrollarea02101" ref="gridcolumncolumnscrollarea02101"  v-bind:style="{}">
 <v-col  :class="'col colgridcolumncolumnscrollarea021011 '"   id="colgridcolumncolumnscrollarea021011" ref="colgridcolumncolumnscrollarea021011" v-for="(item,index) in tutorielList" :key="'colgridcolumncolumnscrollarea021011' + index"  v-bind:style="{}" :cols = "12" 
 :md = "4" 
 :sm = "6" 
><div class="col-container">
 <ChallengeItem :class="'object challengeitemcolgridcolumncolumnscrollarea0210111 '"   id="challengeitemcolgridcolumncolumnscrollarea0210111" ref="challengeitemcolgridcolumncolumnscrollarea0210111"  v-bind:style="{}" :title = "item.name" 
 :backImage = "item.description_image.url" 
 :video = "(item.videos.length > 0)" 
>
</ChallengeItem></div></v-col>  </v-row>
 <v-pagination 
 :color = "'#ff6b00'" 
 :length = "tutorielsCount" 
 :circle = "true" 
 :class="'pagination paginationcolumncolumnscrollarea021073 '"   id="paginationcolumncolumnscrollarea021073" ref="paginationcolumncolumnscrollarea021073" v-model="currentPage"  v-bind:style="{}"></v-pagination>  </div></div></template>
</TemplatePage>
 
      </div>
 </div> 
</template>
<script>
import TemplatePage from '../../At/Pages/TemplatePage'
import Banner from '../../At/Components/Banner'
import ChallengeItem from '../../At/Components/Challenges/ChallengeItem'

import * as utils from '../../Js/utils.js'
export default {
	name: 'TutorielDetails',

    components: {TemplatePage,Banner,ChallengeItem,},
	methods: {
	
		getTutoriel: function() { 		
	let filter2 = {

		tutoriel_category: this.tutorielCathID,

		_start: (this.currentPage - 1)* this.maxItemPerPage,

		_limit: this.maxItemPerPage

	}

	utils.getTutoriels(filter2)

	.then((response)=>{

		this.tutorielList = response.data.map(e => {

			e.description_image.url = this.$store.state.apiURL + e.description_image.url

			return e

		})

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
	},
	mounted(){
			
	// Get images from api

	utils.getImages()

	.then((response)=>{

	  this.imagesList = response.data.filter(e => {

			return (e.mime.match("image/") != null)

		});

		this.imagesList = this.imagesList.map(e => {

			e.url = this.$store.state.apiURL + e.url

			return e

		})

	})

	.catch((error)=>{

	  console.log(error);

	});

	

	this.tutorielCathID = this.$route.params.id

	

	// Get count of tutoriel with id tutorielCathID

	let filter = {

		tutoriel_category: this.tutorielCathID

	}

	utils.getCount('tutoriels', filter)

	.then((response) => {

		this.tutorielsCount = Math.ceil(response.data/this.maxItemPerPage)

	})

	.catch(error => console.log(error))

	

	// Get the list of tutoriels

	this.getTutoriel()

	},
	computed: {
	
		mixedImagesList: function(){		
	return utils.mixed(this.imagesList)

}
,
	},
	watch: {
	
		currentPage: function(newVal){ 		
	this.getChallenge()

}
,
	},
	data(){
	return {
		
		imagesList:  []		,
		titleList:  ["Des défis", "Des concours", "Des tutoriels"]		,
		textList:  [		
	"Résolvez des défis tous les jours et augmentez votre rang dans le classement",

	"Participez à des concours et gagnez des lots et prix.",

	"Entrenez vous et augmentez vos compétances graces à de nombreux tutoriels."	

]
,
		tutorielCathID:  0		,
		tutorielList:  []		,
		tutorielsCount:  0		,
		currentPage:  1		,
		maxItemPerPage:  6		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#TutorielDetails{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.object.templatepage0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
width:100px!important ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	width:auto!important ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:20px ; background-color: transparent ; 

		.object.herderBanner{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:300px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02101{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color: transparent ; 

			.col.colgridcolumncolumnscrollarea021011{

			
			position:relative !important; 
			margin: unset;
			transform-origin: top left;
			
			
			
			width:100px!important ; 
			height:292px!important ; 
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.challengeitemcolgridcolumncolumnscrollarea0210111{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-top: 0px ; 
				margin-right: 0px ; 
				margin-bottom: 0px ; 
				width: calc(100% - (0px + 0px)) !important;
				height: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea021073{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:36px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		}

	
}


}


}


@media (max-width: 600px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02101{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridcolumncolumnscrollarea021011{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.challengeitemcolgridcolumncolumnscrollarea0210111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea021073{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 601px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02101{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridcolumncolumnscrollarea021011{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.challengeitemcolgridcolumncolumnscrollarea0210111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea021073{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 961px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02101{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridcolumncolumnscrollarea021011{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.challengeitemcolgridcolumncolumnscrollarea0210111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea021073{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1265px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02101{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridcolumncolumnscrollarea021011{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.challengeitemcolgridcolumncolumnscrollarea0210111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea021073{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1905px) {


.object.templatepage0{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplatetemplatepage01{


position:relative !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.column.columncolumnscrollarea02101{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.object.herderBanner{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.grid.gridcolumncolumnscrollarea02101{

		
		position:relative !important; 
		
		
		
		
		

			.col.colgridcolumncolumnscrollarea021011{

			
			position:relative !important; 
			
			
			
			

			::v-deep .col-container{position: relative;

			height: 100%;

			width: 100%;

				.object.challengeitemcolgridcolumncolumnscrollarea0210111{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

			
}

		
}

		.pagination.paginationcolumncolumnscrollarea021073{

		
		position:relative !important; 
		
		
		
		
		}

	
}


}


}
}
}
</style>
