<template>
 <div>  
<div id="TopBar"> 
         
 <div  :class="'row item0 '"   id="item0" ref="item0"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'row rowitem08 '"   id="rowitem08" ref="rowitem08"  v-bind:style="{}">
 <router-link  :class="'routerlink routerlinkitem06 '"   id="routerlinkitem06" ref="routerlinkitem06"  v-bind:style="{}" :to = "'/home'" 
>
 <div  :class="'rectangle rectangle01 '"   id="rectangle01" ref="rectangle01"  v-bind:style="{backgroundColor : '#46505d',}">
 <div  :class="'text textrectangle01 '"   id="textrectangle01" ref="textrectangle01"  v-bind:style="{color : '#fff',}">
<div class="text-content">{{ 'abcCode' }}</div>

   </div>
 <div  :class="'rectangle rectanglerectangle03 '"   id="rectanglerectangle03" ref="rectanglerectangle03"  v-bind:style="{}">  </div>
 <div  :class="'rectangle rectanglerectangle033 '"   id="rectanglerectangle033" ref="rectanglerectangle033"  v-bind:style="{}">  </div>
 <div  :class="'rectangle rectanglerectangle034 '"   id="rectanglerectangle034" ref="rectanglerectangle034"  v-bind:style="{}">  </div>
 <div  :class="'rectangle rectanglerectangle0335 '"   id="rectanglerectangle0335" ref="rectanglerectangle0335"  v-bind:style="{}">  </div>  </div>  </router-link>
 <v-btn tag="div" 
 :color = "'#ff6b00'" 
 :fab = "true" 
 :dark = "true" 
 @click="showPanel" :class="'button buttonrowitem05542 '"   id="buttonrowitem05542" ref="buttonrowitem05542"  v-bind:style="{}"><span v-if = "(!true) || (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-menu' !== undefined"  :color = "'#fff'" 
>{{ 'ion-md-menu' }}
</v-icon></v-btn>
 <div  :class="'row rowitem033 '"   id="rowitem033" ref="rowitem033"  v-bind:style="{}">
 <ButtonA :class="'object buttonarowitem031 '"   id="buttonarowitem031" ref="buttonarowitem031"  v-bind:style="{}" :active = "(curentPage == 0)" 
 :href = "'/home'" 
>
</ButtonA>
 <ButtonA :class="'object buttonarowitem0312 '"   id="buttonarowitem0312" ref="buttonarowitem0312"  v-bind:style="{}" :text = "'Challenges'" 
 :active = "(curentPage == 1)" 
 :icon = "'ion-ios-flash'" 
 :href = "'/challenges'" 
>
</ButtonA>
 <ButtonA :class="'object buttonarowitem0313 '"   id="buttonarowitem0313" ref="buttonarowitem0313"  v-bind:style="{}" :text = "'Explorer'" 
 :active = "(curentPage == 2)" 
 :icon = "'ion-md-planet'" 
 :href = "'/explore'" 
>
</ButtonA>
 <ButtonB :class="'object buttonarowitem0314 '"   id="buttonarowitem0314" ref="buttonarowitem0314"  v-bind:style="{}" :click = "showLunch" 
 :text = "'Exécuter'" 
 :active = "(curentPage == 3)" 
 :icon = "'ion-ios-play'" 
>
</ButtonB>  </div>  </div>
 <div  :class="'row rowitem04 '"   id="rowitem04" ref="rowitem04"  v-bind:style="{}">
 <div  :class="'row rowitem05 '"   id="rowitem05" ref="rowitem05" v-if="loginSininVisible"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#ff6b00'" 
 :rounded = "true" 
 :dark = "true" 
 @click="showSignup" :class="'button buttonrowitem051 ' + 'textButton'"   id="buttonrowitem051" ref="buttonrowitem051"  v-bind:style="{}"><span>{{ 'S\' enregistre' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#656a6e'" 
 :rounded = "true" 
 :dark = "true" 
 @click="showLogin" :class="'button buttonrowitem0512 ' + 'textButton'"   id="buttonrowitem0512" ref="buttonrowitem0512"  v-bind:style="{}"><span>{{ 'Se connecter' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#656a6e'" 
 :fab = "true" 
 :onlyIcon = "true" 
 :dark = "true" 
 :class="'button buttonrowitem055 '"   id="buttonrowitem055" ref="buttonrowitem055"  v-bind:style="{}"><span v-if = "(!true) || (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-information-circle' !== undefined" >{{ 'ion-ios-information-circle' }}
</v-icon></v-btn>  </div>
 <div  :class="'row rowitem052 '"   id="rowitem052" ref="rowitem052" v-if="userInfoVisible"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'gray'" 
 :fab = "true" 
 :outlined = "true" 
 :onlyIcon = "true" 
 @click="showNotification" :class="'button notificationBtn '"   id="notificationBtn" ref="notificationBtn"  v-bind:style="{}"><span v-if = "(!true) || (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-notifications' !== undefined"  :color = "'gray'" 
>{{ 'ion-ios-notifications' }}
</v-icon></v-btn>
 <v-img  :class="'networkimage networkimagerowitem0523 '"   id="networkimagerowitem0523" ref="networkimagerowitem0523"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "userImage" 
>
 <div  :class="'mousearea mouseareanetworkimagerowitem05231 '"   id="mouseareanetworkimagerowitem05231" ref="mouseareanetworkimagerowitem05231"  v-bind:style="{}" @click="showUserInfo"></div>  </v-img>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import ButtonA from '../../At/Components/ButtonA'
import ButtonB from '../../At/Components/ButtonB'


export default {
	name: 'TopBar',

    components: {ButtonA,ButtonB,},
	computed: {
	
		loginSininVisible: function(){		
	return (this.userData? false : true)

}
,
		userInfoVisible: function(){		
	return !this.loginSininVisible

}
,
		userData: function(){ 		
	return this.$store.state.user;

}
,
		userImage: function(){		
	if(this.userData && this.userData.profile_image){

		return this.$store.state.apiURL + this.userData.profile_image.url

	} else {

		return "https://picsum.photos/200"

	}

}
,
	},
	props: {
	
		curentPage: {					required: false,			default: () =>  0,		},
		showLunch: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showPanel: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showSignup: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showLogin: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showNotification: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		showUserInfo: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#TopBar{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.textButton{
	padding: 10px !important;
}



.row.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
justify-content:space-between ; background-color:transparent ; 

.row.rowitem08{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



margin-top: 0px ; 
margin-bottom: 0px ; 
width:auto!important ; 
height: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:10px ; background-color:transparent ; 

	.routerlink.routerlinkitem06{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:80px!important ; 
	height:32px!important ; 
	

		.rectangle.rectangle01{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		background-color:#46505d ; 
		border-radius:4px ; 

			.text.textrectangle01{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			color:#fff ; 
			font-size:15px ; 
			text-align:center;
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

			.rectangle.rectanglerectangle03{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:2px ; 
			
			top: 2px ; 
			width:4px!important ; 
			height:4px!important ; 
			
			background-color:#ffffff ; 
			border-radius:2px ; 

			
}

			.rectangle.rectanglerectangle033{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:2px ; 
			
			bottom: 2px ; 
			width:4px!important ; 
			height:4px!important ; 
			
			background-color:#ffffff ; 
			border-radius:2px ; 

			
}

			.rectangle.rectanglerectangle034{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			top: 2px ; 
			right: 2px ; 
			width:4px!important ; 
			height:4px!important ; 
			
			background-color:#ffffff ; 
			border-radius:2px ; 

			
}

			.rectangle.rectanglerectangle0335{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			right: 2px ; 
			bottom: 2px ; 
			width:4px!important ; 
			height:4px!important ; 
			
			background-color:#ffffff ; 
			border-radius:2px ; 

			
}

		
}

	
}

	.button.buttonrowitem05542{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	 display:flex !important; 
	width:32px!important ; 
	height:32px!important ; 
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.row.rowitem033{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: -3px ; 
	margin-bottom: 0px ; 
	display:none !important; 
	width:auto!important ; 
	height: calc(100% - (-3px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:8px ; background-color:transparent ; 

		.object.buttonarowitem031{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		width:auto!important ; 
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0312{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		width:auto!important ; 
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0313{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		width:auto!important ; 
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0314{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		width:auto!important ; 
		height: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.row.rowitem04{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);


top: calc(50% + 0px) ; 
margin-right: 15px ; 
width:auto!important ; 
height:32px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;background-color:transparent ; 

	.row.rowitem05{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	display:none !important; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:8px ; background-color:transparent ; 

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:auto!important ; 
		height:32px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:auto!important ; 
		height:32px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

		.button.buttonrowitem055{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:32px!important ; 
		height:32px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.row.rowitem052{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:8px ; background-color:transparent ; 

		.button.notificationBtn{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:32px!important ; 
		height:32px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.networkimage.networkimagerowitem0523{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:32px!important ; 
		height:32px!important ; 
		
		display: inherit;

		flex: unset;
		border-radius:16px ; 
		border-style: solid ; 
		border-width:1px ; 
		border-color:undefined ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			.mousearea.mouseareanetworkimagerowitem05231{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			cursor: pointer;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.row.item0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.row.rowitem08{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.routerlink.routerlinkitem06{

	
	position:relative !important; 
	
	
	
	
	

		.rectangle.rectangle01{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectangle01{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectanglerectangle03{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle033{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle034{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle0335{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

		
}

	
}

	.button.buttonrowitem05542{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.row.rowitem033{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.buttonarowitem031{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0312{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0313{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0314{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.row.rowitem04{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowitem05{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem055{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.row.rowitem052{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.notificationBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.networkimage.networkimagerowitem0523{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.mousearea.mouseareanetworkimagerowitem05231{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.row.item0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.row.rowitem08{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.routerlink.routerlinkitem06{

	
	position:relative !important; 
	
	
	
	
	

		.rectangle.rectangle01{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectangle01{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectanglerectangle03{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle033{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle034{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle0335{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

		
}

	
}

	.button.buttonrowitem05542{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.row.rowitem033{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.buttonarowitem031{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0312{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0313{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0314{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.row.rowitem04{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowitem05{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem055{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.row.rowitem052{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.notificationBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.networkimage.networkimagerowitem0523{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.mousearea.mouseareanetworkimagerowitem05231{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.row.item0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.row.rowitem08{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.routerlink.routerlinkitem06{

	
	position:relative !important; 
	
	
	
	
	

		.rectangle.rectangle01{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectangle01{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectanglerectangle03{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle033{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle034{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle0335{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

		
}

	
}

	.button.buttonrowitem05542{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	display:none !important; 
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.row.rowitem033{

	
	position:relative !important; 
	
	
	
	
	 display:flex !important; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.buttonarowitem031{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0312{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0313{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0314{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.row.rowitem04{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowitem05{

	
	position:relative !important; 
	
	
	
	
	 display:flex !important; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem055{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.row.rowitem052{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.notificationBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.networkimage.networkimagerowitem0523{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.mousearea.mouseareanetworkimagerowitem05231{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.row.item0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.row.rowitem08{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.routerlink.routerlinkitem06{

	
	position:relative !important; 
	
	
	
	
	

		.rectangle.rectangle01{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectangle01{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectanglerectangle03{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle033{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle034{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle0335{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

		
}

	
}

	.button.buttonrowitem05542{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.row.rowitem033{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.buttonarowitem031{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0312{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0313{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0314{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.row.rowitem04{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowitem05{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem055{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.row.rowitem052{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.notificationBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.networkimage.networkimagerowitem0523{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.mousearea.mouseareanetworkimagerowitem05231{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.row.item0{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

.row.rowitem08{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.routerlink.routerlinkitem06{

	
	position:relative !important; 
	
	
	
	
	

		.rectangle.rectangle01{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectangle01{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectanglerectangle03{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle033{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle034{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.rectangle.rectanglerectangle0335{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

		
}

	
}

	.button.buttonrowitem05542{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.row.rowitem033{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.buttonarowitem031{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0312{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0313{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.buttonarowitem0314{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.row.rowitem04{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowitem05{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrowitem051{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem0512{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.buttonrowitem055{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}

	.row.rowitem052{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.notificationBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.networkimage.networkimagerowitem0523{

		
		position:relative !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.mousearea.mouseareanetworkimagerowitem05231{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}


}
}
}
</style>
