<template>
 <div>  
<div id="ChallengeCard"> 
         
 <router-link  :class="'routerlink routerlink0 '"   id="routerlink0" ref="routerlink0"  v-bind:style="{}" :to = "href" 
>
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0" :elevation = "2" 
  v-bind:style="{}">
 <v-img  :class="'networkimage imagecard01 '"   id="imagecard01" ref="imagecard01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "backImage" 
>  </v-img>
 <v-card 
 :class="'card topic '"   id="topic" ref="topic"  v-bind:style="{}">
 <div  :class="'text textcardcard031 '"   id="textcardcard031" ref="textcardcard031"  v-bind:style="{}">
<div class="text-content">{{ topic }}</div>

   </div>  </v-card>
 <v-card 
 :class="'card cardcard05 '"   id="cardcard05" ref="cardcard05"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagecardcard051 '"   id="networkimagecardcard051" ref="networkimagecardcard051"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "logoImage" 
>  </v-img>  </v-card>
 <div  :class="'rectangle rectanglecard07 '"   id="rectanglecard07" ref="rectanglecard07" v-if="showBrand"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'flow flowrectanglecard071 '"   id="flowrectanglecard071" ref="flowrectanglecard071"  v-bind:style="{}">
 <div  :class="'text textflowrectanglecard0711 '"   id="textflowrectanglecard0711" ref="textflowrectanglecard0711"  v-bind:style="{color : '#0bdeff',}">
<div class="text-content">{{ 'C' }}</div>

   </div>
 <div  :class="'text textflowrectanglecard07112 '"   id="textflowrectanglecard07112" ref="textflowrectanglecard07112"  v-bind:style="{color : '#cd3de7',}">
<div class="text-content">{{ 'L' }}</div>

   </div>
 <div  :class="'text textflowrectanglecard07113 '"   id="textflowrectanglecard07113" ref="textflowrectanglecard07113"  v-bind:style="{color : '#ff2081',}">
<div class="text-content">{{ 'U' }}</div>

   </div>
 <div  :class="'text textflowrectanglecard07114 '"   id="textflowrectanglecard07114" ref="textflowrectanglecard07114"  v-bind:style="{color : '#ffba0c',}">
<div class="text-content">{{ 'B' }}</div>

   </div>  </div>  </div>  </v-card>  </router-link>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ChallengeCard',

    components: {},
	props: {
	
		topic: {					required: false,			default: () =>  "loop &#129409;",		},
		backImage: {					required: false,			default: () =>  "https://picsum.photos/500",		},
		logoImage: {					required: false,			default: () =>  "https://picsum.photos/200",		},
		href: {					required: false,			default: () =>  "",		},
		showBrand: {			type: Boolean,			required: false,			default: () =>  true,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ChallengeCard{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

#topic{
	padding-left: 12px;
	padding-right: 12px;
}

#card0{
	&:hover{
    transform: scale(1.01);
	}
}
	
#topic{
	padding: 0 10px !important;
}



.routerlink.routerlink0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
background-color:#ffffff ; 
border-radius:8px ; max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	border-radius:8px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}

	.card.topic{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 20px ; 
	
	bottom: 20px ; 
	width:auto!important ; 
	height:42px!important ; 
	background-color:#ffffff ; 
	border-radius:1000px ; max-width: initial;min-width: initial;

		.text.textcardcard031{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
		
		left: calc(50% + 0px) ; 
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:auto!important ; 
		
		font-size:16px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

	
}

	.card.cardcard05{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	right: 20px ; 
	bottom: 20px ; 
	width:60px!important ; 
	height:60px!important ; 
	background-color:#ffffff ; 
	border-radius:8px ; max-width: initial;min-width: initial;

		.networkimage.networkimagecardcard051{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 5px ; 
		
		top: 5px ; 
		right: 5px ; 
		bottom: 5px ; 
		
		display: inherit;

		flex: unset;
		border-radius:8px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: -5px ; 
	right: 20px ; 
	width:48px!important ; 
	height:60px!important ; 
	
	background-color:#000 ; 
	border-radius:8px ; 

		.flow.flowrectanglecard071{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 5px ; 
		
		top: 10px ; 
		right: 5px ; 
		bottom: 5px ; 
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;background-color:transparent ; 

			.text.textflowrectanglecard0711{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			margin-top: 0px ; 
			width:50%!important ; 
			height:50%!important ; 
			
			color:#0bdeff ; 
			font-size:20px ; 
			text-align:center;
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

			.text.textflowrectanglecard07112{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			margin-top: 0px ; 
			width:50%!important ; 
			height:50%!important ; 
			
			color:#cd3de7 ; 
			font-size:20px ; 
			text-align:center;
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

			.text.textflowrectanglecard07113{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			margin-top: 0px ; 
			width:50%!important ; 
			height:50%!important ; 
			
			color:#ff2081 ; 
			font-size:20px ; 
			text-align:center;
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

			.text.textflowrectanglecard07114{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			margin-top: 0px ; 
			width:50%!important ; 
			height:50%!important ; 
			
			color:#ffba0c ; 
			font-size:20px ; 
			text-align:center;
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			font-weight: bold;
			}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.routerlink.routerlink0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.card.topic{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.text.textcardcard031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.card.cardcard05{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.networkimagecardcard051{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	
	
	
	
	
	

		.flow.flowrectanglecard071{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textflowrectanglecard0711{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07112{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07114{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.routerlink.routerlink0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.card.topic{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.text.textcardcard031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.card.cardcard05{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.networkimagecardcard051{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	
	
	
	
	
	

		.flow.flowrectanglecard071{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textflowrectanglecard0711{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07112{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07114{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.routerlink.routerlink0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.card.topic{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.text.textcardcard031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.card.cardcard05{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.networkimagecardcard051{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	
	
	
	
	
	

		.flow.flowrectanglecard071{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textflowrectanglecard0711{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07112{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07114{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.routerlink.routerlink0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.card.topic{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.text.textcardcard031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.card.cardcard05{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.networkimagecardcard051{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	
	
	
	
	
	

		.flow.flowrectanglecard071{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textflowrectanglecard0711{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07112{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07114{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.routerlink.routerlink0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imagecard01{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.card.topic{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.text.textcardcard031{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.card.cardcard05{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.networkimagecardcard051{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.rectangle.rectanglecard07{

	
	position:absolute !important; 
	
	
	
	
	
	

		.flow.flowrectanglecard071{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.textflowrectanglecard0711{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07112{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07113{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.textflowrectanglecard07114{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}


}
}
}
</style>
