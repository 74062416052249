<template>
 <div>  
<div id="LunchBar"> 
         
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0" :elevation = "2" 
  v-bind:style="{}">
 <div  :class="'row rowcard02 '"   id="rowcard02" ref="rowcard02"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'row rowrowcard0213 '"   id="rowrowcard0213" ref="rowrowcard0213"  v-bind:style="{justifyContent : 'space-around',}">
 <AppCard :class="'object worldCard '"   id="worldCard" ref="worldCard"  v-bind:style="{}" :bannerAction = "goToStore" 
 :title = "'Partager vos codes'" 
 :text = "'abcCode World'" 
 :bgImg = "'Resources/background/0112419d011ec5139aa01521350ec49a.png'" 
>
</AppCard>
 <AppCard :class="'object codeCard '"   id="codeCard" ref="codeCard"  v-bind:style="{}" :bannerAction = "goToCode" 
 :bgColor = "'#7e5fec'" 
 :bgImg = "'Resources/background/confetis.png'" 
 :title = "'Coder et creer en ligne'" 
 :text = "'abcCode online'" 
>
</AppCard>  </div>
 <div  :class="'rectangle rectanglecolgridcard01512 '"   id="rectanglecolgridcard01512" ref="rectanglecolgridcard01512"  v-bind:style="{backgroundColor : '#7c7d7d',}">
 <div  :class="'rectangle rectanglerectanglecolgridcard015121 '"   id="rectanglerectanglecolgridcard015121" ref="rectanglerectanglecolgridcard015121"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'text textrectanglerectanglecolgridcard0151211 '"   id="textrectanglerectanglecolgridcard0151211" ref="textrectanglerectanglecolgridcard0151211"  v-bind:style="{color : '#838383',}">
<div class="text-content">{{ 'Club' }}</div>

   </div>  </div>  </div>
 <div  :class="'row rowrowcard02133 '"   id="rowrowcard02133" ref="rowrowcard02133"  v-bind:style="{justifyContent : 'space-around',}">
 <AppCard :class="'object helpCard '"   id="helpCard" ref="helpCard"  v-bind:style="{}" :bannerAction = "goToHelp" 
 :bgColor = "'#56445D'" 
 :bgImg = "'Resources/background/0112419d011ec5139aa01521350ec49a.png'" 
 :title = "'Lire la documentation'" 
 :text = "'abcCode aide'" 
>
</AppCard>
 <AppCard :class="'object siteCard '"   id="siteCard" ref="siteCard"  v-bind:style="{}" :bannerAction = "goToSite" 
 :bgColor = "'#27BBAF'" 
 :bgImg = "'Resources/background/confetti.png'" 
 :title = "'Visitez notre site web'" 
 :text = "'abcCode web site'" 
>
</AppCard>  </div>  </div>  </v-card>
 
      </div>
 </div> 
</template>
<script>
import AppCard from '../../At/Components/AppCard'


export default {
	name: 'LunchBar',

    components: {AppCard,},
	methods: {
	
		goToCode: function(){ 		
	window.open(this.$store.state.codeURL, '_blank').focus()

}
,
		goToHelp: function(){ 		
	window.open(this.$store.state.helpURL, '_blank').focus()

}
,
		goToSite: function(){ 		
	window.open(this.$store.state.webURL, '_blank').focus()

}
,
		goToStore: function(){ 		
	window.open('https://world.abccode.org/', '_blank').focus()

}
,
	},
} 
</script>

<style lang = "scss" scoped>


 div#LunchBar{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.card.card0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:118px!important ; 
width: calc(100% - (0px + 0px)) !important;
background-color:#ffffff ; 
border-radius:0px ; max-width: initial;min-width: initial;

.row.rowcard02{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);

left: 10px ; 

top: calc(50% + 0px) ; 
right: 10px ; 
height:95px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
justify-content:space-between ; background-color:transparent ; 

	.row.rowrowcard0213{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:45%!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; 
	justify-content:space-around ; background-color:transparent ; 

		.object.worldCard{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:92px!important ; 
		height:92px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.codeCard{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:92px!important ; 
		height:92px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolgridcard01512{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:1px!important ; 
	height:100%!important ; 
	
	background-color:#7c7d7d ; 

		.rectangle.rectanglerectanglecolgridcard015121{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
		
		left: calc(50% + 0px) ; 
		
		top: calc(50% + 0px) ; 
		width:45px!important ; 
		height:24px!important ; 
		
		background-color:#fff ; 
		border-radius:12px ; 
		border-style:solid ; 
		border-width:2px ; 
		border-color:#ae4cff ; 

			.text.textrectanglerectanglecolgridcard0151211{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			color:#838383 ; 
			font-size:15px ; 
			text-align:center;
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcard02133{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:45%!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; 
	justify-content:space-around ; background-color:transparent ; 

		.object.helpCard{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:92px!important ; 
		height:92px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.siteCard{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:92px!important ; 
		height:92px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.card.card0{


position:relative !important; 




height:80px!important ; 
max-width: initial;min-width: initial;

.row.rowcard02{


position:absolute !important; 




height:71px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowrowcard0213{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldCard{

		
		position:relative !important; 
		
		
		
		
		width:70px!important ; 
		height:70px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.codeCard{

		
		position:relative !important; 
		
		
		
		
		width:70px!important ; 
		height:70px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolgridcard01512{

	
	position:relative !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglecolgridcard015121{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectanglerectanglecolgridcard0151211{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcard02133{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.helpCard{

		
		position:relative !important; 
		
		
		
		
		width:70px!important ; 
		height:70px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.siteCard{

		
		position:relative !important; 
		
		
		
		
		width:70px!important ; 
		height:70px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

.row.rowcard02{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowrowcard0213{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.codeCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolgridcard01512{

	
	position:relative !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglecolgridcard015121{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectanglerectanglecolgridcard0151211{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcard02133{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.helpCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.siteCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.card.card0{


position:relative !important; 




height:254px!important ; 
max-width: initial;min-width: initial;

.row.rowcard02{


position:absolute !important; 



left: 100px ; 

right: 100px ; 
height:212px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowrowcard0213{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldCard{

		
		position:relative !important; 
		
		
		
		
		width:200px!important ; 
		height:212px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.codeCard{

		
		position:relative !important; 
		
		
		
		
		width:200px!important ; 
		height:212px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolgridcard01512{

	
	position:relative !important; 
	
	
	
	
	height:200px!important ; 
	
	

		.rectangle.rectanglerectanglecolgridcard015121{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectanglerectanglecolgridcard0151211{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcard02133{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.helpCard{

		
		position:relative !important; 
		
		
		
		
		width:200px!important ; 
		height:212px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.siteCard{

		
		position:relative !important; 
		
		
		
		
		width:200px!important ; 
		height:212px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

.row.rowcard02{


position:absolute !important; 



left: 16% ; 

right: 16% ; 

display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowrowcard0213{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.codeCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolgridcard01512{

	
	position:relative !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglecolgridcard015121{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectanglerectanglecolgridcard0151211{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcard02133{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.helpCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.siteCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.card.card0{


position:relative !important; 




max-width: initial;min-width: initial;

.row.rowcard02{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.row.rowrowcard0213{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.codeCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.rectangle.rectanglecolgridcard01512{

	
	position:relative !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglecolgridcard015121{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectanglerectanglecolgridcard0151211{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowrowcard02133{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.helpCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.siteCard{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
