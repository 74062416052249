<template>
 <div>  
<div id="WorldButton"> 
         
 <div  :class="'rectangle rectangle0 '"   id="rectangle0" ref="rectangle0"  v-bind:style="{backgroundColor : textColor,}">
 <div  :class="'rectangle rectangle01 '"   id="rectangle01" ref="rectangle01"  v-bind:style="{backgroundColor : backgroundColor,borderWidth : getUnit(border,'px'),borderColor : color, borderStyle:'solid' ,  }">
 <div  :class="'icon iconrectangle031 '"   id="iconrectangle031" ref="iconrectangle031"  v-bind:style="{color : color,fontSize : getUnit(25,'px'),}"><i  :class="iconName"></i></div>
 <div  :class="'text textrectangle052 '"   id="textrectangle052" ref="textrectangle052"  v-bind:style="{color : textColor,}">
<div class="text-content">{{ value }}</div>

   </div>  </div>
 <div  :class="'mousearea mousearearectangle07 '"   id="mousearearectangle07" ref="mousearearectangle07" v-on:mouseover="hover"  v-on:mouseout="hoverOut"  v-ripple = "true"  v-bind:style="{}" @click="click"></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'WorldButton',

    components: {},
	methods: {
	
		hover: function() { 		
	this.hovered = true;

}
,
		hoverOut: function() { 		
	this.hovered = false;

}
,
	},
	computed: {
	
		color: function() { 		
	if(this.hovered){

		return this.hoverColor

	} else {

		return this.textColor

	}

}
,
		border: function() { 		
	if(this.hovered){

		return 1

	} else {

		return 0

	}

}
,
	},
	data(){
	return {
		
		hovered:  false		,
		}
	},
	props: {
	
		iconName: {					required: false,			default: () =>  "ion-ios-heart-empty",		},
		value: {					required: false,			default: () =>  "value",		},
		hoverColor: {					required: false,			default: () =>  "#ff6b00",		},
		click: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		backgroundColor: {					required: false,			default: () =>  "#fff",		},
		textColor: {					required: false,			default: () =>  "#9d9da3",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#WorldButton{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

#rectangle0{
    overflow: hidden;
}



.rectangle.rectangle0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ffffff ; 
border-radius:1000px ; 

.rectangle.rectangle01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 1px ; 

top: 1px ; 
right: 1px ; 
bottom: 4px ; 

background-color:#ffffff ; 
border-radius:1000px ; 

	.icon.iconrectangle031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left:10px ; 
	
	top: calc(50% + 0px) ; 
	width:25px!important ; 
	height:25px!important ; 
	
	font-size:25px ; 
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrectangle052{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left: 40px ; 
	
	top: calc(50% + 0px) ; 
	right: 15px ; 
	height:auto!important ; 
	
	font-size:18px ; 
	text-align:center;
	align-items:center;
	display: flex;
	text-overflow: ellipsis ; 
	.text-content{
	width: 100%;
	white-space: nowrap;
	overflow: hidden ;
	text-overflow: ellipsis ; 
	font-weight: bold;
	}

	
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.rectangle.rectangle0{


position:absolute !important; 







.rectangle.rectangle01{


position:absolute !important; 







	.icon.iconrectangle031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrectangle052{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.rectangle.rectangle0{


position:absolute !important; 







.rectangle.rectangle01{


position:absolute !important; 







	.icon.iconrectangle031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrectangle052{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.rectangle.rectangle0{


position:absolute !important; 







.rectangle.rectangle01{


position:absolute !important; 







	.icon.iconrectangle031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrectangle052{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.rectangle.rectangle0{


position:absolute !important; 







.rectangle.rectangle01{


position:absolute !important; 







	.icon.iconrectangle031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrectangle052{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.rectangle.rectangle0{


position:absolute !important; 







.rectangle.rectangle01{


position:absolute !important; 







	.icon.iconrectangle031{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}

	.text.textrectangle052{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 





}


}
}
}
</style>
