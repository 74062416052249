<template>
 <div>  
<div id="UserTile"> 
         
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0"  v-bind:style="{}">
 <div  :class="'column columncard02 '"   id="columncard02" ref="columncard02"  v-bind:style="{justifyContent : 'space-around',}">
 <v-img  :class="'networkimage networkimagecard014 '"   id="networkimagecard014" ref="networkimagecard014"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "transformedUserImg" 
>
 <div  :class="'mousearea mouseareanetworkimagecard0141 '"   id="mouseareanetworkimagecard0141" ref="mouseareanetworkimagecard0141"  v-bind:style="{}" @click="seeProfile"></div>  </v-img>
 <div  :class="'column columncolumncard023 '"   id="columncolumncard023" ref="columncolumncard023"  v-bind:style="{}">
 <div  :class="'text nom '"   id="nom" ref="nom"  v-bind:style="{}">
<div class="text-content">{{ userName }}</div>

   </div>
 <div  :class="'text level '"   id="level" ref="level"  v-bind:style="{color : '#9f9f9f',}">
<div class="text-content">{{ 'Niveau ' + userLevel }}</div>

   </div>  </div>
 <div  :class="'rectangle rectangle '"   id="rectangle" ref="rectangle" v-if="followedMode"  v-bind:style="{backgroundColor : btnColor,}">
 <div  :class="'icon iconrectangle1 '"   id="iconrectangle1" ref="iconrectangle1"  v-bind:style="{color : '#fff',fontSize : getUnit(20,'px'),}"><i  :class="'ion-md-person'"></i></div>
 <div  :class="'icon iconrectangle12 '"   id="iconrectangle12" ref="iconrectangle12"  v-bind:style="{color : '#fff',fontSize : getUnit(20,'px'),}"><i  :class="btnSecondIcon"></i></div>
 <div  :class="'mousearea mousearearectangle5 '"   id="mousearearectangle5" ref="mousearearectangle5" v-on:mouseover="hoverIn"  v-on:mouseout="hoverOut"  v-on:click="unfollow"   v-bind:style="{}"></div>  </div>  </div>  </v-card>
 
      </div>
 </div> 
</template>
<script>

import * as utils from '../../../Js/utils.js'
export default {
	name: 'UserTile',

    components: {},
	methods: {
	
		hoverIn: function(){ 		
	this.btnColor = "red"

	this.btnSecondIcon = "ion-md-close"

}
,
		hoverOut: function(){ 		
	this.btnColor = "#88c440"

	this.btnSecondIcon = "ion-md-checkmark"

}
,
		seeProfile: function(){ 		
	window.open(this.$store.state.server + '/#/user/' + this.userID)

}
,
		unfollow: function(){		
	utils.unfollow(this.userID)

	.then((response)=>{

		this.getFollowed()

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
	},
	computed: {
	
		transformedUserImg: function() { 		
	return this.$store.state.apiURL + this.userImage

}
,
	},
	data(){
	return {
		
		btnColor:  "#88c440"		,
		btnSecondIcon:  "ion-md-checkmark"		,
		}
	},
	props: {
	
		userImage: {					required: false,			default: () =>  "https://picsum.photos/200",		},
		userName: {					required: false,			default: () =>  "...",		},
		userLevel: {					required: false,			default: () =>  0,		},
		userID: {					required: false,			default: () =>  null,		},
		getFollowed: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		followedMode: {					required: false,			default: () =>  true,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#UserTile{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
background-color:#ffffff ; 
border-radius:8px ; max-width: initial;min-width: initial;

.column.columncard02{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 15px ; 

top: 20px ; 
right: 15px ; 
bottom: 20px ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
justify-content:space-around ; background-color: transparent ; 

	.networkimage.networkimagecard014{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	width:88px!important ; 
	height:88px!important ; 
	
	display: inherit;

	flex: unset;
	border-radius:44px ; 
	border-style: solid ; 
	border-width:1px ; 
	border-color:#6d6d6d ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		.mousearea.mouseareanetworkimagecard0141{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		cursor: pointer;
		}

	
}

	.column.columncolumncard023{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:4px ; background-color: transparent ; 

		.text.nom{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:18px ; 
		text-align:center;
		display: flex;
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		font-weight: bold;
		}

		
}

		.text.level{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#9f9f9f ; 
		font-size:14px ; 
		text-align:center;
		display: flex;
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		font-weight: bold;
		}

		
}

	
}

	.rectangle.rectangle{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	width:48px!important ; 
	height:40px!important ; 
	
	background-color:#ffffff ; 
	border-radius:20px ; 

		.icon.iconrectangle1{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		color:#fff ; 
		font-size:20px ; 
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.icon.iconrectangle12{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		top: 0px ; 
		right: 0px ; 
		width:28px!important ; 
		height:28px!important ; 
		
		color:#fff ; 
		font-size:20px ; 
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.mousearea.mousearearectangle5{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		cursor: pointer;
		}

	
}


}


}


@media (max-width: 600px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard02{


position:absolute !important; 









	.networkimage.networkimagecard014{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.mousearea.mouseareanetworkimagecard0141{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.column.columncolumncard023{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.nom{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.level{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectangle{

	
	position:relative !important; 
	
	
	
	
	
	

		.icon.iconrectangle1{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.icon.iconrectangle12{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.mousearea.mousearearectangle5{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 601px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard02{


position:absolute !important; 









	.networkimage.networkimagecard014{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.mousearea.mouseareanetworkimagecard0141{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.column.columncolumncard023{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.nom{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.level{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectangle{

	
	position:relative !important; 
	
	
	
	
	
	

		.icon.iconrectangle1{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.icon.iconrectangle12{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.mousearea.mousearearectangle5{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 961px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard02{


position:absolute !important; 









	.networkimage.networkimagecard014{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.mousearea.mouseareanetworkimagecard0141{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.column.columncolumncard023{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.nom{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.level{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectangle{

	
	position:relative !important; 
	
	
	
	
	
	

		.icon.iconrectangle1{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.icon.iconrectangle12{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.mousearea.mousearearectangle5{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1265px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard02{


position:absolute !important; 









	.networkimage.networkimagecard014{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.mousearea.mouseareanetworkimagecard0141{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.column.columncolumncard023{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.nom{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.level{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectangle{

	
	position:relative !important; 
	
	
	
	
	
	

		.icon.iconrectangle1{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.icon.iconrectangle12{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.mousearea.mousearearectangle5{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1905px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.column.columncard02{


position:absolute !important; 









	.networkimage.networkimagecard014{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.mousearea.mouseareanetworkimagecard0141{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.column.columncolumncard023{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.nom{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.level{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.rectangle{

	
	position:relative !important; 
	
	
	
	
	
	

		.icon.iconrectangle1{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.icon.iconrectangle12{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		justify-content: center;
		align-items: center;
		i {
		}
}

		.mousearea.mousearearectangle5{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}


}
}
}
</style>
