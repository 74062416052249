import languages from "./language.json";
import Debug from "./debuger.json";
import Comp from "./compiler.json";
import mapKey from "./mapKey.json";
import store from "../../store/index";
import { generateStage, generateCode } from "./template";

var stringSimilarity = require("string-similarity");
import RandExp from "randexp";

let conso;
let stageNumber = 1;
let lineNumber = 0;
let currentLanguage = "fr";
let errorMap = {};
let currentProj = null;
let totalAsset = [];

let ruleList = {
  catchName: {
    fr: /^\s*(\w+) est une?/,
    en: /^\s*(\w+) is an?/,
  },
  catchNameType: {
    fr: /^\s*(\w+) est une? (\w+)/,
    en: /^\s*(\w+) is an? (\w+)/,
  },
  checkManipulation: {
    fr: /^\s*([a-zA-Z]\w+) /,
    en: /^\s*([a-zA-Z]\w+) /
  }
};

export function compileFunction(cp, cLang, curStage) {
  currentProj = cp;
  currentLanguage = cLang;
  errorMap = {};

  var checkPoint = /(\.)$/;
  var finalCode = "";

  conso = { check: "", alert: "" };
  let serverURL = store.state.server + "/";

  let captureRule1 = /(\w{2,}:\s*)+/gm;
  let captureRule2 = /[^\s]+|\s/gm;

  conso.alerted = false;

  // Loop over the stage
  for (var i = 1; i <= 20; i++) {
    var stageCode = "";
    var code = currentProj.code["stage" + i];
    let nameList = [];
    let visitedLine = [];
    stageNumber = i;
    lineNumber = 0;

    // Replace all accentued characters
    code = deleteAccent(code);

    // Check if the basic structure is respected
    if (
      code.match(
        new RegExp(Debug.vide.subRules["basic"][currentLanguage], "gmi")
      ) === null
    ) {
      throwAlert(languages.notBasicMessage[currentLanguage] + i);
      analyseError(
        Debug.vide.subRules["basic"][currentLanguage].match(captureRule1),
        code
      );
      break;
    } else {
      var lignes = code.split(/\n/g);

      for (var j = 0; j < lignes.length; j++) {
        if (visitedLine[j]) {
          stageCode += lignes[j] + "\n";
          continue;
        }
        if (lignes[j].match(/^\s*$/gm)) {
          continue;
        }

        lineNumber = j;
        let lastSimRule = "";
        let lastSimRuleNote = 0;

        var ligne = lignes[j];
        var rulerKey = -1;

        // =================================================================[ Debug ]
        for (let key in Debug) {
          if (checkRule(ligne, key, currentLanguage)) {
            // If the rule don't match, calculate the similarity and check if it is the best

            if (ligne.length > 0) {
              var similarity = stringSimilarity.compareTwoStrings(
                getRandomReg(Debug[key][currentLanguage]),
                ligne
              );

              if (
                similarity > lastSimRuleNote &&
                Debug[key][currentLanguage].length > 0
              ) {
                lastSimRuleNote = similarity;
                lastSimRule = Debug[key][currentLanguage];
              }
            }
          } else {
            rulerKey = key;
            break;
          }
        }

        if (rulerKey == -1 || rulerKey == "vide") {
          if (ligne.match(/^(\s)*?$/g) == null) {
            throwAlert("");

            analyseError(lastSimRule.match(captureRule2), ligne);
            break;
          } else {
          }
        } else {
          var rular = Debug[rulerKey];
          var next = rular.next;
          var required = rular.required;
          let number = rular.number;

          // Check the Number
          if (number !== undefined) {
            if (!checkNumber(ligne, rulerKey, j)) {
              break;
            }
          }

          // Check if it is a declaration. If yes check if the name is already used. If not save the name of the object
          let nameCheck = ligne.match(ruleList.catchName[currentLanguage]);
          if (nameCheck) {
            if (nameList.includes(nameCheck[1])) {
              throwAlert(
                "\n\t\t○\t" +
                  languages.alreadyUsed[currentLanguage]
                    .replace("{{number}}", j + 1)
                    .replace("{{name}}", nameCheck[1])
              );
              break;
            } else {
              nameList.push(nameCheck[1]);
            }
          } else {
            // Check if it is a manipulation of element
            let nameCheck = ligne.match(ruleList.checkManipulation[currentLanguage])
            if(nameCheck){
              //Check if the element exist
              if (nameList.includes(nameCheck[1])){

              }
            }
          }

          //   Check if there are rule that need to obligatory follow the current rule
          if (next) {
            var rulerIndex2 = 0;
            lastSimRuleNote = 0;
            lastSimRule = null;

            while (
              rulerIndex2 < next.length &&
              checkNextRule(next, ligne, rulerIndex2)
            ) {
              let currentRule = getRule(next[rulerIndex2]);

              if (ligne.length > 0) {
                var similarity = stringSimilarity.compareTwoStrings(
                  getRandomReg(currentRule[currentLanguage]),
                  ligne
                );
                if (
                  similarity > lastSimRuleNote &&
                  currentRule[currentLanguage].length > 0
                ) {
                  lastSimRuleNote = similarity;
                  lastSimRule = currentRule[currentLanguage];
                }
              }
              rulerIndex2++;
            }
            if (rulerIndex2 == next.length) {
              throwAlert(
                "\n\t\t○\t" +
                  languages.notLine[currentLanguage].replace("Num", j + 1)
              );

              analyseError(lastSimRule.match(captureRule2), ligne);
              break;
            }
          }

          //   Check if there are rule that need to obligatory follow the current rule
          if (required !== undefined) {
            var rulerIndex3 = 0;
            let stop = false;
            let closureIndex = lignes.length;

            while (rulerIndex3 < required.length) {
              var lineIndex = parseInt(j);
              let currentRule = getRule(required[rulerIndex3]);

              while (lineIndex < closureIndex) {
                let checkedLigne = lignes[lineIndex];
                let check = !checkNextRule(required, checkedLigne, rulerIndex3);

                if (check) {
                  let output = traduct(
                    currentRule,
                    checkedLigne,
                    currentRule.nom,
                    lineIndex
                  );

                  if (output.stop) {
                    stop = true;
                    break;
                  }

                  lignes[lineIndex] = output.result;

                  // If it is a mutiline comment
                  if (
                    rulerKey == "multiComment" &&
                    currentRule.nom == "endComment"
                  ) {
                    for (let k = j + 1; k <= lineIndex; k++) {
                      visitedLine[k] = true;
                    }
                  } else {
                    visitedLine[lineIndex] = true;
                  }

                  if (currentRule.closure) {
                    break;
                  }
                }
                lineIndex++;
              }

              if (currentRule.required && lineIndex === closureIndex) {
                throwAlert(
                  "\n\t\t○,\t" +
                    languages.required[currentLanguage]
                      .replace("{{number}}", j + 1)
                      .replace("{{rule}}", getRandomReg(rulerKey))
                      .replace(
                        "{{ruleNext}}",
                        getRandomReg(currentRule[currentLanguage])
                      )
                );
                stop = true;
                break;
              }

              if (currentRule.closure) {
                closureIndex = parseInt(lineIndex);
              }
              rulerIndex3++;
            }

            if (stop) {
              break;
            }
          }

          //==================================================[ Traduction ]==============

          let output = traduct(rular, ligne, rulerKey, j);

          if (output.stop) {
            break;
          }
          stageCode += output.result + "\n";
        }
      }

      // Filter the list of assets
      totalAsset = totalAsset.filter((e, n) => totalAsset.indexOf(e) === n);
    }
    finalCode += generateStage(i, stageCode);
    conso.check += languages.checked[currentLanguage].replace("{{NUM}}", i);
  }
  //----------------------------------------------------------------------------------------------------------------
  var finalHTML = generateCode(curStage, finalCode, totalAsset);
  conso.code = finalHTML;

  // Generate alert messages
  generateAlert();
  return { final: finalHTML, console: conso };
}

// Traduct the code
function traduct(rular, ligne, rulerKey, lineNumber) {
  var next = rular.next;

  let output = {
    result: "",
    stop: false,
  };

  // Collect Assets
  if (Comp[rulerKey].path) {
    let path = Comp[rulerKey].path;
    let matchs = ligne.match(new RegExp(Comp[rulerKey][currentLanguage]));

    for (let i = 1; i < matchs.length; i++) {
      path = path.replace(`$${i}`, matchs[i]);
    }
    totalAsset.push(path);
  }

  if (
    rulerKey == "personnageImporteX" ||
    rulerKey == "animationImporteeX" ||
    rulerKey == "imageImporteeX" ||
    rulerKey == "musiqueImporteeX"
  ) {
    let matchedIndex = parseInt(
      ligne.match(new RegExp(Comp[rulerKey][currentLanguage]))[1]
    );

    let mapType = {
      personnageImporteX: "personnage",
      animationImporteeX: "animation",
      imageImporteeX: "image",
      musiqueImporteeX: "song",
    };

    let importKey = 0;
    for (
      importKey = 0;
      importKey < currentProj.imported[mapType[rulerKey]].length;
      importKey++
    ) {

      let currId = currentProj.imported[mapType[rulerKey]][importKey].id
      if (currId == matchedIndex) {
        let path = currentProj.imported[mapType[rulerKey]][importKey].url;

        ligne = ligne.replace(
          new RegExp(Comp[rulerKey][currentLanguage]),
          Comp[rulerKey].value.replace("{{path}}", path)
        );

        totalAsset.push(path);
        break;
      }
    }

    if (importKey == currentProj.imported[mapType[rulerKey]].length) {
      throwAlert(
        "\n\t\t○\t" +
          languages.notImported[currentLanguage]
            .replace("{{number}}", lineNumber + 1)
            .replace("{{type}}", mapType[rulerKey])
            .replace("{{key}}", matchedIndex)
      );
      output.stop = true;
      return output;
    }
  } else {
    ligne = ligne.replace(
      new RegExp(Comp[rulerKey][currentLanguage]),
      Comp[rulerKey].value
    );
  }

  if (next !== undefined) {
    for (var z = 0; z < next.length; z++) {
      let rule = next[z];
      let ruleName = next[z];

      if (typeof rule == "string") {
        rule = Debug.vide.subRules[rule];
      } else {
        ruleName = rule.nom;
      }

      ligne = ligne.replace(
        new RegExp(Comp[ruleName][currentLanguage], "g"),
        Comp[ruleName].value
      );
    }
  }

  if (
    ligne.match(
      new RegExp(Debug.vide.subRules["estUn"][currentLanguage], "")
    ) !== null
  ) {
    ligne = ligne.replace(
      new RegExp(Comp.estUn[currentLanguage]),
      Comp.estUn.value
    );
  }

  output.result = ligne;
  return output;
}

function analyseError(rules, code) {
  // If it is not the basic ruel
  if (code.split(/\n/).length == 1) {
    // Check if it is a declaration of variable
    let decCheck = code.match(ruleList.catchNameType[currentLanguage]);
    if (decCheck) {
      throwAlert(
        "\n\t\t○\t" +
          languages.unDefinedType[currentLanguage]
            .replace("{{type}}", decCheck[2])
            .replace("{{name}}", decCheck[1])
      );
    }
  }
  if (rules) {
    for (let i = 0; i < rules.length; i++) {
      let checkIfItIsStarter = i == 0 ? "" : "^";
      let rule = new RegExp(checkIfItIsStarter + rules[i]);
      let check = code.match(rule);
      if (check == null) {
        throwAlert(
          "\n\t\t○\t**" +
            getRandomReg(rules[i]) +
            "** était attendu. (" +
            getRandomReg(rules.join("")) +
            ")"
        );
        break;
      } else {
        code = code.replace(rule, "");
      }
    }
  }
}

function checkRule(ligne, key) {
  return ligne.match(new RegExp(Debug[key][currentLanguage], "")) == null;
}

function checkNumber(ligne, rulerKey, lineNumber) {
  let rule = Debug[rulerKey];
  let check = ligne.match(new RegExp(rule[currentLanguage], ""));
  if (rule.number) {
    let currentNumber = parseInt(check[1]);
    if (currentNumber <= 0 || currentNumber > rule.number) {
      throwAlert(
        languages.notLine[currentLanguage].replace("Num", lineNumber + 1) +
          "\n\t\t○\t" +
          languages.objectRangeError[currentLanguage].replace(
            "{{number}}",
            rule.number
          )
      );
      return false;
    } else {
      return true;
    }
  }
}

function getRule(rule) {
  if (typeof rule == "string") {
    rule = Debug.vide.subRules[rule];
  }
  return rule;
}

function checkNextRule(next, ligne, key) {
  let rule = getRule(next[key]);
  if (rule === undefined) {
    return false;
  }

  let check = ligne.match(new RegExp(rule[currentLanguage], "gmi")) == null;
  return check;
}

function throwAlert(message) {
  conso.alerted = true;
  if (errorMap[stageNumber] == undefined) {
    errorMap[stageNumber] = {};
  }
  if (errorMap[stageNumber][lineNumber + 1] == undefined) {
    errorMap[stageNumber][lineNumber + 1] = [];
  }
  errorMap[stageNumber][lineNumber + 1].push(message);
}

function generateAlert() {
  for (let stageN in errorMap) {
    conso.alert +=
      (stageN != "1" ? "\n\n" : "") +
      languages.errorInLevel[currentLanguage].replace("{{number}}", stageN);

    let errors = errorMap[stageN];
    for (let line in errors) {
      conso.alert += languages.notLine[currentLanguage].replace("Num", line);

      for (let i = 0; i < errors[line].length; i++) {
        conso.alert += errors[line][i];
      }
    }
  }
}

function deleteAccent(code) {
  return code
    .replace(new RegExp(/[àáâãäå]/g), "a")
    .replace(new RegExp(/æ/g), "ae")
    .replace(new RegExp(/ç/g), "c")
    .replace(new RegExp(/[èéêë]/g), "e")
    .replace(new RegExp(/[ìíîï]/g), "i")
    .replace(new RegExp(/ñ/g), "n")
    .replace(new RegExp(/[òóôõö]/g), "o")
    .replace(new RegExp(/œ/g), "oe")
    .replace(new RegExp(/[ùúûü]/g), "u")
    .replace(new RegExp(/[ýÿ]/g), "y");
}

function getRandomReg(reg) {
  const randexp = new RandExp(reg);
  randexp.max = 3;
  return randexp.gen();
}
