<template>
 <div>  
<div id="LandingBox"> 
         
 <div  :class="'column col0 '"   id="col0" ref="col0"  v-bind:style="{}">
 <div  :class="'text textitem07 '"   id="textitem07" ref="textitem07"  v-bind:style="{}">
<div class="text-content">{{ title }}</div>

   </div>
 <div  :class="'rectangle scrollareaitem04 '"   id="scrollareaitem04" ref="scrollareaitem04"  v-bind:style="{backgroundColor : 'transparent',borderColor : borderColor,}">
 <div  :class="'slot slotscrollareaitem041 '"   id="slotscrollareaitem041" ref="slotscrollareaitem041"  v-bind:style="{}"><slot  :name = "'content'" 
></slot></div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'LandingBox',

    components: {},
	props: {
	
		title: {					required: false,			default: () =>  "title",		},
		borderColor: {					required: false,			default: () =>  "#df2cff",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LandingBox{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.button{
	padding: 10px !important;
}



.column.col0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

.text.textitem07{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 10px ; 

margin-right: 10px ; 
height:40px!important ; 
width: calc(100% - (10px + 10px)) !important;

font-size:25px ; 
align-items:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;
}


}

.rectangle.scrollareaitem04{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

background-color:transparent ; 
border-radius:25px ; 
border-style:solid ; 
border-width:4px ; 
border-color:undefined ; 

	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	width:100px!important ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	}


}


}


@media (max-width: 600px) {


.column.col0{


position:relative !important; 









.text.textitem07{


position:relative !important; 





.text-content{
width: 100%;
}


}

.rectangle.scrollareaitem04{


position:relative !important; 







	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 601px) {


.column.col0{


position:relative !important; 









.text.textitem07{


position:relative !important; 





.text-content{
width: 100%;
}


}

.rectangle.scrollareaitem04{


position:relative !important; 







	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 961px) {


.column.col0{


position:relative !important; 









.text.textitem07{


position:relative !important; 





.text-content{
width: 100%;
}


}

.rectangle.scrollareaitem04{


position:relative !important; 







	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 1265px) {


.column.col0{


position:relative !important; 









.text.textitem07{


position:relative !important; 





.text-content{
width: 100%;
}


}

.rectangle.scrollareaitem04{


position:relative !important; 







	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}

@media (min-width: 1905px) {


.column.col0{


position:relative !important; 









.text.textitem07{


position:relative !important; 





.text-content{
width: 100%;
}


}

.rectangle.scrollareaitem04{


position:relative !important; 







	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	
	
	
	
	}


}


}
}
}
</style>
