import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
/**
 * Please don' t remove this.
 * Artefact will use it to inject code
 */
//<= inject_import =>

//<= end_import =>

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  /**
   * Please don' t remove this.
   * Artefact will use it to inject code
   */
  //<= inject_store =>
state: {
	
  
		fotFamily:  "Comic Sans MS"		,
		language:  "francais"		,
		server:  "https://world.abccode.org/"			,
		apiURL:  "https://api.abccode.org"		,
		codeURL:  "https://code.abccode.org"		,
		helpURL:  "https://help.abccode.org"		,
		webURL:  "https://abccode.org"		,
		user:  null		,
		token:  null		,      },

        getters: {

   
		server: function(state) { 		
	return state.server;

}
,     },

        mutations: {

   
		changeLang: function(state, newLang) { 		
	state.language = newLang;

}
,
		registerUser: function(state, data) { 		
	state.user = data

}
,
		registerToken: function(state, token) { 		
	state.token = token

}
,     },

        
//<= end_store =>
})
