import axios from 'axios'
import store from '../../store/index'
import router from '../../router/index'

const qs = require('qs');
	
export function getImages(){	
	var config = {
  	method: 'get',
	  url: store.state.apiURL + '/upload/files'
	};	
	return axios(config)
}
	
export function mixed(array){
	for(let i = array.length - 1; i > 0; i--){
  	const j = Math.floor(Math.random() * i)
	  const temp = array[i]
	  array[i] = array[j]
	  array[j] = temp
	}	
	return array
}
	
export function getChallengeTypes(){
	var config = {
  	method: 'get',
	  url: store.state.apiURL + '/challenge-types'
	};	
	return axios(config)
}
	
export function getChallenges(filter){
	const query = qs.stringify(filter)
	var config = {
  	method: 'get',
	  url: store.state.apiURL + `/challenges?${query}`
	};	
	return axios(config)
}
	
export function getTutorielCategories(){
	var config = {
  	method: 'get',
	  url: store.state.apiURL + '/tutoriel-categories'
	};	
	return axios(config)
}
	
export function getTags(){
	var config = {
  	method: 'get',
	  url: store.state.apiURL + '/tags'
	};	
	return axios(config)
}
	
export function getTutoriels(filter){
	const query = qs.stringify(filter)
	var config = {
  	method: 'get',
	  url: store.state.apiURL + `/tutoriels?${query}`
	};	
	return axios(config)
} 
	
export function getCount(path, filter = {}){
	const query = qs.stringify(filter)
	var config = {
  	method: 'get',
	  url: store.state.apiURL + `/${path}/count?${query}`
	};	
	return axios(config)	
}
	
export function getProjects(filter){
	const query = qs.stringify(filter)
	var config = {
  	method: 'get',
	  url: store.state.apiURL + `/projects?${query}`
	};	
	return axios(config)
} 
	
export function getPageData(name){
	var config = {
  	method: 'get',
	  url: store.state.apiURL + `/${name}`
	};	
	return axios(config)
} 
	
export function getLikedProjects(filter){
	const query = qs.stringify(filter)
	var config = {
  	method: 'get',
	  url: store.state.apiURL + `/liked-projects?${query}`,
   	headers: {
      Authorization: `Bearer ${store.state.token}`,
    }
	};	
	return axios(config)
} 
	
export function getUserData(id){
	var config = {
  	method: 'get',
	  url: store.state.apiURL + `/users/${id}`,
   	headers: {
      Authorization: `Bearer ${store.state.token}`,
    }
	};	
	return axios(config)
} 
	
export function logout(){
	store.commit('registerToken', null)
	store.commit('registerUser', null)
	router.push('/');
}
	
export function likeProject(filter){
	var config = {
  	method: 'post',
	  url: store.state.apiURL + '/liked-projects',
   	headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
		data: filter
	};	
	return axios(config)
} 
	
export function dislikeProject(likeId){
	var config = {
  	method: 'delete',
	  url: store.state.apiURL + `/liked-projects/${likeId}`,
   	headers: {
      Authorization: `Bearer ${store.state.token}`,
    }
	};	
	return axios(config)
} 
	
export function getComments(filter){
	const query = qs.stringify(filter)
	var config = {
  	method: 'get',
	  url: store.state.apiURL + `/comments?${query}`,
	};	
	return axios(config)
} 
	
export function addComments(filter){
	var config = {
  	method: 'post',
	  url: store.state.apiURL + '/comments',
   	headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
		data: filter
	};	
	return axios(config)
} 
	
export function follow(filter){
	var config = {
  	method: 'post',
	  url: store.state.apiURL + '/followers',
   	headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
		data: filter
	};	
	return axios(config)
} 
	
export function followers(filter){
	const query = qs.stringify(filter)
	var config = {
  	method: 'get',
	  url: store.state.apiURL + `/followers?${query}`,
   	headers: {
      Authorization: `Bearer ${store.state.token}`,
    }
	};	
	return axios(config)
} 
	
export function unfollow(id){
	var config = {
  	method: 'delete',
	  url: store.state.apiURL + `/followers/${id}`,
   	headers: {
      Authorization: `Bearer ${store.state.token}`,
    }
	};	
	return axios(config)
} 	
	
//Upload file
export function uploadFile(iconUrl){
	let apiUrl = store.state.apiURL

 	var formData = new FormData();
	formData.append('files', iconUrl);

	return axios.post(apiUrl + '/upload',
   	formData,
		{
   		headers: {
        'Content-Type': 'multipart/form-data',
    		Authorization: `Bearer ${store.state.token}`,
 	    }
 	  }
	) 	
}
	
//Update user
export function updateUser(userData, imgData){
	let apiUrl = store.state.apiURL + '/users' + `/${store.state.user.id}`
	
  let dt = {
  	username: userData.username
	};
	
	if(imgData){
		dt.profile_image = imgData.id
	}

  let config = {
    url: apiUrl,
		method: "put",
   	headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
		data: dt
  };
	
  return axios(config)
}