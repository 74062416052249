<template>
 <div>  
<div id="PanelUser"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitem01 '"   id="rectangleitem01" ref="rectangleitem01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectangleitem011 '"   id="mousearearectangleitem011" ref="mousearearectangleitem011"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'transition transitionitem03 '"   id="transitionitem03" ref="transitionitem03"  v-bind:style="{}">
 <transition  :enter-active-class = "'animated swing'" 
>
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0" v-if="notifBoxVisible"  v-bind:style="{}">
 <div  :class="'column columncardtransitionitem0311 '"   id="columncardtransitionitem0311" ref="columncardtransitionitem0311"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text userName '"   id="userName" ref="userName"  v-bind:style="{color : '#4b4c50',}">
<div class="text-content">{{ user.userName }}</div>

   </div>
 <v-img  :class="'networkimage userImage4 '"   id="userImage4" ref="userImage4"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "userImage" 
>  </v-img>
 <v-btn tag="div" 
 :color = "'#a91eff'" 
 :outlined = "true" 
 :text = "true" 
 @click="goToProfile" :class="'button profileBtn '"   id="profileBtn" ref="profileBtn"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-md-person' !== undefined"  :left = "true" 
 :color = "'#ababab'" 
>{{ 'ion-md-person' }}
</v-icon><span>{{ 'Profile' }}</span>
</v-btn>
 <div  :class="'rectangle separator '"   id="separator" ref="separator"  v-bind:style="{backgroundColor : '#ababab',}">  </div>
 <v-btn tag="div" 
 :color = "'#a91eff'" 
 :outlined = "true" 
 :text = "true" 
 @click="goToAide" :class="'button helpBtn '"   id="helpBtn" ref="helpBtn"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-md-help' !== undefined"  :left = "true" 
 :color = "'#ababab'" 
>{{ 'ion-md-help' }}
</v-icon><span>{{ 'Aide' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#a91eff'" 
 :outlined = "true" 
 :text = "true" 
 @click="goToSettings" :class="'button settingBtn '"   id="settingBtn" ref="settingBtn"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-ios-settings' !== undefined"  :left = "true" 
 :color = "'#ababab'" 
>{{ 'ion-ios-settings' }}
</v-icon><span>{{ 'Paramètres de compte' }}</span>
</v-btn>
 <div  :class="'rectangle separator7 '"   id="separator7" ref="separator7"  v-bind:style="{backgroundColor : '#ababab',}">  </div>
 <v-btn tag="div" 
 :color = "'#ff1d74'" 
 :outlined = "true" 
 :text = "true" 
 @click="logout" :class="'button logoutBtn '"   id="logoutBtn" ref="logoutBtn"  v-bind:style="{}"><v-icon  :style = "{}"  v-if = "'ion-ios-log-out' !== undefined"  :left = "true" 
 :color = "'#ababab'" 
>{{ 'ion-ios-log-out' }}
</v-icon><span>{{ 'Se déconnecter' }}</span>
</v-btn>  </div>  </v-card>  </transition></div>  </div>
 
      </div>
 </div> 
</template>
<script>

import * as utils from '../../Js/utils.js'
export default {
	name: 'PanelUser',

    components: {},
	methods: {
	
		logout: function() {		
	utils.logout();

}
,
		goToProfile: function(){ 		
	this.$router.push('/profile')

}
,
		goToAide: function(){ 		
	window.open('https://aide.abccode.org/', '_blank').focus()

}
,
		goToSettings: function(){ 		
	this.$router.push('/profile/parametres')

}
,
	},
	mounted(){
			
	setTimeout(()=>{ this.notifBoxVisible = true }, 1000);

	},
	computed: {
	
		user: function() { 		
	return this.$store.state.user

}
,
		userImage: function(){		
	if(this.user && this.user.profile_image){

		return this.$store.state.apiURL + this.user.profile_image.url

	} else {

		return "https://picsum.photos/200"

	}

}
,
	},
	data(){
	return {
		
		notifBoxVisible:  true		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  (e=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#PanelUser{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.button{
    justify-content: left;
    padding: 0 12px !important;
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.5 ;  filter: alpha(opacity=50) ; 

background-color:#000 ; 

	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.transition.transitionitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: 50px ; 
right: 20px ; 
width:315.75px!important ; 
height:224px!important ; 


	.card.card0{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	top: 0.25px ; 
	width:312px!important ; 
	height:298.75px!important ; 
	background-color:#ffffff ; 
	border-radius:8px ; max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 12px ; 
		
		top: 12px ; 
		right: 12px ; 
		bottom: 12px ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:3px ; 
		justify-content:space-between ; background-color: transparent ; 

			.text.userName{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			color:#4b4c50 ; 
			font-size:16px ; 
			.text-content{
			width: 100%;
			}

			
}

			.networkimage.userImage4{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:110px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			display: inherit;

			flex: unset;
			border-radius:8px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

			
}

			.button.profileBtn{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:25px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

			.rectangle.separator{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:1px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			background-color:#ababab ; 

			
}

			.button.helpBtn{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:25px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

			.button.settingBtn{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:25px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

			.rectangle.separator7{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:1px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			background-color:#ababab ; 

			
}

			.button.logoutBtn{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:25px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			}
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.transition.transitionitem03{


position:absolute !important; 






	.card.card0{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.userName{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.networkimage.userImage4{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.button.profileBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.separator{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.button.helpBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.settingBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.separator7{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.button.logoutBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.transition.transitionitem03{


position:absolute !important; 






	.card.card0{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.userName{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.networkimage.userImage4{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.button.profileBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.separator{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.button.helpBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.settingBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.separator7{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.button.logoutBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.transition.transitionitem03{


position:absolute !important; 






	.card.card0{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.userName{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.networkimage.userImage4{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.button.profileBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.separator{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.button.helpBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.settingBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.separator7{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.button.logoutBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.transition.transitionitem03{


position:absolute !important; 






	.card.card0{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.userName{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.networkimage.userImage4{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.button.profileBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.separator{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.button.helpBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.settingBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.separator7{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.button.logoutBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.rectangleitem01{


position:absolute !important; 







	.mousearea.mousearearectangleitem011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.transition.transitionitem03{


position:absolute !important; 






	.card.card0{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.column.columncardtransitionitem0311{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.text.userName{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.networkimage.userImage4{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.button.profileBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.separator{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.button.helpBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.settingBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.separator7{

			
			position:relative !important; 
			
			
			
			
			
			

			
}

			.button.logoutBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}
}
</style>
