<template>
 <div>  
<div id="ButtonNetworkImage"> 
         
 <div  :class="'rectangle item0 '"   id="item0" ref="item0"  v-bind:style="{backgroundColor : color,}">
 <div  :class="'row row0 '"   id="row0" ref="row0"  v-bind:style="{}">
 <v-img  :class="'networkimage imagerow03 '"   id="imagerow03" ref="imagerow03"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "icon" 
>  </v-img>
 <div  :class="'text textrow03 '"   id="textrow03" ref="textrow03"  v-bind:style="{color : textColor,}">
<div class="text-content">{{ text }}</div>

   </div>  </div>
 <div  :class="'mousearea mouseareaitem03 '"   id="mouseareaitem03" ref="mouseareaitem03" v-on:mouseover="hover"  v-on:mouseout="hoverOut"   v-bind:style="{}" @click="click"></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ButtonNetworkImage',

    components: {},
	methods: {
	
		hover: function() { 		
	this.hovered = true;

}
,
		hoverOut: function() { 		
	this.hovered = false;

}
,
		click: function() { 		
	this.$emit('clickIt')

}
,
	},
	computed: {
	
		color: function() { 		
	if(this.active || this.hovered){

		return this.hoverColor

	} else {

		return 'transparent'

	}

}
,
	},
	data(){
	return {
		
		hovered:  false		,
		}
	},
	props: {
	
		icon: {					required: false,			default: () =>  "Resources/abcCode_Logo_N_G_transparent.png",		},
		textColor: {					required: false,			default: () =>  "#656a6e",		},
		text: {					required: false,			default: () =>  "Home",		},
		hoverColor: {					required: false,			default: () =>  "#e5e7e9",		},
		active: {			type: Boolean,			required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ButtonNetworkImage{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

#item0{
	padding: 12px;
}



.rectangle.item0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 
height:100%!important ; 

background-color:#ffffff ; 
border-radius:1000px ; 

.row.row0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

margin-top: 0px ; 
width:auto!important ; 
height:100%!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:5px ; background-color:transparent ; 

	.networkimage.imagerow03{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:28px!important ; 
	height:28px!important ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}

	.text.textrow03{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	font-size:17px ; 
	text-align:left;
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;
	font-weight: bold;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.rectangle.item0{


position:relative !important; 







.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.imagerow03{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.text.textrow03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.rectangle.item0{


position:relative !important; 







.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.imagerow03{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.text.textrow03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.rectangle.item0{


position:relative !important; 







.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.imagerow03{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.text.textrow03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.rectangle.item0{


position:relative !important; 







.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.imagerow03{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.text.textrow03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.rectangle.item0{


position:relative !important; 







.row.row0{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.networkimage.imagerow03{

	
	position:relative !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.text.textrow03{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}


}

.mousearea.mouseareaitem03{


position:absolute !important; 





}


}
}
}
</style>
