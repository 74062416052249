<template>
 <div>  
<div id="NivItem"> 
         
 <v-card 
 :class="'card rec0 '"   id="rec0" ref="rec0" :elevation = "3" 
  v-bind:style="{backgroundColor : '#fff',}">
 <v-img  :class="'image imagerectanglerec01111111222223 '"   id="imagerectanglerec01111111222223" ref="imagerectanglerec01111111222223"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../../' + 'Resources/App/niv/' + nivNumber + '.png' + '')" 
>  </v-img>
 <div  :class="'text niv4 '"   id="niv4" ref="niv4"  v-bind:style="{color : '#969696',}">
<div class="text-content">{{ 'Niveau' + nivNumber }}</div>

   </div>
 <div  :class="'rectangle activePoint5 '"   id="activePoint5" ref="activePoint5" v-if="active"  v-bind:style="{backgroundColor : '#ff6b00',}">  </div>
 <div  :class="'mousearea mousearearec03 '"   id="mousearearec03" ref="mousearearec03"  v-bind:style="{}" @click="clo(nivNumber-1)"></div>  </v-card>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'NivItem',

    components: {},
	props: {
	
		nivNumber: {					required: false,			default: () =>  1,		},
		clo: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		active: {					required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#NivItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.card.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#fff ; 
border-radius:20px ; max-width: initial;min-width: initial;

.image.imagerectanglerec01111111222223{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);

left:10px ; 

top: calc(50% + 0px) ; 
width:66px!important ; 
height:66px!important ; 

display: inherit;

flex: unset;
border-radius:40px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.text.niv4{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);

left: 90px ; 

top: calc(50% + 0px) ; 
right: 10px ; 
height:auto!important ; 

color:#969696 ; 
font-size:18px ; 
text-overflow: ellipsis ; 
.text-content{
width: 100%;
white-space: nowrap;
overflow: hidden ;
text-overflow: ellipsis ; 
}


}

.rectangle.activePoint5{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: 15px ; 
right: 15px ; 
width:8px!important ; 
height:8px!important ; 

background-color:#ff6b00 ; 
border-radius:4px ; 


}

.mousearea.mousearearec03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.card.rec0{


position:absolute !important; 




max-width: initial;min-width: initial;

.image.imagerectanglerec01111111222223{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.text.niv4{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.rectangle.activePoint5{


position:absolute !important; 








}

.mousearea.mousearearec03{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.card.rec0{


position:absolute !important; 




max-width: initial;min-width: initial;

.image.imagerectanglerec01111111222223{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.text.niv4{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.rectangle.activePoint5{


position:absolute !important; 








}

.mousearea.mousearearec03{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.card.rec0{


position:absolute !important; 




max-width: initial;min-width: initial;

.image.imagerectanglerec01111111222223{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.text.niv4{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.rectangle.activePoint5{


position:absolute !important; 








}

.mousearea.mousearearec03{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.card.rec0{


position:absolute !important; 




max-width: initial;min-width: initial;

.image.imagerectanglerec01111111222223{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.text.niv4{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.rectangle.activePoint5{


position:absolute !important; 








}

.mousearea.mousearearec03{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.card.rec0{


position:absolute !important; 




max-width: initial;min-width: initial;

.image.imagerectanglerec01111111222223{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.text.niv4{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.rectangle.activePoint5{


position:absolute !important; 








}

.mousearea.mousearearec03{


position:absolute !important; 





}


}
}
}
</style>
