<template>
 <div>  
<div id="ShareCard"> 
         
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0" :elevation = "3" 
  v-bind:style="{}">
 <div  :class="'item itemcard01 '"   id="itemcard01" ref="itemcard01"  v-bind:style="{}">
 <v-card 
 :class="'card carditemcard011 '"   id="carditemcard011" ref="carditemcard011" :elevation = "1" 
  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagecarditemcard0111 '"   id="networkimagecarditemcard0111" ref="networkimagecarditemcard0111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "mainImage" 
>  </v-img>  </v-card>
 <v-img  :class="'networkimage networkimageitemcard013 '"   id="networkimageitemcard013" ref="networkimageitemcard013"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "creatorImage" 
>  </v-img>  </div>
 <router-link  :class="'routerlink routerlinkcard05 '"   id="routerlinkcard05" ref="routerlinkcard05"  v-bind:style="{}" :to = "href" 
>  </router-link>
 <div  :class="'column columncard03 '"   id="columncard03" ref="columncard03"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'column columncolumncard031 '"   id="columncolumncard031" ref="columncolumncard031"  v-bind:style="{}">
 <div  :class="'text textcolumncolumncard0311 '"   id="textcolumncolumncard0311" ref="textcolumncolumncard0311"  v-bind:style="{color : '#585858',}">
<div class="text-content">{{ projectName }}</div>

   </div>
 <div  :class="'text textcolumncolumncard03112 '"   id="textcolumncolumncard03112" ref="textcolumncolumncard03112"  v-bind:style="{color : '#b8b8b8',}">
<div class="text-content">{{ ('par ' + creatorName) }}</div>

   </div>
 <div  :class="'item publishedTimeItem '"   id="publishedTimeItem" ref="publishedTimeItem"  v-bind:style="{}">
 <div  :class="'icon iconrowcolumncolumncard03151 '"   id="iconrowcolumncolumncard03151" ref="iconrowcolumncolumncard03151"  v-bind:style="{color : '#b8b8b8',fontSize : getUnit(15,'px'),}"><i  :class="'ion-md-alarm'"></i></div>
 <div  :class="'text textcolumncolumncard031122 '"   id="textcolumncolumncard031122" ref="textcolumncolumncard031122"  v-bind:style="{color : '#b8b8b8',}">
<div class="text-content">{{ publishedTime }}</div>

   </div>  </div>  </div>
 <div  :class="'row rowcolumncard033 '"   id="rowcolumncard033" ref="rowcolumncard033"  v-bind:style="{}">
 <WorldButton :class="'object worldbuttonrowcolumncard0331 '"   id="worldbuttonrowcolumncard0331" ref="worldbuttonrowcolumncard0331"  v-bind:style="{}" :click = "likeIt" 
 :value = "numberLike + ' Likes'" 
 :textColor = "(m_liked? 'red' : '#9d9da3')" 
>
</WorldButton>
 <WorldButton :class="'object worldbuttonrowcolumncard03312 '"   id="worldbuttonrowcolumncard03312" ref="worldbuttonrowcolumncard03312"  v-bind:style="{}" :click = "remixCode" 
 :value = "'Remixer'" 
 :iconName = "'ion-ios-shuffle'" 
>
</WorldButton>  </div>  </div>  </v-card>
 
      </div>
 </div> 
</template>
<script>
import WorldButton from '../../../At/Components/WorldButton'


export default {
	name: 'ShareCard',

    components: {WorldButton,},
	methods: {
	
		likeIt: function(){		
	if(this.m_liked){

		this.dislike(this.projectId)

	} else {

		this.like(this.projectId)

	}

	this.m_liked = !this.m_liked

}
,
		remixCode: function(id){ 		
	window.open(this.$store.state.codeURL + '/#/app/mix/' + this.projectId, '_blank').focus()

}
,
	},
	computed: {
	
		mainImage: function() { 		
	return this.item.description_image?.url

}
,
		creatorImage: function() { 		
	return this.item.user?.profile_image

}
,
		creatorName: function() { 		
	return this.item.user?.username

}
,
		projectName: function() { 		
	return this.item.name

}
,
		publishedTime: function() { 		
	return this.item.updated_at

}
,
		numberLike: function() { 		
	return this.item.likes.length

}
,
		projectId: function() { 		
	return this.item.id

}
,
	},
	watch: {
	
		liked: function(val) { 		
	this.m_liked = this.liked

}
,
	},
	data(){
	return {
		
		m_liked:  this.liked		,
		}
	},
	props: {
	
		href: {					required: false,			default: () =>  "",		},
		liked: {					required: false,			default: () =>  false,		},
		like: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		dislike: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		item: {					required: false,			default: () =>  ({}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ShareCard{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
background-color:#ffffff ; 
border-radius:20px ; max-width: initial;min-width: initial;

.item.itemcard01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 10px ; 

top: 10px ; 
right: 10px ; 
bottom: 150px ; 


	.card.carditemcard011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	background-color:#ffffff ; 
	border-radius:20px ; max-width: initial;min-width: initial;

		.networkimage.networkimagecarditemcard0111{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: inherit;

		flex: unset;
		border-radius:20px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

	
}

	.networkimage.networkimageitemcard013{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:20px ; 
	
	bottom: -20px ; 
	width:40px!important ; 
	height:40px!important ; 
	
	display: inherit;

	flex: unset;
	border-radius:20px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}


}

.routerlink.routerlinkcard05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 



}

.column.columncard03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 15px ; 

right: 15px ; 
bottom: 15px ; 
height:105px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
justify-content:space-between ; background-color: transparent ; 

	.column.columncolumncard031{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.text.textcolumncolumncard0311{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#585858 ; 
		font-size:22px ; 
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		font-weight: bold;
		}

		
}

		.text.textcolumncolumncard03112{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#b8b8b8 ; 
		font-size:15px ; 
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		font-weight: bold;
		}

		
}

		.item.publishedTimeItem{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:25px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		

			.icon.iconrowcolumncolumncard03151{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left:0px ; 
			
			top: calc(50% + 0px) ; 
			width:20px!important ; 
			height:20px!important ; 
			
			color:#b8b8b8 ; 
			font-size:15px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolumncard031122{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 25px ; 
			
			top: calc(50% + 0px) ; 
			right: 0px ; 
			height:auto!important ; 
			
			color:#b8b8b8 ; 
			font-size:15px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			font-weight: bold;
			}

			
}

		
}

	
}

	.row.rowcolumncard033{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-right: 0px ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.object.worldbuttonrowcolumncard0331{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:115px!important ; 
		height:34px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.worldbuttonrowcolumncard03312{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:115px!important ; 
		height:34px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.item.itemcard01{


position:absolute !important; 






	.card.carditemcard011{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.networkimagecarditemcard0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.networkimage.networkimageitemcard013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.routerlink.routerlinkcard05{


position:absolute !important; 







}

.column.columncard03{


position:absolute !important; 









	.column.columncolumncard031{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard03112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.publishedTimeItem{

		
		position:relative !important; 
		
		
		
		
		

			.icon.iconrowcolumncolumncard03151{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolumncard031122{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowcolumncard033{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldbuttonrowcolumncard0331{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.worldbuttonrowcolumncard03312{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.item.itemcard01{


position:absolute !important; 






	.card.carditemcard011{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.networkimagecarditemcard0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.networkimage.networkimageitemcard013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.routerlink.routerlinkcard05{


position:absolute !important; 







}

.column.columncard03{


position:absolute !important; 









	.column.columncolumncard031{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard03112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.publishedTimeItem{

		
		position:relative !important; 
		
		
		
		
		

			.icon.iconrowcolumncolumncard03151{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolumncard031122{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowcolumncard033{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldbuttonrowcolumncard0331{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.worldbuttonrowcolumncard03312{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.item.itemcard01{


position:absolute !important; 






	.card.carditemcard011{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.networkimagecarditemcard0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.networkimage.networkimageitemcard013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.routerlink.routerlinkcard05{


position:absolute !important; 







}

.column.columncard03{


position:absolute !important; 









	.column.columncolumncard031{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard03112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.publishedTimeItem{

		
		position:relative !important; 
		
		
		
		
		

			.icon.iconrowcolumncolumncard03151{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolumncard031122{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowcolumncard033{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldbuttonrowcolumncard0331{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.worldbuttonrowcolumncard03312{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.item.itemcard01{


position:absolute !important; 






	.card.carditemcard011{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.networkimagecarditemcard0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.networkimage.networkimageitemcard013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.routerlink.routerlinkcard05{


position:absolute !important; 







}

.column.columncard03{


position:absolute !important; 









	.column.columncolumncard031{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard03112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.publishedTimeItem{

		
		position:relative !important; 
		
		
		
		
		

			.icon.iconrowcolumncolumncard03151{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolumncard031122{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowcolumncard033{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldbuttonrowcolumncard0331{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.worldbuttonrowcolumncard03312{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.item.itemcard01{


position:absolute !important; 






	.card.carditemcard011{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.networkimagecarditemcard0111{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.networkimage.networkimageitemcard013{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.routerlink.routerlinkcard05{


position:absolute !important; 







}

.column.columncard03{


position:absolute !important; 









	.column.columncolumncard031{

	
	position:relative !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncolumncard0311{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textcolumncolumncard03112{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.publishedTimeItem{

		
		position:relative !important; 
		
		
		
		
		

			.icon.iconrowcolumncolumncard03151{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.textcolumncolumncard031122{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.row.rowcolumncard033{

	
	position:relative !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldbuttonrowcolumncard0331{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.worldbuttonrowcolumncard03312{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
