<template>
 <div>  
<div id="LandingSection"> 
         
 <div  :class="'column col0 '"   id="col0" ref="col0"  v-bind:style="{}">
 <div  :class="'row rowcolumn0151 '"   id="rowcolumn0151" ref="rowcolumn0151"  v-bind:style="{}">
 <div  :class="'text textrowcolumn013 '"   id="textrowcolumn013" ref="textrowcolumn013"  v-bind:style="{color : '#414a51',}">
<div class="text-content">{{ 'Club' }}</div>

   </div>
 <div  :class="'text textrowcolumn013314 '"   id="textrowcolumn013314" ref="textrowcolumn013314"  v-bind:style="{color : '#8b959c',}">
<div class="text-content">{{ 'Info' }}</div>

 
 <div  :data-balloon-length = "'medium'" 
 :aria-label = "'tooltip'" 
 :data-balloon-visible = "true" 
 :data-balloon-blunt = "true" 
 :class="'mousearea mouseareaitemrowcolumn01531 '"   id="mouseareaitemrowcolumn01531" ref="mouseareaitemrowcolumn01531"  v-bind:style="{}"></div>  </div>  </div>
 <div  :class="'scrollarea scrollareaitem04 '"   id="scrollareaitem04" ref="scrollareaitem04"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'slot slotscrollareaitem041 '"   id="slotscrollareaitem041" ref="slotscrollareaitem041"  v-bind:style="{}"><slot  :name = "'content'" 
></slot></div>  </div>
 <div  :class="'rectangle rectangleitem07 '"   id="rectangleitem07" ref="rectangleitem07" v-show="!expended"  v-bind:style="{backgroundImage: 'linear-gradient(' + 0 + 'deg, ' +'white'+ ',' +'transparent' + ')' , }">  </div>
 <v-btn tag="div" 
 :color = "'#656a6e'" 
 :rounded = "true" 
 :dark = "true" 
 @click="expendSection" :class="'button buttonrowitem05123 '"   id="buttonrowitem05123" ref="buttonrowitem05123"  v-bind:style="{}"><span>{{ buttonText }}</span>
</v-btn>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'LandingSection',

    components: {},
	props: {
	
		expendSection: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		expended: {					required: false,			default: () =>  false,		},
		buttonText: {					required: false,			default: () =>  "",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LandingSection{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.button{
	padding: 10px !important;
}



.column.col0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:10px ; background-color: transparent ; 

.row.rowcolumn0151{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:40px!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:10px ; background-color:transparent ; 

	.text.textrowcolumn013{

	align-self: flex-start;
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:auto!important ; 
	
	color:#414a51 ; 
	font-size:27px ; 
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	margin-top: 0px ; 
	margin-bottom: 0px ; 
	width:auto!important ; 
	height: calc(100% - (0px + 0px)) !important;
	
	color:#8b959c ; 
	font-size:25px ; 
	align-items:center;
	display: flex;
	.text-content{
	width: 100%;text-decoration: underline
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		cursor: help;
		}

	
}


}

.scrollarea.scrollareaitem04{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

overflow:hidden ; 
overflow-y:hidden ; 
background-color:transparent ; 

	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	margin-top: 0px ; 
	margin-right: 0px ; 
	width:100px!important ; 
	height:auto!important ; 
	width: calc(100% - (0px + 0px)) !important;
	}


}

.rectangle.rectangleitem07{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
bottom: 0px ; 
height:222px!important ; 
max-height: 100%!important ; 

background-image: linear-gradient(0deg, white, transparent) ; 


}

.button.buttonrowitem05123{

height: auto;
align-self: flex-start;
position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

margin-bottom: -16px ; 
width:auto!important ; 
height:32px!important ; 

text-transform:unset ; 
min-width: auto;
padding-left: 16px;
padding-right: 16px;

i.v-icon{
}
}


}


@media (max-width: 600px) {


.column.col0{


position:relative !important; 









.row.rowcolumn0151{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.scrollarea.scrollareaitem04{


position:relative !important; 






	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	
	
	
	
	}


}

.rectangle.rectangleitem07{


position:absolute !important; 








}

.button.buttonrowitem05123{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 601px) {


.column.col0{


position:relative !important; 









.row.rowcolumn0151{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.scrollarea.scrollareaitem04{


position:relative !important; 






	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	
	
	
	
	}


}

.rectangle.rectangleitem07{


position:absolute !important; 








}

.button.buttonrowitem05123{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 961px) {


.column.col0{


position:relative !important; 









.row.rowcolumn0151{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.scrollarea.scrollareaitem04{


position:relative !important; 






	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	
	
	
	
	}


}

.rectangle.rectangleitem07{


position:absolute !important; 








}

.button.buttonrowitem05123{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1265px) {


.column.col0{


position:relative !important; 









.row.rowcolumn0151{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.scrollarea.scrollareaitem04{


position:relative !important; 






	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	
	
	
	
	}


}

.rectangle.rectangleitem07{


position:absolute !important; 








}

.button.buttonrowitem05123{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1905px) {


.column.col0{


position:relative !important; 









.row.rowcolumn0151{


position:relative !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.text.textrowcolumn013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textrowcolumn013314{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareaitemrowcolumn01531{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}

.scrollarea.scrollareaitem04{


position:relative !important; 






	.slot.slotscrollareaitem041{

	
	position:relative !important; 
	
	
	
	
	}


}

.rectangle.rectangleitem07{


position:absolute !important; 








}

.button.buttonrowitem05123{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}
}
</style>
